import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import calculatorService from '../services/CalculatorService';
import { useAuth } from './AuthContext';


const ResultsContext = createContext();

export function useResults() {
    return useContext(ResultsContext);
}

export function ResultsProvider({ children }) {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const urlCalcId = searchParams.get('calcId');
    const navigate = useNavigate();
    const {authUser} = useAuth();
    const [resultsData, setResultsData] = useState(null);
    const [paid, setPaid] = useState(null);


    const [calcId, setCalcId] = useState(urlCalcId || null);
    const [calcName, setCalcName] = useState("");
    const [calcAdresare, setCalcAdresare] = useState("Către:");
    const [creationDate, setCreationDate] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (urlCalcId) {
            setCalcId(urlCalcId);
        }
    }, [urlCalcId]);

    useEffect(() => {
        const fetchResultsData = async () => {
            try {
                if (calcId) {
                    setLoading(true);
                    const data = await calculatorService.getCalculation(calcId); 
                    setResultsData(data.result);
                    setPaid(data.paid);
                    setCalcName(data.name);
                    if(authUser  && authUser.subscription == null && authUser.balance -1 >= 0 && data.paid != true){
                        await calculatorService.saveCalculation(data.calcId);
                    }
                    const date = new Date(data.creationDate);
                    setCreationDate(date.toLocaleDateString());
                    const newCalcId = data.calcId; // Assuming the new calcId is obtained from the fetched data
                    const newUrl = new URL(window.location.href);
                    newUrl.searchParams.set('calcId', newCalcId);
                    window.history.replaceState({}, '', newUrl);
                }
            } catch (error) {
                // navigate(-1);
                
            }finally {
                setLoading(false);
            }
        };
        

        fetchResultsData();
    }, [calcId]);

    return (
        <ResultsContext.Provider value={{ resultsData, setResultsData, loading, paid, calcName, setCalcName,calcId,calcAdresare, setCalcAdresare,creationDate }}>
            {children}
        </ResultsContext.Provider>
    );
}
