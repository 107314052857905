import React from 'react'
import MyInput from '../../UI/input/MyInput'

export default function DetailedWrapper({isDetailed,setIsDetailed}) {
  return (
    <div>
        <MyInput
            name="checkbox4"
            type="checkbox"
            checked={isDetailed}
            onChange={(e) => setIsDetailed(e.target.checked)}
            label="Calcul detaliat"
        />

    </div>
  )
}
