import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { useAuth } from '../../context/AuthContext';
import userService from '../../services/UserService';
import ChangePasswordForm from '../Forms/ChangePasswordForm';
import EmailForm from '../Forms/EmailForm';
import LinkCustomPasswordForm from '../Forms/LinkCustomPasswordForm';
import DetailsForm from '../Forms/DetailsForm';


export default function Settings() {
const { authUser, setAuthUser } = useAuth();

const [email, setEmail] = useState('');
const [currentPassword, setCurrentPassword] = useState('');
const [newPassword, setNewPassword] = useState('');
const [showOverlay,setShowOverlay] = useState(false);
const [selectedTab, setSelectedTab] = useState('details');
const [resend, setResend] = useState(false);
useEffect(() => {
  const email = authUser?.email ? authUser.email : '';
  setEmail(email);
}, [authUser]);



  const handleEmailSubmit = async (verificationCode) => {
    if(email === authUser.email){
      return;
    }
    try{
      const data = await userService.changeUserEmail(email,verificationCode);
      setAuthUser(data);
      setResend(false);
      setShowOverlay(false);
      toast.success('Adresa de email a fost modificată cu success.', {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }catch(error){
      setResend(true);
      toast.error(`${error.response ? error.response.data.email || error.response.data :'Eroare la procesare.'}`, {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      if(error.response && error.response.data !== "Invalid verification code."){
        setResend(false);
        setShowOverlay(false);
      }
    }
  }
  const handlePasswordSubmit = async (verificationCode) => {
    if(!currentPassword || !newPassword){
      toast.error('Completați toate cîmpurile.', {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    try{
      const data = await userService.changeUserPassword(currentPassword,newPassword,verificationCode);
      setCurrentPassword('');
      setNewPassword('');
      setResend(false);
      setShowOverlay(false);
      toast.success('Parola actualizată cu succes.', {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }catch(error){
      setResend(true);
      toast.error(`${error.response ? error.response.data.newPassword || error.response.data :'Eroare la procesare.'}`, {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      if(error.response && error.response.data === "Parola curenta este gresita."){
        setResend(false);
        setShowOverlay(false);
      }
    }
  }

  const handleLinkCustomPassword = async (e) => {
    e.preventDefault();
    try {
      const data = await userService.linkWithCustomPassword(newPassword);
      setNewPassword('');
      toast.success('Parola actualizată cu succes.', {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }catch(error){
      toast.error(`${error.response ? error.response.data.newPassword || error.response.data :'Eroare la procesare.'}`, {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }



  return (
    <div>
          <div className='tab-select-wrapper'>
          <span className={selectedTab === 'details' ?  'tab-selector-settings selected-tab-settings' : 'tab-selector-settings'} onClick={() => {setSelectedTab('details');}} >Detalii</span>
          <span className={selectedTab === 'email' ?  'tab-selector-settings selected-tab-settings' : 'tab-selector-settings'} onClick={() => {setSelectedTab('email');}} >Email</span>
          <span className={selectedTab === 'password' ?  'tab-selector-settings selected-tab-settings' : 'tab-selector-settings'} onClick={() => {setSelectedTab('password');}} >Parola</span>
          </div>
      {selectedTab === 'details'  && <DetailsForm/>}
        {selectedTab === 'email' && <EmailForm 
        email={email}
        setEmail={setEmail}
        handleEmailSubmit={handleEmailSubmit}
        showOverlay={showOverlay}
        setShowOverlay={setShowOverlay}
        resend={resend}
        />}
      {
      selectedTab === 'password' ?
      authUser.passwordCustom
      ? 
      <ChangePasswordForm 
        currentPassword={currentPassword}
        setCurrentPassword={setCurrentPassword}
        newPassword={newPassword}
        setNewPassword={setNewPassword}
        handlePasswordSubmit={handlePasswordSubmit}
        showOverlay={showOverlay}
        setShowOverlay={setShowOverlay}
        resend={resend}
        />
      :   
      <LinkCustomPasswordForm
      newPassword={newPassword}
      setNewPassword={setNewPassword}
      handlePasswordSubmit={handleLinkCustomPassword}
      />
      : null
      }
      
    </div>
  )
}
