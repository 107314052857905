import React from 'react'
import MyButton from '../UI/button/MyButton'
import transfer from '../assets/icons/transfer-100.png';
import card from '../assets/icons/credit-card-100.png';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import userService from '../services/UserService';
import { useEffect } from 'react';
import instance from '../interceptors/AxiosInterceptor';
import { toast } from 'react-toastify';
import axios from 'axios';
import subscriptionService from '../services/SubscriptionService';
import { CircleSpinner } from 'react-spinner-overlay';
import InputWrapper from './InputWrapper';
import { useAuth } from '../context/AuthContext';
export default function PaymentMethodForm({goBack,updatePlan,planId,maxActiveSessions,termsAccepted,setTermsAccepted}) {
    const [paymentMethod,setPaymentMethod] = useState(null);
    const [clientIp, setClientIp] = useState('');
    const [loading,setLoading] = useState(false);
    const [transferMethod,setTransferMethod] = useState(false);
    const {authUser} = useAuth();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [IDNO, setIDNO] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [telephoneNumber, setTelephoneNumber] = useState('');
    useEffect(() => {
        if (authUser){
            setEmail(authUser.email);
          }
        if(authUser && authUser.personType){
          setCompanyName(authUser.name);
        }else{
        const [first, last] = authUser?.name?.split(' ') || [];
        setFirstName(first || '');
        setLastName(last || '');
        }
        if(authUser && authUser.telephoneNumber){
          setTelephoneNumber(authUser.telephoneNumber);
        }
        if(authUser && authUser.idno){
            setIDNO(authUser.idno);
        }
    
      }, [authUser]);
    const location = useLocation();
    useEffect(() => {
        const fetchClientIp = async () => {
        try {
            const response = await axios.get('https://api.ipify.org?format=json');
            setClientIp(response.data.ip);
        } catch (error) {
         
        }
        };

        fetchClientIp();
    }, []);
    async function cardCheckout() {
            try {
                setLoading(true);
                    const response = !updatePlan ? await instance.post('/api/subscription/subscribe', {
                        clientIp: clientIp,
                        planId: planId,
                        maxActiveSessions
                    }) : await subscriptionService.updateSubscription(planId,maxActiveSessions);
                    if(!updatePlan){
                        localStorage.setItem('prevPath', location.pathname + location.search);
                        window.location.href = response.data;
                    }else{
                        toast.success('Abonament schimbat cu succes!', {
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                          });
                    }
                    if(!termsAccepted){await userService.acceptTerms();}
                    setTermsAccepted(true);
                  setTimeout(() => {
                    window.location.reload();
                }, 1000);
            } catch (error) {
                toast.error('Eroare la procesarea tranzacției, încercați mai târziu.', {
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }finally{
                setLoading(false);
            }
    }

    function validation(){
        const phoneNumberPattern = /^(?:\+373|0)?[0-9]{8}$/;
        const IDNOPattern = /[0-9]{6,13}/;
        
        if (!phoneNumberPattern.test(telephoneNumber)){
            toast.error('Numărul de telefon trebuie să fie în formatul +373xxxxxxxx, 0xxxxxxxx sau xxxxxxxx.', {
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              return false;
        }
            if (!IDNOPattern.test(IDNO)){
                toast.error('IDNO trebuie să conțină între 6 și 13 cifre.', {
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
                  return false;
            }
            if (!planId) {
                toast.error('Selectați un plan înainte de plată.', {
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                return false;
            }
        if(!termsAccepted){
            toast.error('Acceptați Termenii și condițiile.', {
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return false;
        }
        return true;
    }
    const transferCheckout = async (e) => {
        e.preventDefault();
        if(!validation()){
            return;
        }
        const transferRequest = {
            name: authUser.personType ? companyName : firstName + " " + lastName,
            email,
            idno: IDNO,
            telephoneNumber,
            planId,
            maxActiveSessions
        };
        
        try {
            setLoading(true);
        await subscriptionService.createInvoice(transferRequest);
        toast.success('Ordin de plata creat cu succes, va rugăm să verificați email-ul.', {
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
        await userService.acceptTerms();
        setTermsAccepted(true);
        setTimeout(() => {
            window.location.reload();
          }, 3000);
        } catch (error) {
            toast.error("A intervenit o eroare.Încercați mai târziu", {
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }finally{
            setLoading(false);
        }
    }
  return (
    <div>
        {
            !transferMethod &&         
            <div className='checkout-form-container'>
            <div>
            <div className='payment-label'><h2>Metoda de plată</h2></div>
            <div className='bundles-wrapper'>
            <MyButton 
            body = {loading ? <div className="spinner-container"><CircleSpinner loading={loading} color='#1b1b1bff' />
            </div> : <><img src={card} loading="lazy" alt="" className="space"/><h3>Card</h3><span>ALEGE</span></>}
            type="button"
            className="bundle-card"
            onClick = {cardCheckout}
            />
            <MyButton 
            body = {loading ? <div className="spinner-container"><CircleSpinner loading={loading} color='#1b1b1bff' />
            </div>: <><img src={transfer} loading="lazy" alt="" className="space"/><h3>Transfer</h3><span>ALEGE</span></>}
            type="button"
            className="bundle-card"
            onClick = {()=>{setTransferMethod(true)}}
            />
        </div>
            </div>
            </div>
        }
        {
            transferMethod && 
            <>
            <form onSubmit={transferCheckout} className='transfer-form'>
                    <InputWrapper
                                name="IDNO"
                                type="text"
                                isRequired={true}
                                placeholder="Introduceți IDNO"
                                body="IDNO"
                                value={IDNO}
                                onChange={(e) => setIDNO(e.target.value)}
                                regex = {/^(\s*|\d+)$/}
                                // controlLimit = {13}
                                limit = {13}
                                className=''
                            />
                        {authUser.personType ?
                            <InputWrapper
                                name="companyName" 
                                type="text" 
                                isRequired={true} 
                                placeholder="Denumirea companiei" 
                                body="Denumirea companiei"
                                value={companyName}
                                onChange={(e) => setCompanyName(e.target.value)}
                                className=''
                            />
                            :
                            <div className='inline-wrapper'>
                            <InputWrapper
                                name="Lastname" 
                                type="text" 
                                isRequired={true} 
                                placeholder="Ursu" 
                                body="Nume"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                            <InputWrapper
                                name="Firstname" 
                                type="text" 
                                isRequired={true} 
                                placeholder="Alexandru" 
                                body="Prenume"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                        </div>}
                        <div className='inline-wrapper'>
                        <InputWrapper
                            name="phoneNumber"
                            type="text"
                            isRequired={true}
                            placeholder="Introduceți numărul de telefon"
                            body="Număr de telefon"
                            value={telephoneNumber}
                            onChange={(e) => setTelephoneNumber(e.target.value)}
                            isNumeric={true}
                            isTelephoneNumber = {true}
                            className=''
                        />
                        <InputWrapper
                            name="Email-2" 
                            type="email" 
                            isRequired={true} 
                            placeholder="exemplu@gmail.com" 
                            body="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className=''
                        />
                        </div>
                            <MyButton 
                                type="submit"
                                className = "button-with-loader"
                                body={loading ? <div className="spinner-container"><CircleSpinner loading={loading} color='#1b1b1bff' />
                                </div> : "Primește ordin de plată"}
                            />
                       
                    </form>
            </>
        }
       <div style={{ marginTop: transferMethod ?'0' :'10px' }}>
       {goBack && !updatePlan && !transferMethod &&<MyButton 
                    body = "Înapoi"
                    type = "button"
                    className="cancel-button"
                    onClick = {goBack}
                />}
                {transferMethod &&<MyButton 
                    body = "Înapoi"
                    type = "button"
                    className="cancel-button"
                    onClick = {() => setTransferMethod(false)}
                />}
       </div>
    </div>
  )
}
