import React, { useState, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import '../assets/css/Oauth2Page.css'
import InputWrapper from '../components/InputWrapper';
import ToggleButton from '../components/ToggleButton';
import { useAuth } from '../context/AuthContext';
import userService from '../services/UserService';
import MyButton from '../UI/button/MyButton';
export default function Oauth2SuccesPage() {
    const prevPath = localStorage.getItem('prevPath');
    const navigate = useNavigate();
    const [params, setParams] = useSearchParams()
    const [telephoneNumber, setTelephoneNumber] = useState('');
    const [personType, setPersonType] = useState(false);
    const [IDNO, setIDNO] = useState('');
    const { checkAuthentication, authUser, setAuthUser } = useAuth();
    function validation(){
        const phoneNumberPattern = /^(?:\+373|0)?[0-9]{8}$/;
        const IDNOPattern = /[0-9]{6,13}/;
        if (!phoneNumberPattern.test(telephoneNumber)){
            toast.error('Numărul de telefon trebuie să fie în formatul +373xxxxxxxx, 0xxxxxxxx sau xxxxxxxx.', {
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              return false;
        }
        if(personType){
            if (!IDNOPattern.test(IDNO)){
                toast.error('IDNO trebuie să conțină de la 6 pana la 13 cifre.', {
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
                  return false;
            }
        }
        return true;
    }
    useEffect(() => {
        const accessToken = params.get('accessToken');
        const refreshToken = params.get('refreshToken');
        if (accessToken && refreshToken) {
            localStorage.setItem('accessToken', accessToken);
            localStorage.setItem('refreshToken', refreshToken);
            if(!authUser){
                checkAuthentication();
            }

        }
        if (authUser && !authUser.isNewlyCreated){
            if (prevPath) {
                navigate(prevPath);
                localStorage.removeItem('prevPath');
            } else {
               navigate("/dashboard");
            }
        }
     
    }, [params, authUser]);
    useEffect(() => {
      
    }, []);


    const handleSubmit = async (e) =>{
        e.preventDefault();
        if(!validation()){
            return ;
        }
        try {
            const user = await userService.updateNewlyCreatedDetails(personType,telephoneNumber,IDNO);
            setAuthUser(user);
            toast.success('Autorizare reușită.', {
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              if (prevPath) {
                navigate(prevPath);
                localStorage.removeItem('prevPath');
            } else {
               navigate("/dashboard");
            }
        } catch (error) {
            toast.error('Ceva nu a mers bine. Vă rugăm să încercați din nou mai târziu.', {
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
        }
    }

    return (
        <div className="oauth2-page">
          {authUser && authUser.isNewlyCreated && <div className='auth-block'>
           <div className='main-section acc-section'>
           <div className='content-wrapper'>
           <h2>Autorizare Reușită!</h2>
           <p className='faint-text'>Vă rugăm să completați informațiile suplimentare pentru a finaliza procesul.</p>
            <div className='form-wrapper'>
            <div className='myInpWrapper full-width'>
                    <ToggleButton
                    labelLeft='Fizică'
                    labelRight='Juridică'
                    isChecked={personType}
                    id="personType"
                    onClickRight={() => {setPersonType(true);}}
                    onClickLeft={() => {setPersonType(false);}}
                    />
                </div>
           <form className='form-container grid selected-tab-form' onSubmit={handleSubmit}>
                <InputWrapper
                name="phoneNumber"
                type="text"
                isRequired={true}
                placeholder="Introduceți numărul de telefon"
                body="Număr de telefon"
                value={telephoneNumber}
                onChange={(e) => setTelephoneNumber(e.target.value)}
                isNumeric={true}
                isTelephoneNumber = {true}
                className="full-width"
            />
                {personType && 
                <InputWrapper
                name="IDNO"
                type="text"
                isRequired={true}
                placeholder="Introduceți IDNO"
                body="IDNO"
                value={IDNO}
                onChange={(e) => setIDNO(e.target.value)}
                regex = {/^(\s*|\d+)$/}
                // controlLimit = {13}
                limit = {13}
                className="full-width"
            />}
            <MyButton body="Salvează" className='full-width'/>
            </form>
            </div>
            </div>
            </div>
           </div>}
        </div>
    );
}
