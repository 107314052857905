import React, { useState } from 'react'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import EmailConfirmationOverlay from '../components/EmailConfirmationOverlay'
import Heading from '../components/Heading'
import Message from '../components/Message'
import RegisterForm from '../components/RegisterForm'
import useRegister from '../hooks/useRegister'

export default function SignupPage() {
  const [showOverlay, setShowOverlay] = useState(false);
    const { register, loading, emailConfirmationPrompt, setEmailConfirmationPrompt } = useRegister();
    
    useEffect(() => {
      if (emailConfirmationPrompt) {
          setShowOverlay(true);
      }
  }, [emailConfirmationPrompt]);
  return (
    <div className='auth-block'>
    <div className='main-section acc-section'>
      <div className='content-wrapper'>
            <div className='form-wrapper'>
            <Heading text="Înregistrare" />
                    <div className='form-block-box'>
                        <RegisterForm register={register} loading={loading}/>
                        <div className="faint-text switch-forms">Ai deja un cont? <Link to="/login" className='  gradient-text-2' >Autentifică-te</Link></div>
                        <Message type="success" message="Mulțumim! Înregistrarea ta a fost primită!" />
                        <Message type="failure" message="Hopa! Ceva a mers prost în timpul trimiterei formularului." />
                    </div>
            </div>
      </div>
    </div>

    <EmailConfirmationOverlay isOpen={showOverlay} onClose={() => {setShowOverlay(false);setEmailConfirmationPrompt('');}} />
    </div>
  )
}
