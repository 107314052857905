import React, { useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext';
import SubUserAdditionForm from '../Forms/SubUserAdditionForm';
import SubUsersTable from '../SubUsersTable';

export default function SubAccountsManagement() {
    const {authUser} = useAuth();
    const [subUsers, setSubUsers] = useState(null);
    useEffect(() => {
        if (authUser && authUser.subUsers) {
            setSubUsers(authUser.subUsers);
        }
    }, [authUser]);
  return (
    <div className='section-form'>
        <div className='section-top-flex'>
        <div className='section-title-info'>
          <h1 className='section-title'>Subconturi</h1>
          <p className='section-description'>Gestionarea subconturilor</p>
        </div>
        </div>
        <div className="spacer-m-2"></div>
        <div className='section-form-container'>
            <SubUserAdditionForm/>
        <div className='section-form'>
        <div className="spacer-m-2"></div>
        <div className='section-form-container'>
        <div className='subUsers-container listcalc'>
            {subUsers && subUsers.length > 0 ? <SubUsersTable
                subUsers={subUsers}
                setSubUsers={setSubUsers}
                subUsersPerPage = {5}
            />:
            <>
          <h4 style={{ textAlign: 'center' }}>
            Nu există subconturi.
          </h4>
        </>
            }
        
        </div>
        </div>
        </div>
        </div>
    </div>
  )
}
