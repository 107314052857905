import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { useCalculatorData } from '../context/CalculatorDataContext';
import authService from "../services/AuthService";

function useLogout() {
    const { setIsLoggedIn, setAuthUser } = useAuth(); 
    const navigate = useNavigate();
   const {clearData} =  useCalculatorData();
    const logout = async () => {
        try {
            // Call the logout service method
            await authService.logout();

            // Clear local app state
            setIsLoggedIn(false);
            setAuthUser(null);
            clearData();
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            localStorage.setItem('logoutEvent', Date.now().toString());
            sessionStorage.clear();
            // Redirect to login
            navigate("/login");
            
        } catch (error) {
            window.location.reload();
        }
    }

    return logout;
}

export default useLogout;

window.addEventListener('storage', (event) => {
    if (event.key === 'logoutEvent') {
        // Perform logout or reload action in response to the logout event
        // For example:
        window.location.reload(); // Reload the page to force logout
        // Or trigger your logout logic here

        // Remove the logoutEvent item from localStorage after processing
        localStorage.removeItem('logoutEvent');
    }
});
