import React, { useState } from 'react'
import { useEffect } from 'react';
import { FaArrowDown, FaArrowUp, FaCheck, FaEdit, FaSave, FaTimes, FaTrash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useCalculatorData } from '../context/CalculatorDataContext';
import { useScreenWidth } from '../hooks/useScreenWidth';
import calculatorService from '../services/CalculatorService';
import MyInput from '../UI/input/MyInput';
import Pagination from './Pagination';

export default function CalcsTable({calcs, setCalcs,allowEdit,refetchCalcsData,calcsPerPage}) {
    const [currentPage, setCurrentPage] = useState(1);
    const [editedCalcNames, setEditedCalcNames] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortBy, setSortBy] = useState('createdDate');
    const [sortOrder, setSortOrder] = useState('desc');
    const isMobileWidth = useScreenWidth();
    const navigate = useNavigate();
    useEffect(()=>{
        if(calcs){
            setEditedCalcNames(new Array(calcs.length).fill(null));
        }
    },[calcs]);
    const {
        setName,
        setPenalitateValue,
        setDobandaValue,
        setSelectedRate,
        setPenalitateType,
        setDelay,
        setActiveInput,
        setNumberValue,
        setIsQuarantine,
        setIsDetailed,
        setStartDate,
        setEntries,
        clearData,
      } = useCalculatorData();
        // Filter calcs based on search term
      const filteredCalcs = calcs?.filter((calc) => calc.name.toLowerCase().includes(searchTerm.toLowerCase())) || [];
      const visiblePages = 1;
      const indexOfLastCalc = currentPage * calcsPerPage;
      const indexOfFirstCalc = indexOfLastCalc - calcsPerPage;
      const openCalc = (calc) => {
        clearData();
        const calcData = JSON.parse(calc.calcData);
        setStartDate(new Date(calcData.penaltyDate));
        setIsDetailed(!calcData.isBrief);
        setName(calc.name);
        setIsQuarantine(calcData.isCarantine);
        if(calcData.rateValue){
          if(calcData.rateValue === 0){
            setSelectedRate(0.0);
          }else if (calcData.rateValue === 0.05){
            setSelectedRate(5);
          }else if(calcData.rateValue === 0.09){
            setSelectedRate(9);
          }else{
            setSelectedRate('custom');
            setDobandaValue(calcData.rateValue * 100);
          }
        }else{
          setSelectedRate(0.0);
        }
        const newEntries = [];
    
        // Convert scadentValues to entries
        Object.entries(calcData.scadentValues || {}).forEach(([date, due]) => {
          newEntries.push({ date: new Date(date), due, paid: null });
        });
    
        // Convert payValues to entries
        Object.entries(calcData.payValues || {}).forEach(([date, paid]) => {
          newEntries.push({ date: new Date(date), due: null, paid });
        });
    
        setEntries(newEntries);
        setDelay(calcData.daysTillStart);
        setPenalitateValue(calcData.penaltyValue);
        setPenalitateType(calcData.penaltyType);
        if (calcData.daysToCalculate === 180) {
          setActiveInput(1);
        } else if (calcData.daysToCalculate === 1095) {
          setActiveInput(2);
        } else {
          setNumberValue(calcData.daysToCalculate);
          setActiveInput(null);
        }
        if (calc.calcType === 'PENALTIES') {
          navigate('/penalitate');
        } else if (calc.calcType === 'RATES') {
          navigate('/dobanda');
        }else if (calc.calcType === 'INFLATION'){
          navigate('/inflatie');
        }else if (calc.calcType === 'COMBINED'){
          navigate('/imputatia');
        }
      };

      const sortedCalcs = filteredCalcs.slice().sort((a, b) => {
        return sortOrder === 'asc' ? a[sortBy].localeCompare(b[sortBy]) : b[sortBy].localeCompare(a[sortBy]);
      });

      const currentCalcs = sortedCalcs.slice(indexOfFirstCalc, indexOfLastCalc);
      const handleSort = (criteria) => {
        if (sortBy === criteria) {
          setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
          setSortBy(criteria);
          setSortOrder('asc');
        }
      };
    const handleEditCalc = (e, calc, index) => {
        e.stopPropagation();
        if (calc.saved) {
          setEditedCalcNames((prev) => {
            const updated = [...prev];
            updated[index] = currentCalcs[index].name;
            return updated;
          });
        }
      };
    
      const handleSaveEdit = async (e, calc, index) => {
        e.stopPropagation();
        try {
          const data = await calculatorService.renameCalculation(editedCalcNames[index], calc.calcId);
          refetchCalcsData();
        } catch (error) {
          return;
        } finally {
          setEditedCalcNames((prev) => {
            const updated = [...prev];
            updated[index] = null;
            return updated;
          });
        }
      };
    
    
      const handleCancelEdit = (e, index) => {
        e.stopPropagation();
        setEditedCalcNames((prev) => {
          const updated = [...prev];
          updated[index] = null; 
          return updated;
        });
      };
    
    const handleDeleteCalc = async (e, calc) => {
      e.stopPropagation();
      try {
        const updatedCalcs = calcs.filter((c) => c.calcId !== calc.calcId);
        setCalcs(updatedCalcs);
        await calculatorService.deleteCalculation(calc.calcId);
        sessionStorage.removeItem('calcsData');
      } catch (error) {
        
      }
    };
    const clearSearch = () => {
      setSearchTerm('');
    };
  return (
    <>
    <div className="calc-search">
        <MyInput
         value={searchTerm}
         onChange={(e) => setSearchTerm(e.target.value)}
         type="text"
        placeholder="Caută după nume"
        />
                {searchTerm && ( // Conditionally render the clear icon
          <button type="button" className="clear-search-icon" onClick={clearSearch}>
            <FaTimes />
          </button>
        )}
        </div>
      <table className='calcs-table'>
        <thead>
          <tr>
            <td className='paid-header'>Achitat</td>
            <th onClick={() => handleSort('name')}>
              Nume
              {sortBy === 'name' && (
                sortOrder === 'asc' ? <FaArrowUp size={isMobileWidth ? 6 : 15} style={{ marginLeft: '4px', verticalAlign: 'middle' }} /> :
                  <FaArrowDown size={isMobileWidth ? 6 : 15} style={{ marginLeft: '4px', verticalAlign: 'middle' }} />
              )}
            </th>
            <th onClick={() => handleSort('createdDate')}>
              Data Creării
              {sortBy === 'createdDate' && (
                sortOrder === 'asc' ? <FaArrowUp size={isMobileWidth ? 6 : 15} style={{ marginLeft: '4px', verticalAlign: 'middle' }} /> :
                  <FaArrowDown size={isMobileWidth ? 6 : 15} style={{ marginLeft: '4px', verticalAlign: 'middle' }} />
              )}
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {currentCalcs.map((calc, index) => (
            <tr key={index} onClick={() => openCalc(calc)} className="calc-row">
            <td> {calc.paid && <FaCheck className='calc-icon paid-icon' />}</td>
              <td>
             
                {editedCalcNames[index] !== null ?
                  <MyInput
                    type='text'
                    value={editedCalcNames[index]}
                    onChange={(e) => {     e.stopPropagation();
                        if (calc.saved) {
                          setEditedCalcNames((prev) => {
                            const updated = [...prev];
                            updated[index] = e.target.value;
                            return updated;
                          });
                        }
                      }}
                    onClick={(e) => e.stopPropagation()}
                  /> : calc.name}
              </td>
              <td>{new Date(calc.createdDate).toLocaleString()}</td>
              <td>
                {editedCalcNames[index] !== null ? (
                  <>
                    <div className='buttons-wrapper'>
                      <button onClick={(e) => handleSaveEdit(e,calc, index)} className="action-button" type="button">
                        <FaSave className='calc-save-icon' />
                      </button>
                      <button onClick={(e) => handleCancelEdit(e, index)} className="action-button" type="button">
                        <FaTimes className='calc-cancel-icon' />
                      </button>
                    </div>
                  </>
                ) : (
                  <div className='buttons-wrapper'>
                    {allowEdit && <button onClick={(e) => handleEditCalc(e, calc, index)} className="action-button" type="button">
                      <FaEdit className='calc-edit-icon' />
                    </button>}
                    <button onClick={(e) => handleDeleteCalc(e, calc)} className="action-button" type="button">
                      <FaTrash className='calc-delete-icon' />
                    </button>
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        calcs={sortedCalcs}
        setCurrentPage={setCurrentPage}
        calcsPerPage={calcsPerPage}
        currentPage={currentPage}
        visiblePages={visiblePages}
      />
          <svg className='hide'>
      <symbol id='left' fill='none' stroke='currentColor' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
        <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M15 19l-7-7 7-7'></path>
      </symbol>
      <symbol id='right' fill='none' stroke='currentColor' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
        <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M9 5l7 7-7 7'></path>
      </symbol>
    </svg>
    </>
  )
}
