import instance from "../interceptors/AxiosInterceptor";

const BASE_URL = "/api/subscription";
const subscriptionService = {

    cancelSubscription: async () => {
        try {
            const response = await instance.post(`${BASE_URL}/cancel-subscription`, {
                withCredentials: true
            });
            return response.data;  
        } catch (error) {
            throw error;
        }
    },
    changeCard: async (clientIp,succesUrl) => {
        try {
            const response = await instance.post(`${BASE_URL}/change-card`, {
                clientIp: clientIp,
                planId: succesUrl
            }, {
                withCredentials: true
            });
            return response.data;
        } catch (error) {
        }
    },

    updateSubscription: async (planId,maxActiveSessions) => {
        try {
            const response = await instance.post(`${BASE_URL}/update-subscription`, {
                planId,
                maxActiveSessions
            },{
                withCredentials: true
            });
            return response.data;  
        } catch (error) {

            throw error;
        }
    },
    fetchClientToken: async () => {
        try {
         const response = await instance.get(`${BASE_URL}/client-token`,{
            withCredentials: true
         });
         return response.data;  
        } catch (error) {
         throw error;
        }
    },
    getPaymentMethods: async () => {
        try {
            const response = await instance.get(`${BASE_URL}/payment-methods`,{
                withCredentials: true
            });
            return response.data;
            
        } catch (error) {
            throw error;
        }
    },
    deletePaymentMethod: async (paymentMethodToken) => {
        try {
            const response = await instance.delete(`${BASE_URL}/payment-methods/${paymentMethodToken}`);
            return response.data;
        } catch (error) {

            throw error;
        }
    },
    setDefaultPaymentMethod: async (paymentMethodToken) => {
        try {
            const response = await instance.post(`${BASE_URL}/payment-methods/${paymentMethodToken}/default`);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    createInvoice: async (transferRequest) => {
        try {
            const response = await instance.post(`${BASE_URL}/create-invoice`,
                transferRequest
            ,{
                withCredentials: true
            });
            return response.data;
        } catch (error) {

            throw error;
        }
    },
    handleSuccess: async (transactionId) => {
        try {
            const response = await instance.post(
                `${BASE_URL}/succes-handle`,
            transactionId ,
                {
                    headers: {
                        'Content-Type': 'application/json', // Set the content type to JSON
                    },
                    withCredentials: true
                }
            );
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    


   
    
    
}

export default subscriptionService;