import React, { useEffect } from 'react'
import { useState } from 'react'
import InputWrapper from '../InputWrapper'
import MyButton from '../../UI/button/MyButton'
import { useAuth } from '../../context/AuthContext';
import { toast } from 'react-toastify';
import userService from '../../services/UserService';
import VerifyCode from '../VerifyCode';
import Overlay from '../Overlay';
import useRequestChangeDetails from '../../hooks/useRequestChangeDetails';
export default function DetailsForm() {
  const { authUser, setAuthUser } = useAuth();
  
  const [firstName,setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [name,setName] = useState('');
  const [telephoneNumber, setTelephoneNumber] = useState('');
  const [showOverlay,setShowOverlay] = useState(false);
  const [resend, setResend] = useState(false);
  function handleOverlay(){
    setShowOverlay(!showOverlay);
  }
  const {requestChangeDetails, cooldown,cooldownTimeLeft,error} = useRequestChangeDetails();
  useEffect(()=>{if(error){
    setShowOverlay(false);
  }},[error]);
  useEffect(() => {
    if(authUser && authUser.personType){
      setCompanyName(authUser.name);
    }else{
    const [first, last] = authUser?.name?.split(' ') || [];
    setFirstName(first || '');
    setLastName(last || '');
    }
    if(authUser.telephoneNumber){
      setTelephoneNumber(authUser.telephoneNumber);
    }
    if(!name){
      setName(authUser.name);
    }

  }, [authUser]);
  useEffect(() => {
    if (authUser && authUser.personType) {
      setName(companyName)
    } else {
      setName(firstName + ' ' + lastName);
    }
  }, [firstName,lastName,companyName]);

  const handleDetailsSubmit = async (verificationCode) => {
    if(name === authUser.name && telephoneNumber === authUser.telephoneNumber){
      return;
    }
    try {
      if (authUser && authUser.personType) {
        setName(companyName)
      } else {
        setName(firstName + ' ' + lastName);
      }
      const data = await userService.updateUserDetails(name, telephoneNumber, verificationCode);
      if (data) {
        setAuthUser(data);
        setShowOverlay(false);
        setResend(false);
        toast.success('Datele au fost salvate cu succes!', {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      setResend(true);
      toast.error(error.response, {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const phoneNumberPattern = /^(?:\+373|0)?[0-9]{8}$/;
    if (!phoneNumberPattern.test(telephoneNumber)){
      toast.error('Numărul de telefon trebuie să fie în formatul +373xxxxxxxx, 0xxxxxxxx sau xxxxxxxx.', {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return;
  }
    if(name === authUser.name && telephoneNumber === authUser.telephoneNumber){
      return;
    }
    setShowOverlay(true);
    requestChangeDetails();
};
  return (
    <div className='section-form selected-tab-form-settings'>
      <form className='section-form-container' onSubmit={handleSubmit}>
      <div className='section-top-flex'>
        <div className='section-title-info'>
          <p className='section-description'>Foloseste forma de mai jos pentru a schimba datele despre profil.</p>
        </div>
        <div className='section-form-button'>
        <MyButton body="Salvează"/>
        </div>
      </div>
      <div className="spacer-m-2"></div>
      <div className='section-row'>
      {authUser && authUser.personType ? 
      <InputWrapper
          name="companyName" 
          type="text" 
          isRequired={true} 
          placeholder="Denumirea companiei" 
          body="Denumirea companiei"
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
          />:
      <>
      <InputWrapper
        name="firstName" 
        type="text" 
        isRequired={true} 
        body="Prenume"
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
      />
      <InputWrapper
        name="lastName" 
        type="text" 
        isRequired={true} 
        body="Nume"
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
      />
      </>}
      <InputWrapper
                name="phoneNumber"
                type="text"
                isRequired={true}
                placeholder="Introduceți numărul de telefon"
                body="Număr de telefon"
                value={telephoneNumber}
                onChange={(e) => setTelephoneNumber(e.target.value)}
                isNumeric={true}
                isTelephoneNumber = {true}
            />
      </div>
      </form>
      <Overlay
        isOpen={showOverlay}
        handleOverlay={handleOverlay}
        body={
        <VerifyCode
        handleSubmit={handleDetailsSubmit}
        handleRequestSubmit={handleSubmit}
        resend={resend}
        cooldown={cooldown}
        cooldownTimeLeft={cooldownTimeLeft}
        />}
        />
    </div>
  )
}
