import axios from 'axios';
import { toast } from 'react-toastify';

// Create an Axios instance
const instance = axios.create({
  baseURL: 'https://api.prolex.md/',
  withCredentials: true,
});
let isRefreshing = false; 
let refreshPromiseQueue = [];
function redirectToLoginOrShowError() {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  toast.error('Sesiunea d-voastra a expirat.', {
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
  setTimeout(() => {
    window.location.href = "/login";
  }, 500);

}

// Request interceptor
instance.interceptors.request.use(
  config => {
    // Check if there are tokens in local storage
    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');
    
    if (accessToken && refreshToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
      config.headers['Refresh-Token'] = refreshToken;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// Response interceptor
instance.interceptors.response.use(
  response => {
    const accessToken = response.headers['authorization'];
    const refreshToken = response.headers['refresh-token'];
      
    // Store tokens in local storage if they are present in the response headers
    if (accessToken && refreshToken) {
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);
    }
    return response;  // If the response is OK, return it
  },
  async error => {
    // Check if the request was for the login or register endpoints
    const isLoginOrRegister = error.config.url.includes("/api/auth/login") || 
      error.config.url.includes("/api/auth/register") || 
      error.config.url.includes("/api/oauth2/linkAccountWithOAuth") || 
      error.config.url.includes("/api/oauth2/linkOAuthWithCustomPassword");
    const isRefreshTokenRequest = error.config.url.includes("/api/auth/refresh-token");

    if (isRefreshTokenRequest) {
      return Promise.reject(error);
    }
  
    if (error.response && error.response.status === 401 && !isLoginOrRegister && !error.config.__isRetryRequest) {
      if (!isRefreshing) {
        isRefreshing = true;
        try {
          await instance.get('/api/auth/refresh-token', { withCredentials: true });
          isRefreshing = false;
          return instance(error.config); // Retry the original request after token refresh
        } catch (refreshError) {
          redirectToLoginOrShowError();
        }
      } else {
        // Queue the retry request
        const retryOriginalRequest = new Promise(resolve => {
          refreshPromiseQueue.push(resolve);
        });
        return retryOriginalRequest;
      }
    }
    return Promise.reject(error);
  }
);

export default instance;
