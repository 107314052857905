import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import MyButton from '../UI/button/MyButton'
import InputWrapper from './InputWrapper'
import SocialButtons from './SocialButtons'
import { CircleSpinnerOverlay } from 'react-spinner-overlay'

export default function LoginForm({login,loading,email, setEmail}) {
  const [password, setPassword] = useState('');
    

  return (
    <form className='form-container tab-form' onSubmit={(e) => {
        e.preventDefault();
        login(email, password);
    }}>
    <InputWrapper
        name="Email-2" 
        type="email" 
        isRequired={true} 
        placeholder="youremail@example.com" 
        body="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <InputWrapper
        name="Password-2" 
        type="password" 
        isRequired={true} 
        placeholder="" 
        body="Parolă"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
                            <div className='gradient-text-2 bold'>
                                <Link to={'/password/forgot'}>Ți-ai uitat parola?</Link>
                            </div>
                            <MyButton body="Autentificare" disabled={loading} className="login-button"/>
                            <CircleSpinnerOverlay loading={loading} overlayColor="rgba(0, 0, 0, 0.8)" color='#1b1b1bff' />
                            <div className='container-7'>
                            <SocialButtons />
                            </div>
                        </form>
  )
}
