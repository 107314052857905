import React from 'react'
import NumericInput from '../NumericInput'

export default function DelayContainer({delay, setDelay}) {
  return (
    <div className='delay-container'>
        <label htmlFor="delay" className='myInpLabel'>Vacanţa de plată</label>
        <NumericInput
        name="delay" 
        isRequired={true} 
        placeholder="Zile" 
        allowDecimals={false}
        value={delay}
        onChange={(e) => setDelay(e.target.value)}
        />
        </div>
  )
}
