import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import '../assets/css/Oauth2Page.css';

export default function Oauth2FailurePage() {
    const navigate = useNavigate();
    const toasts = [];

    useEffect(() => {
        navigate("/login");
        
        // Show toast only if the component is still mounted
        const toastId = toast.error('Autorizare Google Eșuată.', {
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
        
        // Add toastId to the array
        toasts.push(toastId);
        
        // Cleanup function to dismiss all toasts when component unmounts
        return () => {
            toast.dismiss(toasts[0]);
        };
    }, []);

    return (
        <div className="oauth2-page">
            <h2>Autorizare Eșuată!</h2>
            <p>
                A apărut o problemă cu procesul de autorizare. Vă rugăm să încercați din nou.
            </p>
        </div>
    );
}
