import React from 'react'
import { timesPeriod } from '../../assets/data/option';
import MySelect from '../../UI/select/MySelect';
import NumericInput from '../NumericInput';

export default function TimeCheckboxGroup({ activeInput, setActiveInput, numberValue, setNumberValue }) {
    const selectedOption = timesPeriod.find((option) => option.value === activeInput) || null;
    const handleSelectChange = (option) => {
        if (option === activeInput) {
          setActiveInput(null);
        } else {
          setActiveInput(option);
        }
      };
    return (
        <div>
            <label htmlFor="calcdays" className='myInpLabel'>Perioada</label>
            <div className='calcDays-wrapper'>
                <MySelect
                    options={timesPeriod}
                    name="calcdays"
                    value={selectedOption}
                    onChange={handleSelectChange}
                    isSearchable={false}
                    isCreatable={false}
                />

                {activeInput === 'custom' && (
                    <NumericInput
                        name="numberInput"
                        value={numberValue}
                        placeholder="Zile"
                        allowDecimals={false}
                        onChange={(e) => setNumberValue(e.target.value)}
                    />
                )}
            </div>
        </div>
    )
}