import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CircleSpinnerOverlay } from 'react-spinner-overlay';
import { toast } from 'react-toastify';
import { useAuth } from '../context/AuthContext';
import subscriptionService from '../services/SubscriptionService';
import userService from '../services/UserService';
import MyButton from '../UI/button/MyButton';

export default function SubscribeSuccesPage() {
  const {authUser,setAuthUser} = useAuth();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [orderId, setOrderId] = useState(null);
  const [transaction, setTransaction] = useState(null);
  const navigate = useNavigate();
  // Extract orderId from URL
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const orderIdParam = searchParams.get('orderId');
    setOrderId(orderIdParam);
  }, [location.search]);

  

// Delay retrieving user details for 2 seconds after component mounts
useEffect(() => {
  let timerId;
  const fetchData = async () => {
    try {
      timerId = setTimeout(async () => {
        const transactionResponse = subscriptionService.handleSuccess(orderId && !isNaN(orderId) ? parseInt(orderId) : null);
        const userData = await userService.getCurrentUser();
        setAuthUser(userData);
        setLoading(false); // Set loading to false when user details are retrieved
        
        // Move transaction check and setting inside the setTimeout callback
        if (userData && orderId) {
          const foundTransaction = userData.transactions.find(transaction => transaction.id === parseInt(orderId));
          if (foundTransaction) {
            setTransaction(foundTransaction);
            toast.success('Tranzacție efectuată cu succes!', {
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        }
      }, 3000); // 3 seconds delay
    } catch (error) {
      // Handle the error here
      console.error('Error fetching user data:', error);
      setLoading(false); // Make sure to set loading to false even if an error occurs
      // You might want to display an error message to the user or handle the error in another way
      toast.error('Error fetching user data. Please try again later.', {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  fetchData();

  return () => clearTimeout(timerId); // Cleanup the timer
}, [orderId]);
  function determinaTipAbonament() {
    if(authUser.subscription){
      if (authUser.subscription.plan.id === "lun20") {
        return 'Lunar';
      } else if (authUser.subscription.plan.id === "sem80") {
        return 'Semestrial';
      } else if (authUser.subscription.plan.id === "anu150") {
        return 'Anual';
      } else {
        return 'necunoscut';
      }
    }
    return 'necunoscut';
  }
  function formatDate(date) {
    return format(new Date(date), 'dd.MM.yyyy');
  }
  const handleContinue = () => {
    const prevPath = localStorage.getItem('prevPath');
    if (prevPath && !prevPath.includes("/subscribe-success")) {
      navigate(prevPath);
    }else{
      navigate("/dashboard")
    }
  };

  return (
    <div className='acc-section'>
      <div className='content-wrapper'>
        <div className='form-wrapper'>
          <div className='form-block-box link-wrapper tab-form'>
          {!loading && transaction ? (
            <div className='transaction-details'>
            <h2>Detalii Tranzacție</h2>
            <div className='detail-row'>
              <p className='detail-label'>ID Tranzacție:</p>
              <p className='detail-value'>{transaction.id}</p>
            </div>
            <div className='detail-row'>
              <p className='detail-label'>Cost:</p>
              <p className='detail-value'>{transaction.amount} MDL</p>
            </div>
           {authUser.subscription !== null ? <div className='detail-row'>
              <p className='detail-label'>Tip Abonament:</p>
              <p className='detail-value'>{determinaTipAbonament()}</p>
            </div> : 
            <div className='detail-row'>
            <p className='detail-label'>Număr calcule:</p>
            <p className='detail-value'>{Math.round(transaction.amount / 22)}</p>
          </div>
            }
            <div className='detail-row'>
              <p className='detail-label'>Statut:</p>
              <p className='detail-value'>{transaction.status === "successful" ? "succes": null}</p>
            </div>
            <div className='detail-row'>
              <p className='detail-label'>Data Tranzacției:</p>
              <p className='detail-value'>{formatDate(transaction.timestamp)}</p>
            </div>
            <div className='detail-row'>
              <p className='detail-label'>Număr Card:</p>
              <p className='detail-value'>{transaction.cardNumber || 'N/A'}</p>
            </div>
          </div>
         ) : (
         null
         )}
           <MyButton body="Continuă" className="continue-button" onClick={handleContinue} />
          </div>
        </div>
      </div>
      <CircleSpinnerOverlay loading={loading} overlayColor="rgba(0, 0, 0, 0.8)" color='#1b1b1bff' />
    </div>
  )
}
