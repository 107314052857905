import React from 'react'
import DashboardContainer from '../components/DashboardContainer';
import '../assets/css/Dashboard.css'
import Overlay from '../components/Overlay';
import CheckoutForm from '../components/CheckoutForm';
import { useState } from 'react';
export default function DashboardPage() {
  const [showOverlay, setShowOverlay] = useState(false);

  const handleOpenOverlay = () => {
    setShowOverlay(!showOverlay);
};
  return (
        <div className='profile-settings relative'>
            <DashboardContainer />
            <Overlay
              isOpen={showOverlay}
              handleOverlay={handleOpenOverlay}
              body={<CheckoutForm/>}
            />
      </div>
    
  )
}
