import React from 'react'
import maintenanceImg from '../assets/images/maintenance.png'
export default function UnavailablePage() {
  return (
<div className="unavailable-page">
      <div className="container">
        <img src={maintenanceImg} alt="Lucrări de întreținere" />
        <h1>Scuzați-ne pentru inconveniență!</h1>
        <p>
          În prezent, efectuăm lucrări de întreținere pentru a îmbunătăți experiența ta.
        </p>
        <p>
          Vom reveni online cât mai curând posibil. 
        </p>
        <p>
          Mulțumim pentru înțelegere!
        </p>
      </div>
    </div>
  )
}
