import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import userService from '../services/UserService';
import MyButton from '../UI/button/MyButton'

export default function SubUserConfirmationPage() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const tokenValue = queryParams.get('token');
    const name = queryParams.get('name'); 
    const navigate= useNavigate();

    const confirmSubAccount = async () => {
        try {
            const response = await userService.confirmSubUser(tokenValue);
            toast.success("Aderare la cont superior cu succes.",{
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              setTimeout(() => {
                window.location.href = '/dashboard'; 
              }, 1000);
        } catch (error) {
             toast.error("Eroare la confirmare.",{
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
        }
    }
  return (
    <div className='acc-section'>
    <div className='content-wrapper'>
      <div className='form-wrapper'>
        <div className='form-block-box link-wrapper tab-form'>
         <div>
            <div>
            <h2>Confirmare cerere subcont</h2>
            <p>Ați primit o cerere pentru a adera ca subcont la contul superior: <strong>{name}</strong>.</p>
            <MyButton onClick={confirmSubAccount} body="Confirmă cererea" />
            </div>
        </div>
    </div>
    </div>
    </div>
    </div>
  )
}
