import PenalitatePage from '../pages/PenalitatePage'
import DobandaPage from '../pages/DobandaPage'
import InflatiePage from '../pages/InflatiePage'
import ZilePage from '../pages/ZilePage'
import EmailConfirmationPage from '../pages/EmailConfirmationPage'
import ResultPage from '../pages/ResultPage'
import Oauth2SuccesPage from '../pages/Oauth2SuccesPage'
import Oauth2FailurePage from '../pages/Oauth2FailurePage'
import DefaulAccountLinkPage from '../pages/DefaulAccountLinkPage'
import PasswordForgotPage from '../pages/PasswordForgotPage'
import ResetPasswordPage from '../pages/ResetPasswordPage'
import TermsAndConditionsPage from '../pages/TermsAndConditionsPage'
import SubscribeSuccesPage from '../pages/SubscribeSuccesPage'
import SubscribeFailPage from '../pages/SubscribeFailPage'
import ImputatiaPage from '../pages/ImputatiaPage'
import UnavailablePage from '../pages/UnavailablePage'

export const calculatorRoutes = [
    {path: '/penalitate', component: PenalitatePage },
    {path: '/dobanda', component: DobandaPage },
    {path: '/inflatie', component: InflatiePage },
    {path: '/zile', component: ZilePage },
    {path: '/email-confirm/verify', component: EmailConfirmationPage },
    {path: '/rezultat', component: ResultPage },
    {path: '/success', component: Oauth2SuccesPage},
    {path: '/failure', component: Oauth2FailurePage},
    {path: '/link-acc', component: DefaulAccountLinkPage},
    {path: '/password/forgot', component: PasswordForgotPage},
    {path: '/reset-password', component: ResetPasswordPage},
    {path: '/termeni-conditii', component: TermsAndConditionsPage},
    {path: '/subscribe-fail', component: SubscribeFailPage},
    {path: '/imputatia', component: ImputatiaPage},
    {path: '/unavailable', component: UnavailablePage},
]
