import React, { useEffect, useState } from 'react'
import '../assets/css/Terms.css';
import { useAuth } from '../context/AuthContext';
import userService from '../services/UserService';
import MyButton from '../UI/button/MyButton';
export default function TermsAndConditionsPage() {
  const {authUser} = useAuth();
  const [termsAccepted, setTermsAccepted] = useState(false);
  useEffect(()=>{
    if (authUser){
      setTermsAccepted(authUser.termsAccepted);
    }
  },[authUser])
  const handleAcceptTerms = async  () => {
    try {
      await userService.acceptTerms();
      setTermsAccepted(true);
    } catch (error) {
      
    }
  };
  return (
    <div className='terms-page'>
       <div className='terms-container'>
       <h2>Termeni și condiții</h2>
        <ol>
       <li className='terms-title'>
        <h3> Dispoziții generale.</h3>
          <ol>
            <li>Calculatorul calc.prolex.md și taxa.prolex.md aparțin cu drept de proprietate intelectuală companiei SRL ”Pro-Lex IT”, ambele sunt destinate justițiabililor precum și altor categorii de utilizatori, interesați în  calcularea penalităților, dobînzilor, inflatiei, zile, taxei de stat și alte tipuri de calcule legate de aspecte financiare și juridice.
            </li>
            <li>La înregistrarea și utilizarea acestui calculator implică acceptarea tacită termenilor și condițiilor prezentate în continuare.</li>
            <li>Acești Termeni și Condiții, precum și informația despre serviciile/produsele/pachetele afișate pe site sunt oferte publice, în conformitate cu prevederile Codului civil al Republicii Moldova.
            </li>
            <li>Utilizatorul, după ce a luat cunoștință cu textul acestor condiții, le acceptă prin bifarea în drept cu fraza ”Accept Termeni și condiții”. La fel, utilizatorii acceptă Termenii și Condițiile, apasînd butonul ”Achită”.</li>
          </ol>
           </li>

<li className='terms-title'>
  <h3>Definirea noțiunilor utilizate în cadrul termenilor si conditiilor:</h3>
  <ol>
    <li><strong>Utilizator:</strong> este o persoană fizică sau juridică, care a creat sau intenționează să creeze un cont pe platformă prin completarea formularului de înregistrare.</li>
    <li><strong>Penalitati:</strong> Clauza penală este o prevedere contractuală prin care părţile evaluează anticipat prejudiciul, stimulând că debitorul, în cazul neexecutării obligaţiei, urmează să remită creditorului o sumă de bani sau un alt bun (penalitate).</li>
    <li><strong>Dobînda de întîrziere</strong> în executarea obligațiilor pecuniare
        <ol>
        <li>În cazul în care o sumă de bani nu este plătită la scadență, creditorul are dreptul la dobândă de întîrziere pentru suma respectivă din data imediat următoare scadenței plății pînă la data în care s-a efectuat plata, la rata prevăzută la alin. (2) ori la o altă rată prevăzută de dispozițiile legale speciale.</li>
        <li>Rata dobînzii de întîrziere este egală cu rata prevăzută la art. 874 plus 5 puncte procentuale pe an, în cazul în care debitorul este un consumator, sau 9 puncte procentuale pe an, în celelalte cazuri. Cu toate acestea, dacă înainte de scadență obligația pecuniară era purtătoare de dobândă prevăzută de contract, în scopul determinării ratei dobînzii de întîrziere, creditorul poate înlocui rata prevăzută la art. 874 cu rata dobînzii prevăzute de contract.</li>
        <li>Debitorul consumator are dreptul să facă dovada că prejudiciul suferit de creditor ca urmare a întîrzierii plății este mai mic decît rata legală a dobînzii de întîrziere.</li>
        <li>În cazul în care s-a stipulat o clauză penală, creditorul poate să ceară, la alegere, fie dobînda de întîrziere calculată conform dispozițiilor prezentului articol, fie penalitatea pentru întîrziere. De asemenea, creditorul poate cere despăgubiri pentru prejudiciul cauzat în partea neacoperită de dobînda de întîrziere.</li>
        <li>Pe perioada în care suma de bani nu este plătită la scadență cu justificare, debitorul datorează doar dobînda de întîrziere la rata prevăzută la art. 874. Debitorul consumator nu datorează asemenea dobândă de întîrziere.</li>
        </ol>
        </li>
        <li><strong>Inflatie:</strong> Indicii prețurilor de consum, luna precedenta=100 pe Grupe majore, Ani și Luni.</li>
        <li><strong>Rata CHIBOR:</strong> rata de referință CHIBOR este rata calculată în modul stabilit de Banca Națională a Moldovei, în baza cotațiilor orientative/ferme ale băncilor contributorii pentru plasarea mijloacelor bănești în lei moldovenești la alte bănci, pe termen de 12 luni.</li>
        <li><strong>Taxa de stat:</strong> sumă de bani care se percepe de către organele de stat și de către alte entități împuternicite special pentru acest fapt de la persoanele fizice și juridice în ale căror interese se efectuează acțiuni sau se eliberează documente.</li>
        <li><strong>Taxa de timbru:</strong> Taxa de timbru este o sumă de bani care se percepe, o singură dată, de la persoanele fizice și juridice pentru intentarea unui proces judiciar civil, de contencios administrativ, precum și pentru fiecare contestație împotriva deciziei agentului constatator asupra cauzei contravenționale atât în primă instanță, cât și în căile de atac.</li>

  </ol>
</li>
<li className='terms-title'>
  <h3>Token-uri.</h3>
  <ol>
    <li>O alternativă la abonamente o reprezintă achiziționarea de token-uri. Un token este echivalent cu un calcul individual. Achiziționarea token-urilor este recomandată utilizatorilor care au nevoie de calcule ocazionale.</li>
    <li><strong>Prețuri token-uri:</strong> 1 token = 22 MDL</li>
    <li><strong>Utilizarea token-urilor:</strong><br />
     <ol>
      <li> Pentru a utiliza un token, pur și simplu selectați calculatorul dorit, introduceți valorile necesare și faceți clic pe butonul "Calculare". Un token va fi dedus automat din contul dvs.</li>
     </ol>
    </li>
    <li><strong>Restricții:</strong><br />
    <ol>
      <li>Posesorii de abonamente active nu pot achiziționa token-uri pe același cont.</li>
      <li>Token-urile nu sunt rambursabile.</li>
      </ol>
    </li>
</ol>
</li>

<li className='terms-title'>
  <h3>Termenii și condițiile de plată.</h3>
  <ol>
    <li>Serviciile de calculare a penalitatilor, dobanzilor, inflației și taxei de stat sunt disponibile contra cost, în limita pachetelor oferite.</li>
    <li>Utilizatorii vor fi taxați pentru utilizarea serviciilor oferite. Plata în acest sens, se va face în avans.</li>
    <li>Pentru a beneficia de aceste servicii, utilizatorii trebuie să achiziționeze unul dintre pachetele disponibile, conform tarifelor:
    <table>
        <thead>
        <tr>
            <th colSpan={3}>Pachete</th>
        </tr>
        </thead>
        <tbody>
        <tr>
            <td>Lunar</td>
            <td>Semestrial</td>
            <td>Anual</td>
        </tr>
        <tr>
            <td>300 lei</td>
            <td>1359 lei</td>
            <td>2520 lei</td>
        </tr>
        </tbody>
    </table>
    </li>
    <li>Utilizatorii au dreptul de a accesa și utiliza serviciile de calculare a penalitatilor, dobanzilor, inflației și taxei de stat, în baza pachetelor oferite, după achiziționarea acestora conform tarifelor afișate</li>
    <li>SRL ”Pro-Lex IT” își rezervă dreptul de a modifica tarifele fără notificare prealabilă.</li>
    <li>Serviciile se consideră achitate doar după ce suma abonamentului ales, este înregistrată în contul SRL "Pro-Lex IT" iar utilizatorul primește confirmarea prin e-mail a recepționării plății.</li>
  </ol>
</li>

<li className='terms-title'>
  <h3>Modalități de plată</h3>
  <ol>
    <li>Plata se poate efectua prin transfer bancar sau card de plată.</li>
    <li>Plata se face în condiții de siguranță maximă folosind ca instrument de plată cardul de plată, care permite efectuarea de tranzacții online. După efectuarea plății veți primi o confirmare de plata pe adresa dvs. de email.</li>
    <li>Procesatorul de plata folosește un sistem securizat, bazat pe ultima versiunea a standardului de securitate 3D-Secure ceea ce înseamnă o nouă abordare globală a autentificării cumpărătorilor în tranzacții sigure online. Aceasta măsura de siguranța presupune redirecționarea utilizatorului în momentul efectuării plații pe o pagina securizata maib unde autentificarea fiecărui deținător de card se face prin atribuirea unui cod de unică folosințe pentru fiecare tranzacție online.</li>
    <li>Rambursarea mijloacelor bănești se efectuează doar pe cardul de plată care a fost folosit pentru achiziție.</li>
    <li>Pentru a efectua o plată, va trebui să introduceți detaliile cardului dvs. bancar. Transferul acestor informații se face în conformitate cu toate măsurile de securitate necesare. Informațiile sunt transmise în formă criptată și sunt stocate numai pe un server specializat al sistemului de plăți.</li>
    <li>Pentru efectuarea plății vor fi solicitate următoarele date:
      <ol>
        Numărul cardului (16 cifre)<br />
        Data expirării (luna și anul)<br />
        Codul CVC sau CVV (3 cifre)<br />
        Numele și prenumele de pe cardul de plată
      </ol>
    </li>
  </ol>
</li>


<li className='terms-title'>
  <h3>Perioadele de abonament</h3>
  <ol>
    <li>Platforma calc.prolex.md oferă utilizatorilor trei opțiuni de abonament: lunar, semestrial și anual. Fiecare pachet include acces la toate funcțiile platformei, cu variații în ceea ce privește durata accesului și prețul.</li>
    <li>După expirarea perioadei de abonament, utilizatorul are opțiunea de a renunța la servicii sau de a reînnoi abonamentul pentru o nouă perioadă.</li>
    <li>Prin introducerea datelor bancare, utilizatorul își exprimă acordul pentru ca sistemul să debiteze automat cardul asociat contului său cu suma aferentă abonamentului ales, la data scadentă a fiecărei perioade de facturare.</li>
    <li>Utilizatorul are posibilitatea de a anula oricând plata automată a abonamentului prin accesarea cabinetului său personal și selectarea opțiunii "Anulare abonament".</li>
    <li>Anularea plății automate va duce la încetarea reînnoirii automate a abonamentului la sfârșitul perioadei de facturare curente.</li>
    <li>Utilizatorul va fi notificat prin e-mail cu privire la data scadentă a plății și la suma ce urmează a fi debitată.</li>
    <li>În cazul în care plata automată eșuează din cauza lipsei fondurilor suficiente pe cardul asociat contului, utilizatorul va fi notificat prin e-mail și i se va oferi posibilitatea de a actualiza datele bancare sau de a alege o altă modalitate de plată.</li>
    <li>Utilizatorul poate consulta istoricul plăților efectuate pentru abonament în cabinetul său personal.</li>
  </ol>
</li>


<li className='terms-title'>
  <h3>Drepturile și obligațiile utilizatorilor</h3>
  <ol>
    <li>”Pro-Lex IT” nu este responsabil pentru exactitatea și corectitudinea informațiilor furnizate de utilizator în timpul înregistrării.</li>
    <li>Utilizatorii au dreptul de a accesa și utiliza serviciile platformei, conform termenilor și condițiilor stabilite.</li>
    <li>Utilizatorii nu au dreptul de a utiliza serviciile în scopuri ilegale sau frauduloase.</li>
    <li>Utilizatorii trebuie să respecte regulile de utilizare a serviciilor și să nu încalce drepturile de autor sau alte drepturi ale terților.</li>
    <li>”Pro-Lex IT” nu-și asumă responsabilitatea pentru eventualele erori sau omisiuni în rezultatele calculate din culpa utilizatorilor.</li>
    <li>Se interzice transmiterea accesului la contul personal către terțe persoane cu excepția cazului când utilizatorul achiziționează un abonament pentru mai mulți utilizatori activi.</li>
    <li>În cazul în care utilizatorii încalcă termenii și condițiile sau utilizează serviciile în mod abuziv, ”Pro-Lex IT” își  rezervă dreptul de a suspenda sau anula accesul acestora la servicii, fără notificare prealabilă și fără rambursarea sumelor achitate pentru abonament. Suma rămasă pentru abonamentul achitat dar nefolosit, va avea statut de penalitate pentru încălcarea termenilor și condițiilor.</li>
    <li>Serviciile de calculare a penalitatilor, dobanzilor, inflației sunt contra cost și utilizatorii sunt obligați să achite tarifele specificate în pachetele oferite.</li>
    <li>Utilizatorii trebuie să fie persoane juridice sau fizice cu capacitatea juridică, apte de a încheia contracte publice.</li>
    <li>Utilizatorii pot solicita în orice moment întreruperea serviciilor și anularea abonamentului, în acest caz, sumele achitate pentru abonament/e nu se vor restitui.</li>
    <li>SRL ”Pro-Lex IT” nu își asumă responsabilitatea pentru eventualele erori sau defecțiuni ale serviciului, iar utilizatorii îl folosesc pe propria răspundere.</li>
  </ol>
</li>
<li className='terms-title'>
  <h3>Politica de modificare a "termenilor și condițiilor"</h3>
  <ol>
    <li>Ne rezervam dreptul de a modifica sau actualiza termenii și condițiile în orice moment, fara notificare prealabila. Utilizatorii vor fi informați cu privire la orice modificări prin intermediul site-ului nostru sau prin alte mijloace de comunicare.</li>
    <li>Orice modificare a termenilor și condițiilor va intra în vigoare imediat după publicarea acestora pe site-ul nostru.</li>
    <li>Utilizatorii sunt responsabili să verifice periodic termenii și condițiile pentru a fi la curent cu eventualele modificări.</li>
    <li>Continuarea utilizării serviciilor de calculare a penalităților, dobânzilor, inflației și taxei de stat de pe platforma calc.prolex.md, prezumă acceptarea noilor termeni și condiții.</li>
    </ol>
</li>

<li className='terms-title'>
  <h3>Drepturi de autor</h3>
  <ol>
    <li>Toate drepturile de proprietate intelectuală asupra site-ului prolex.md, calc.prolex.md, taxa.prolex.md, inclusiv logica, algoritmi, conținutul, designul și orice alt material prezent pe site-uri, sunt deținute de SRL ”Pro-Lex IT” și sunt protejate de legea privind dreptul de autor și drepturile conexe.</li>
    <li>Toate rezultatele obținute prin intermediul calc.prolex.md, taxa.prolex.md sunt proprietate intelectuală a  SRL ”Pro-Lex IT” și nu pot fi replicate fără acordul scris al acesteia.</li>
   </ol>
</li>

<li className='terms-title'>
  <h3>Condiții de utilizare. Garanții.</h3>
  <ol>
    <li>”Pro-Lex IT” SRL nu garantează ca serverele pe care este gazduit site-ul, sau e-mailurile trimise de la prolex.md sunt fără viruși sau alte componente informatice cu caracter potențial dăunător.</li>
    <li>Utilizatorii folosesc platformele calc.prolex.md și taxa.prolex.md pe riscul propriu, ”Pro-Lex IT” SRL fiind liber de orice răspundere pentru eventualele daune directe sau indirecte cauzate de utilizarea sau accesarea/vizitarea platformelor sau ca urmare a utilizării informațiilor de pe acestea. </li>
   </ol>
</li>

<li className='terms-title'>
  <h3>Protecția datelor cu caracter personal</h3>
  <ol>
    <li>Prin utilizarea site-ului calc.prolex.md, sunteți de acord în mod automat cu colectarea și prelucrarea datelor cu caracter personal, care sunt necesare pentru procesarea/confirmarea/executarea abonamentelor.</li>
    <li>Datele personale se prelucrează doar pentru scopuri legitime, precum acela de a furniza accesul la serviciile pe care le comanzi, promoții, google analytics, cookies și notificări\newsletter etc.</li>
    <li>Toată informația ce conține date cu caracter personal este stocată și utilizată doar pentru perioada necesară atingerii scopurilor pentru care a fost colectată și în conformitate cu prevederile stabilite de Legea nr. 133/2011 privind protecția datelor cu caracter personal.</li>
    <li>Noi folosim măsuri de securitate comerciale pentru a preveni accesul neautorizat, menținerea exactității datelor și pentru asigurarea utilizării corecte a informației de pe site. Transmiterea datelor pe internet sau prin rețea wireless nu poate fi protejată în totalitate.</li>
    <li>Ne angajăm să protejăm informațiile utilizatorilor prin implementarea unor măsuri de securitate robuste. Cu toate acestea, este important de subliniat că securitatea online nu este niciodată garantată complet. Similar cu alte companii, nu putem oferi o garanție absolută împotriva breșelor de securitate sau a pierderii datelor. Utilizatorii sunt încurajați să ia propriile măsuri de precauție pentru a-și proteja informațiile, cum ar fi utilizarea parolelor complexe și evitarea accesării site-urilor web nesigure.</li>
    <li>Utilizatorii sunt responsabili de datele lor personale publicate pe https//calc.prolex.md/, de rectificarea sau șterge acestora.</li>
    <li>Utilizatorii sunt responsabili pentru protejarea confidențialității contului lor și a datelor personale asociate și trebuie să notifice imediat orice utilizare neautorizată sau încălcare a securității.</li>
  </ol>
</li>

<li className='terms-title'>
  <h3>Politica de confidențialitate</h3>
  <ol>
    <li>În conformitate cu prevederile Legii Nr.133/2011 privind protecția datelor cu caracter personal, furnizorul de servicii prelucrează date cu caracter personal. Datele cu caracter personal sunt prelucrate cu bună-credință și se face în temeiul și în conformitate cu prevederile legale. Prelucrarea datelor cu caracter personal se realizează prin mijloace mixte (automate și manuale), cu respectarea cerințelor legale și în condiții care să asigure securitatea, confidențialitatea și respectarea drepturilor persoanelor vizate.</li>
    <li>Furnizorul colectează și prelucrează doar datele personale furnizate de utilizatori la înregistrarea și achitarea abonamentului: nume, prenume, idno/idnp, număr de telefon, e-mail ș.a..</li>
    <li>Datele personale sunt utilizate exclusiv în scopul administrării site-ului, pentru a permite accesul la informații special, pentru livrarea serviciilor sau pentru a contacta utilizatorul.</li>
    <li>Prin prezenta informare, utilizatorii și consumatorii iau cunoștință despre faptul că li se vor prelucra și utiliza datele cu caracter personal (nume, prenume, idno/idnp, număr de telefon, e-mail ș.a.). </li>
    <li>”Pro-Lex IT” prelucrează datele cu caracter personal ale utilizatorilor care sunt furnizate prin navigare, folosirea și înregistrarea pe site-ul calc.prolex.md</li>
    <li>”Pro-Lex IT” nu va dezvălui unei terțe părți niciuna dintre datele deținute, fără acordul persoanelor vizate și nu va comercializa, schimba, divulga aceste date cu alte persoane, cu excepțiile prevăzute de legislația în vigoare (la solicitarea instituțiilor publice ale statului, organele de drept, instanțelor de judecată, de asemenea autorizate să prelucreze date cu caracter personal).</li>
    <li>Datele cu caracter personal pot fi prelucrate și utilizate de către ”Pro-Lex IT” și în scopuri statistice și de promovare ulterioară.</li>
    <li>”Pro-Lex IT” îndeplinește cerințele de securitate a datelor cu caracter personal și asigură protecția datelor consumatorilor și le protejează pentru a nu fi pierdute, distruse, distorsionate/falsificate sau divulgate terțelor persoane.</li>
    <li>Angajații ”Pro-Lex IT” sunt obligați să respecte confidențialitatea datelor personale ale utilizatorilor. Prelucrarea datelor cu caracter personal va fi realizată de persoana împuternicită.</li>
    <li>”Pro-Lex IT” nu colectează informații referitoare la tranzacții, cum ar fi nr. la card, data expirării, țara de origine, cod ccv / cvv.</li>
    <li>Pentru mai multe detalii și informații orice persoană interesată se poate adresa printr-un email la office@prolex.md sau prin telefon la numărul +373 69 767 760</li>
  </ol>
</li>

<li className='terms-title'>
  <h3>Soluționarea disputelor</h3>
  <ol>
    <li>În caz de apariție a unor dispute între Utilizator și ”Pro-Lex IT”, părțile vor depune toate eforturile pentru a le soluționa pe cale amiabilă, prin negocieri directe.</li>
    <li>Dacă soluționarea amiabilă a disputei nu este posibilă în termen de 30 de zile de la data trimiterii notificării de către o parte către cealaltă parte, orice parte poate sesiza disputa unui mediator. Costurile medierii vor fi suportate în mod egal de către ambele părți.</li>
    <li>Orice dispută care nu poate fi soluționată prin celelalte metode enumerate mai sus va fi deferită spre soluționare instanțelor judecătorești competente din Republica Moldova.</li>
    <li>Prezenții Termeni și Condiții, precum și orice dispute care ar putea apărea în legătură cu aceștia, vor fi guvernate și interpretate conform legislației Republicii Moldova.</li>
    <li>Acești Termeni și Condiții reprezintă întregul acord dintre Utilizator și ”Pro-Lex IT” cu privire la obiectul lor și înlocuiesc orice alte acorduri sau înțelegeri anterioare, scrise sau verbale, care ar putea exista între părți cu privire la același obiect.</li>
   </ol>
</li>

<li className='terms-title'>
  <h3>Datele de contact</h3>
  <ol>
    Denumirea juridica a companiei: SRL ”Pro-Lex IT” <br />
    IDNO: 1018600049526 <br />
    Adresa juridica: MD4814, mun. Chişinău, s. Budeşti, str. M. Cebotari 11 <br />
    Telefon de contact: +373 69 767 760 <br />
    Email de contact: office@prolex.md 
   </ol>
</li>


</ol>
{authUser && !termsAccepted &&  (
        <div className='terms-confirmation'>
          <MyButton
            type='button'
            onClick={handleAcceptTerms}
            body="Accept Termenii și condițiile"
          />
        </div>
      )}
       </div>
    </div>
  )
}
