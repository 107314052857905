import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import authService from '../services/AuthService';
import '../assets/css/EmailConfirmationPage.css'
import MyButton from '../UI/button/MyButton';
import { CircleSpinner, CircleSpinnerOverlay } from 'react-spinner-overlay';
import { toast } from 'react-toastify';
import useResendVerificationEmail from '../hooks/useResendVerificationEmail';
function EmailConfirmationPage() {
    const [isConfirmed, setIsConfirmed] = useState(false);
    const [loading, setLoading] = useState(true);  // Added state for loading
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const { resendVerificationEmail, loading: resendLoading, cooldown,cooldownTimeLeft } = useResendVerificationEmail();
    

    // Get token from the query parameters
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    const email = searchParams.get('email');

    useEffect(() => {
        if(!email || !token){
          navigate('/login');
        }
          async function confirmEmail() {
            try {
              if(!isConfirmed){
                await authService.confirmEmailToken(token); 
                setIsConfirmed(true);
                navigate('/login');
                toast.success("Email confirmat cu succes.",{
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
              }   
            } catch (error) {
              if (error.response && error.response.status === 400) {
                  toast.error(error.response.data,{
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
              } else {
                toast.error('Confirmarea emailului a eșuat. Vă rugăm să încercați din nou mai târziu.',{
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
              }
              
          }finally{
            setLoading(false);
          }
          }

        if (token) {
            confirmEmail();
        } else {
            setError('TOKEN_INVALID_OR_EXPIRED');
            setLoading(false);
        }

    }, [token]);


  return (
    <div className='email-confirm-div'>
        <CircleSpinnerOverlay loading={loading} overlayColor="rgba(0, 0, 0, 0.8)" color='#1b1b1bff' />
        {isConfirmed && <p>Email confirmat! Redirecționare către autentificare...</p>}
        {!isConfirmed && error === "TOKEN_INVALID_OR_EXPIRED" && 
        <div className='resend-container tab-form'>
            <p>Hopa! Link-ul tău de verificare pare a fi invalid sau a expirat. Te rugăm să soliciți unul nou.</p>
            { cooldown ? 
                        <MyButton disabled body={`Trimite Email in ${cooldownTimeLeft}s`} /> :
                        resendLoading ?  <MyButton onClick={() => resendVerificationEmail(email,'email')} className="button-h45" body={<div className="spinner-container"><CircleSpinner loading={resendLoading} color='#1b1b1bff' /></div>} />
                        : <MyButton onClick={() => resendVerificationEmail(email,'email')} body="Trimite Email" /> }
        </div>
        } 
    </div>
);
  }
export default EmailConfirmationPage;