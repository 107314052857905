import React from 'react'
import { valute } from '../../assets/data/option'
import MySelect from '../../UI/select/MySelect'

export default function ValutaContainer({valuta,setValuta}) {
  const selectedOption = valute.find(option => option.value === valuta) || null;
  return (
    <div className='container-valuta'>
        <label  className='myInpLabel'>Valuta</label>
        <MySelect 
        options={valute} 
        name="valute"
        value={selectedOption}
        onChange={setValuta}
        />
        </div>
  )
}
