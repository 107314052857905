import React from 'react';
import { CircleSpinner } from 'react-spinner-overlay';
import '../assets/css/Overlay.css'
import useResendVerificationEmail from '../hooks/useResendVerificationEmail';
import MyButton from '../UI/button/MyButton';
function EmailConfirmationOverlay({ isOpen, onClose,isLoginAttempt,email}) {
    const { resendVerificationEmail,error,loading,cooldownTimeLeft,cooldown } = useResendVerificationEmail();
    if (!isOpen) {return null;}
    return (
        <div className="overlay">
            <div className="overlay-content">
                <h2>Confirmare Email</h2>
                <p>Va rugam sa verificati adresa Dvs de E-mail, pentru a activa contul.</p>
                <p>Linkul este valabil doar 2 ore.</p>
                {isLoginAttempt ? 
                    cooldown ? 
                        <MyButton disabled body={`Trimite Email in ${cooldownTimeLeft}s`} /> :
                        loading ?  <MyButton onClick={() => resendVerificationEmail(email,'email')} className="button-h45" body={<div className="spinner-container"><CircleSpinner loading={loading} color='#1b1b1bff' /></div>} />
                        : <MyButton onClick={() => resendVerificationEmail(email,'email')} body="Trimite Email" /> 
                : null}
                <MyButton onClick={onClose} body="Închide" />
            </div>
        </div>
    );
}

export default EmailConfirmationOverlay;
