import React, { useState } from 'react';
import '../assets/css/uploader.css';
import ExampleCSV from '../assets/images/csv-example.jpg';
function Uploader({ onFileUpload,handleOverlay }) {
  const [fileName, setFileName] = useState('');

  const handleFileChange = ({ target: { files } }) => {
    if (files[0] && files[0].type === 'text/csv') {
      setFileName(files[0].name);
      handleOverlay();
      onFileUpload(files[0]);
    } else {
      alert('Vă rugăm să selectați un fișier CSV valid.');
      setFileName('');
    }
  };

 

  return (
    <div>
        <div className="upload-container">
      <label className="upload-button" htmlFor="fileInput">
      Alege fișier CSV
      </label>
      <input
        type="file"
        id="fileInput"
        accept=".csv"
        onChange={handleFileChange}
      />
      <div className="file-name">{fileName}</div>
    </div>
    <div className='example-section'>
            <h4>Exemplu format de date in fisier CSV</h4>
        <img src={ExampleCSV} alt="Example CSV" />
    </div>
    </div>
  );
}

export default Uploader;
