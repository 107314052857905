import React, { useEffect, useState } from 'react';
import { FaArrowDown, FaArrowUp, FaTimes, FaTrash } from 'react-icons/fa';
import { toast } from 'react-toastify';
import userService from '../services/UserService';
import MyInput from '../UI/input/MyInput';
import Pagination from './Pagination';

export default function SubUsersTable({ subUsers, setSubUsers, subUsersPerPage }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState('email');
  const [sortOrder, setSortOrder] = useState('asc');
  
  useEffect(() => {
    if (subUsers) {
      setCurrentPage(1); // Reset to first page on data change
    }
  }, [subUsers]);

  // Filter subUsers based on search term
  const filteredSubUsers = subUsers?.filter((user) =>
    user.email.toLowerCase().includes(searchTerm.toLowerCase())
  ) || [];

  // Pagination logic
  const indexOfLastSubUser = currentPage * subUsersPerPage;
  const indexOfFirstSubUser = indexOfLastSubUser - subUsersPerPage;
  const currentSubUsers = filteredSubUsers.slice(indexOfFirstSubUser, indexOfLastSubUser);
  const visiblePages = 1;

  // Sorting logic
  const sortedSubUsers = filteredSubUsers.slice().sort((a, b) => {
    const comparison = a[sortBy].localeCompare(b[sortBy]);
    return sortOrder === 'asc' ? comparison : -comparison;
  });

  const handleSort = (criteria) => {
    if (sortBy === criteria) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(criteria);
      setSortOrder('asc');
    }
  };

  const handleDeleteSubUser = async (id) => {
    try {
      await userService.deleteSubUser(id); // Call the delete function from the service
      setSubUsers(subUsers.filter((user) => user.id !== id));
      toast.success("Subcontul a fost șters cu succes.", {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      toast.error("A apărut o eroare la ștergerea subcontului.", {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const clearSearch = () => {
    setSearchTerm('');
  };

  return (
    <>
      <div className="calc-search">
        <MyInput
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          type="text"
          placeholder="Caută după email"
        />
        {searchTerm && (
          <button type="button" className="clear-search-icon" onClick={clearSearch}>
            <FaTimes />
          </button>
        )}
      </div>
      <table className="calcs-table">
        <thead>
          <tr>
            <th onClick={() => handleSort('email')}>
              Email
              {sortBy === 'email' && (
                sortOrder === 'asc' ? (
                  <FaArrowUp style={{ marginLeft: '4px' }} />
                ) : (
                  <FaArrowDown style={{ marginLeft: '4px' }} />
                )
              )}
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {currentSubUsers.map((user, index) => (
            <tr key={index} className="calc-row">
              <td>{user.email}</td>
              <td>
                <div className="buttons-wrapper">
                  <button
                    onClick={() => handleDeleteSubUser(user.id)}
                    className="action-button"
                    type="button"
                  >
                    <FaTrash className="calc-delete-icon" />
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        calcs={sortedSubUsers}
        setCurrentPage={setCurrentPage}
        calcsPerPage={subUsersPerPage}
        currentPage={currentPage}
        visiblePages={visiblePages}
      />
    </>
  );
}
