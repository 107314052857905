import React from 'react'
import SocialButton from './SocialButton'
import { ReactComponent as GoogleIcon } from '../assets/icons/icons8-google.svg'
import { useNavigate } from 'react-router-dom'
export default function SocialButtons() {
  return (
                                <div className='wrap-all-buttons'>
                                    <div className='faint-text'>
                                    sau autentifică-te cu:
                                    </div>
                                    
                                    <div className='wrap-bottom-buttons'>
                                    <SocialButton Icon={GoogleIcon} onClick={(e) => {e.preventDefault(); window.location.href='https://api.prolex.md/api/oauth2/authorization/google';}} />
                                    {/* <SocialButton Icon={FacebookIcon}  />
                                    <SocialButton Icon={ViberIcon}  /> */}
                                    </div>
                                </div>
  )
}
