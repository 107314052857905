import React from 'react'
import CustomDatePicker from '../CustomDatePicker'

export default function StartDatePicker({startDate, setStartDate, label}) {
  return (
    <div>
         <label htmlFor="datepicker1" className='myInpLabel'>{label}</label>
        <CustomDatePicker 
        name="datepicker1"
        value={startDate}
        onChange={setStartDate}/>
        </div>

  )
}
