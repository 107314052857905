import React, {useState, useRef} from 'react'
import CustomDatePicker from '../components/CustomDatePicker';
import '../assets/css/ZilePage.css';
import MyInput from '../UI/input/MyInput';
import NumericInput from '../components/NumericInput';
import MyTextarea from '../UI/textarea/MyTextarea';
import DaysResults from '../components/DaysResults';
import {Popover, ArrowContainer} from '../UI/popover/Popover.tsx'

export default function ZilePage() {

    const newDate = new Date();
    const [firstDate, setFirstDate] = useState(newDate);
    const [lastDate, setLastDate] = useState(
        new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate() + 10)
    );

    const [firstDayInclusive, setFirstDayInclusive] = useState(false);
    const [lastDayInclusive, setLastDayInclusive] = useState(false);

    const [daysInterval, setDaysInterval] = useState('');

    const [holidays, setHolidays] = useState('');

    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const clickMeButtonRef = useRef(HTMLButtonElement | undefined);

    return (
        <div className='calculator-page'>
            <form className='form-container calculator-form'>
                <div className='calculator-container'>
                    <div className='calculator-left-side'>
                        <div className='inline-wrapper'>
                            <div className='input-column'>
                                <label className="myInpLabel" htmlFor='firstDate'>De la</label>
                                <CustomDatePicker
                                    name='firstDate'
                                    value={firstDate}
                                    onChange={setFirstDate}
                                    placeholder={'De la'}/>
                                <label className="myInpLabel" htmlFor='firstDayCheck'></label>
                                <MyInput
                                    name="firstDayCheck"
                                    type="checkbox"
                                    checked={firstDayInclusive}
                                    onChange={(e) => setFirstDayInclusive(e.target.checked)}
                                    label="Inclusiv prima zi"/>
                            </div>

                            <div className='input-column'>
                                <label className="myInpLabel" htmlFor='lastDate'>Până la</label>
                                <CustomDatePicker
                                    name='lastDate'
                                    value={lastDate}
                                    onChange={setLastDate}
                                    placeholder={'Până la'}/>
                                <label className="myInpLabel" htmlFor='lastDayCheck'></label>
                                <MyInput
                                    name="lastDayCheck"
                                    type="checkbox"
                                    checked={lastDayInclusive}
                                    onChange={(e) => setLastDayInclusive(e.target.checked)}
                                    label="Inclusiv ultima zi"/>
                            </div>
                        </div>

                        <div className='daysIntervalDiv'>
                            <label className="myInpLabel" htmlFor='daysInterval'>Interval de zile</label>
                            <NumericInput
                                name='daysInterval'
                                value={daysInterval}
                                onChange={(e) => setDaysInterval(e.target.value)}
                                allowDecimals="allowDecimals"
                                placeholder='0'/>
                        </div>

                        <div className='holidaysDiv'>
                            <div
                                style={{
                                    display: 'inline'
                                }}>

                                <Popover 
                                  isOpen={isPopoverOpen} 
                                  positions={['right', 'bottom', 'top']} 
                                  padding={10} ref={clickMeButtonRef}
                                  content={({position, childRect, popoverRect}) => (
                                      <ArrowContainer
                                        position={position} 
                                        childRect={childRect} 
                                        popoverRect={popoverRect} 
                                        arrowColor={'white'} 
                                        arrowSize={10} 
                                        className='popover-arrow-container' 
                                        arrowClassName='popover-arrow'>
                                          <div
                                              style={{
                                                  backgroundColor: 'white',
                                                  width: '100%',
                                                  borderRadius: '5px',
                                                  border: '1.5px solid lightgrey',
                                                  fontFamily: 'Montserrat, sans-serif',
                                                  fontSize: '14px',
                                                  lineHeight: '1.6'
                                              }}
                                            >
                                                <div
                                                  className='header'
                                                  style={{
                                                      height:'40px',
                                                      backgroundColor: 'lightgrey',
                                                      padding: '0px 15px 0px 15px',
                                                      alignItems: 'center',
                                                      display: 'flex'
                                                  }}>
                                                    Exemplu
                                                </div>
                                              <div
                                                  className='body'
                                                  style={{
                                                    padding: '12px 15px 12px 15px',
                                                    alignItems: 'center'
                                                  }}>
                                                  <p><b>25.12.2020 - 15.01.2021</b>: se indică intervalul dintre 2 date</p>
                                                  <p><b>08.03.2021</b>: după acest model pot fi indicate mai multe date</p>
                                              </div>
                                          </div>
                                      </ArrowContainer>
                                    )}>
                                    <u
                                        onMouseEnter={() => setIsPopoverOpen(true)}
                                        onMouseLeave={() => setIsPopoverOpen(false)}
                                    >
                                        Adaugă zile nelucrătoare
                                    </u>
                                </Popover>

                            </div>

                            <MyTextarea
                                name='holidays'
                                label='Adaugă zile nelucrătoare'
                                value={holidays}
                                onChange={(e) => setHolidays(e.target.value)}
                                />
                        </div>
                    </div>

                    <div className='calculator-right-side'>
                        <DaysResults 
                          firstDate={firstDate} 
                          lastDate={lastDate} 
                          firstDayInclusive={firstDayInclusive} 
                          lastDayInclusive={lastDayInclusive} 
                          daysInterval={daysInterval}
                          holidays={holidays}
                        />
                    </div>
                </div>
            </form>
        </div>
    )
}
