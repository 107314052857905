import React from 'react'
import MyInput from '../../UI/input/MyInput'

export default function QuarantineCheck({isQuarantine, setIsQuarantine}) {
  return (
    <div>
       
        <MyInput
            name="checkbox3"
            type="checkbox"
            label="Calculatorul nu va calcula perioada stărilor de urgență"
            checked={isQuarantine}
            onChange={(e) => setIsQuarantine(e.target.checked)}
        />
    </div>
  )
}
