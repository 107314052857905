import React from "react";
import classes from "./MyTextarea.module.css"


export default function MyTextarea ({name, value, onChange, isRequired, placeholder,disabled}){

    return (
            <textarea
                className={`${classes.textarea}`}
                id={name}
                required={isRequired}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                disabled={disabled}
            />
    );
}
