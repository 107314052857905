import React, { useRef } from 'react'
import '../assets/css/Calculator.css'
import BalanceTable from '../components/CalculatorWrappers/BalanceTable';
import ValutaContainer from '../components/CalculatorWrappers/ValutaContainer';
import PenalitateContainer from '../components/CalculatorWrappers/PenalitateContainer';
import DelayContainer from '../components/CalculatorWrappers/DelayContainer';
import TimeCheckboxGroup from '../components/CalculatorWrappers/TimeCheckboxGroup';
import QuarantineCheck from '../components/CalculatorWrappers/QuarantineCheck';
import StartDatePicker from '../components/CalculatorWrappers/StartDatePicker';
import DetailedWrapper from '../components/CalculatorWrappers/DetailedWrapper';
import usePenalitateCalculation from '../hooks/usePenalitateCalculation';
import calculatorService from '../services/CalculatorService';
import { useResults } from '../context/ResultsContext';
import { useCalculatorData } from '../context/CalculatorDataContext';
import { toast } from 'react-toastify';
import { CircleSpinnerOverlay } from 'react-spinner-overlay';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useScreenWidth } from '../hooks/useScreenWidth';
import GeneralButtons from '../components/GeneralButtons';
import { useEffect } from 'react';
import { isValid } from 'date-fns';
import { isMobile } from 'react-device-detect';
import { useCalcsContext } from '../context/CalcsContext';
import MyInput from '../UI/input/MyInput';
import MyButton from '../UI/button/MyButton';
import Overlay from '../components/Overlay';
import { useAuth } from '../context/AuthContext';

const ERROR_CLASS = 'error-input';

export default function PenalitatePage() {    
        const {
          dueAmount, setDueAmount,
          name, setName,
          dueDate, setDueDate,
          paymentAmount, setPaymentAmount,
          paymentDate, setPaymentDate,
          valuta, setValuta,
          penalitateValue, setPenalitateValue,
          penalitateType, setPenalitateType,
          delay, setDelay,
          activeInput, setActiveInput,
          numberValue, setNumberValue,
          isQuarantine, setIsQuarantine,
          isDetailed, setIsDetailed,
          fromNextDay,setFromNextDay,
          startDate, setStartDate,
          entries, setEntries,
          clearData
      } = useCalculatorData();
      const { calculateResults } = usePenalitateCalculation({penalitateValue, penalitateType, startDate, delay, activeInput, numberValue, isQuarantine, isDetailed,fromNextDay, entries});
      const {setResultsData} = useResults();
      const navigate = useNavigate();
      const [loading, setLoading] = useState(false);
      const [showOverlay, setShowOverlay] = useState(false);
      const [calcConfirmationOverlay, setCalcConfirmationOverlay] = useState(false);
      const isMobileWidth = useScreenWidth();
      const penalitateValueRef = useRef(null);
      const penalitateTypeRef = useRef(null);
      const tableRef = useRef(null);
      const {authUser} = useAuth();

      const { refetchCalcsData } = useCalcsContext();
      const handleNavigateToRezultat = (calcId) => {
        const url = `/rezultat?calcId=${calcId}`;
          let newWin = window.open(url, '_blank');
          if(!newWin || newWin.closed || typeof newWin.closed=='undefined') 
          { 
            navigate(url);
          }
      };
      
      const handleOpenOverlay = () => {
        setShowOverlay(!showOverlay);
    };

    useEffect(() => {
      if (tableRef.current && tableRef.current.classList) {
          tableRef.current.classList.remove(ERROR_CLASS);
      }
  }, [dueAmount,dueDate,paymentAmount,paymentAmount,entries]);

  useEffect(() => {
    if (penalitateValueRef.current && penalitateValueRef.current.classList) {
        penalitateValueRef.current.classList.remove(ERROR_CLASS);
    }
}, [penalitateValue]);

  useEffect(() => {
    if (penalitateTypeRef.current && penalitateTypeRef.current.classList) {
        penalitateTypeRef.current.classList.remove(ERROR_CLASS);
    }
}, [penalitateType]);

const validation = () => {
  // Prevent default form submission

  if (!startDate) {
    toast.warning('Selectati data penalitatii.', {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });
    return false;
}

// Check if the selected date is valid
if (!isValid(new Date(startDate))) {
    toast.warning('Data penalitatii nu este valida.', {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });
    return false;
}

// Check if the selected date is greater than any entries' due date
const hasValidEntries = entries.some(entry => {
  const entryDate = new Date(entry.date);
  return entry.due && entryDate <= new Date(startDate); // Use >= for valid entries on or after start date
});

if (!hasValidEntries) {
    toast.warning('Data penalitatii trebuie sa fie mai mare decat datile scadente.', {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });
    return false;
}

if (!entries.some(entry => entry.due)) {
  toast.warning('Introduceti date in tabel.', {
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
  tableRef.current.classList.add(ERROR_CLASS);
  tableRef.current.focus();
    return false;
}

if (penalitateValue === "") {
  toast.warning('Introduceti valoarea penalitatii.', {
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
  penalitateValueRef.current.classList.add(ERROR_CLASS);
  penalitateValueRef.current.focus();
  return false;
}

if (penalitateType === null) {
  toast.warning('Selectati tipul penalitatii.', {
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
  penalitateTypeRef.current.classList.add(ERROR_CLASS);
  penalitateTypeRef.current.focus();
  return false; 
}
return true;
}
    const handleCalculation = async() =>{
        const requestBody = calculateResults();
        try {
          setLoading(true);
          const responseData = await calculatorService.getPenaltiesResults(requestBody,name);
          authUser && refetchCalcsData();
          if(authUser && authUser.subscription == null && authUser.balance -1 >= 0){
            calculatorService.saveCalculation(responseData.calcId);
          }
          setResultsData(responseData.result);
          handleNavigateToRezultat(responseData.calcId);
        } catch (error) {
          
        } finally {
          setCalcConfirmationOverlay(false);
          setLoading(false);
        }
    }

      const handleSubmit = (e) => {
        e.preventDefault();
        if(!validation()){
          return ;
        }

        if(authUser && authUser.subscription == null && authUser.balance -1 >= 0){
        setCalcConfirmationOverlay(true);
      }else{
        handleCalculation();
      }
    }

  return (
    <div className='calculator-page'>
      <form className='form-container calculator-form' onSubmit={handleSubmit}>
        <div className='calculator-container'>

        <div className='calculator-left-side'>
        <div className={!isMobileWidth ? 'proportional-wrapper' : 'mobile-wrapper'}>
        {isMobileWidth
        ?<>
        <ValutaContainer valuta={valuta} setValuta={setValuta}/>
        <PenalitateContainer 
          penalitateValue={penalitateValue} 
          setPenalitateValue={setPenalitateValue}
          penalitateType={penalitateType} 
          setPenalitateType={setPenalitateType} 
          required={true}
          penalitateValueRef = {penalitateValueRef}
          penalitateTypeRef = {penalitateTypeRef}
        />
        </>
      :
      <>
      <PenalitateContainer 
          penalitateValue={penalitateValue} 
          setPenalitateValue={setPenalitateValue}
          penalitateType={penalitateType} 
          setPenalitateType={setPenalitateType} 
          required={true}
          penalitateValueRef = {penalitateValueRef}
          penalitateTypeRef = {penalitateTypeRef}
        />
        <ValutaContainer valuta={valuta} setValuta={setValuta}/>
      </>
        }
        
        </div>
        <div className={!isMobileWidth  ? activeInput !== 'custom' ? 'dual-wrapper' : 'proportional-wrapper' : 'mobile-wrapper'}>
        <TimeCheckboxGroup 
            activeInput={activeInput} 
            setActiveInput={setActiveInput}
            numberValue={numberValue} 
            setNumberValue={setNumberValue}
        />
        <DelayContainer delay={delay} setDelay={setDelay}/>
        </div>
        <div className={isMobileWidth ? 'mobile-wrapper' : 'inline-wrapper'}>
        <div>
        <MyInput
            name="checkbox5"
            type="checkbox"
            checked={fromNextDay}
            onChange={(e) => setFromNextDay(e.target.checked)}
            label="Următoarea zi de la scadență"
        />
    </div>
        <QuarantineCheck isQuarantine={isQuarantine} setIsQuarantine={setIsQuarantine} />
        <DetailedWrapper isDetailed={isDetailed} setIsDetailed={setIsDetailed} />
        
        </div>

        <StartDatePicker startDate={startDate} setStartDate={setStartDate} required={true} label = "Data calcul penalitate"/>
      
        </div>
        <div className='calculator-right-side'>
          <BalanceTable entries={entries}  setEntries={setEntries} 
          dueAmount = {dueAmount} setDueAmount={setDueAmount}
          dueDate = {dueDate} setDueDate={setDueDate}
          paymentAmount = {paymentAmount} setPaymentAmount={setPaymentAmount}
          paymentDate = {paymentDate} setPaymentDate={setPaymentDate}
          tableRef = { tableRef } />
        {!isMobileWidth &&  <GeneralButtons 
        clearData={clearData}
        handleSubmit={handleSubmit}
        />}
        </div>

        </div>
        {isMobileWidth && <GeneralButtons 
        clearData={clearData}
        handleSubmit={handleSubmit}
        />}
      </form>
      <CircleSpinnerOverlay loading={loading} overlayColor="rgba(0, 0, 0, 0.8)" color='#1b1b1bff' />
      <Overlay
      body={<div>
        <p>Apăsând "Continuare", vei consuma un token din contul tău pentru a realiza un calcul. </p>
        <p>Important: Contul tău va fi debitat doar în cazul in care este efectuat un calcul nou.</p>
        <MyButton
          type="button"
          onClick={handleCalculation}
          body="Continuă"
          className="continue-button"
        />
      </div>}
      isOpen={calcConfirmationOverlay}
      handleOverlay={() => setCalcConfirmationOverlay(!calcConfirmationOverlay)}
        />
    </div>
  )
}
