import React from 'react'
import { penalty } from '../../assets/data/option'
import MySelect from '../../UI/select/MySelect'
import NumericInput from '../NumericInput';

export default function PenalitateContainer({penalitateValue, setPenalitateValue,penalitateType, setPenalitateType, penalitateValueRef, penalitateTypeRef}) {
  const selectedOption = penalty.find(option => option.value === penalitateType) || null;
  return (
    <div className='penalitate-container'>
        <label htmlFor="penaltyValue" className={'myInpLabel'}>Mărime penalitate</label>
        <div className='penalty-wrapper'>
        <NumericInput
        name="penaltyValue" 
        isRequired={true} 
        placeholder="Penalitate"
        value={penalitateValue}
        onChange={(e) => setPenalitateValue(e.target.value)}
        frwdRef = { penalitateValueRef }
                />
        <MySelect
        frwdRef = {penalitateTypeRef}
        options={penalty} 
        name="penalty"
        value={selectedOption}
        onChange={setPenalitateType}/>
        </div>

        </div>
  )
}
