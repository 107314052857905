import React from 'react'
import MyInput from '../UI/input/MyInput'
import NumericInput from './NumericInput'
export default function InputWrapper({name,body,value,onChange,type,isRequired,placeholder,className,inpClassName,isNumeric, ...props}) {
  return (
    <div className={'myInpWrapper ' + className}>
        <label htmlFor={name} className={'myInpLabel'}>{body}</label>
        {isNumeric ? 
        <NumericInput 
        name={name}
        type={type} 
        isRequired={isRequired} 
        placeholder={placeholder}
        body={body}
        value={value}
        onChange={onChange}
        className={inpClassName}
        {...props}/>
        :<MyInput 
        name={name}
        type={type} 
        isRequired={isRequired} 
        placeholder={placeholder}
        body={body}
        value={value}
        onChange={onChange}
        className={inpClassName}
        {...props}/>
        }
        </div>
  )
}
