import React from 'react'
import { useEffect } from 'react';
import InputWrapper from './InputWrapper'

export default function PasswordWrapper({password,setPassword,passwordError,setPasswordError,confirmPassword,setConfirmPassword,className}) {


      const handleConfirmPasswordChange = (event) => {
        const value = event.target.value;
        setConfirmPassword(value);
    
        if (value && password && value !== password) {
          setPasswordError('Parolele nu se potrivesc');
        } else {
          setPasswordError('');
        }
      };
  return (
    <div className={className}>
        <InputWrapper 
            name="password" 
            type="password"
            body="Parola"
            isRequired={true}
            value={password} 
            inpClassName={(passwordError && password) ? 'invalid-input' : ''}
            onChange={(event) => setPassword(event.target.value)}
        />

        <InputWrapper 
            name="confirmPassword" 
            type="password"
            body="Confirmă Parola"
            isRequired={true}
            value={confirmPassword}
            inpClassName={(passwordError && confirmPassword) ? 'invalid-input' : ''}
            onChange={handleConfirmPasswordChange}
        />
</div>
  )
}
