import React from 'react'
import Select from 'react-select';

export default function MySelect({options, name, value,frwdRef, onChange,height, ...props}) {
  const customStyles = {
    container: (base,state) =>({
      ...base,
      width:"100%",
      fontSize: '13px'
    }),
    control: (base, state) => ({
      ...base,
      width: '100%',
      height: height ? height : '45px',
      borderRadius: '5px',
      border: state.isFocused ? '1px solid var(--prolex-blue);' : '1.5px solid lightgrey',
      outline: 'none',
      transition: 'all 0.3s cubic-bezier(0.19, 1, 0.22, 1)',
      color: 'black',
      fontSize: '13px',
      backgroundColor: 'white',
      ':hover': {
        border: '1px solid var(--prolex-blue);',
      },
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isFocused || state.isSelected ? 'lightgrey' : 'white',
      color: 'black',
      padding: '8px 12px',
      fontSize: '13px'
    }),
    menu: (base) => ({
      ...base,
      zIndex: 10 ,
      fontSize: '13px'
  }),
  placeholder:(base)=>({
    ...base,
    fontSize: '13px',
  })
  };

  return (
    <Select
      ref = {frwdRef}
      value={value}
      onChange={option => onChange(option ? option.value : null)}
      options={options}
      styles={customStyles}
      {...props}
    />
  );
}
