import React, { useState, useEffect } from 'react';
import { CircleSpinnerOverlay } from 'react-spinner-overlay';
import { toast } from 'react-toastify';
import userService from '../../services/UserService';
import MyButton from '../../UI/button/MyButton';
import MyTextarea from '../../UI/textarea/MyTextarea';

export default function SubUserAdditionForm() {
  const [emails, setEmails] = useState(''); // String for multiple emails
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      // Split emails by commas or line breaks and trim any extra spaces
      const emailList = emails.split(/[\s,]+/).map(email => email.trim()).filter(email => email);
      if (emailList.length === 0) {
        throw new Error('Please enter at least one valid email.');
      }
      // Send the list of emails to the service
      await userService.addSubUser(emailList);
      toast.success("Cererea a fost transmisa cu succes.", {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setEmails(''); // Clear the input after successful submission
    } catch (error) {
      setError(error.message);
      if (error.response && error.response.status === 409) {
        toast.error("Unele subconturi sunt deja legate de acest cont.", {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error("A apărut o eroare la trimiterea cererii, încercați din nou mai târziu.", {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <form className='section-form-container' onSubmit={handleSubmit}>
      <div className='section-top-flex'>
        <div className='section-title-info'>
          <p className='section-description'>Folosește formularul de mai jos pentru a adăuga subconturi. Introdu adresele de email separate prin virgulă sau pe linii separate.</p>
        </div>
        <div className='section-form-button'>
          <MyButton body="Trimite cerere" />
        </div>
      </div>
      <div className="spacer-m-2"></div>
      <div className='section-text'>
        <div className='section-row'>
          <MyTextarea
            name="subUserEmails"
            value={emails}
            onChange={(e) => setEmails(e.target.value)}
            isRequired={true}
            placeholder="Introdu email-urile aici, separate prin virgulă sau pe linii diferite"
          />
        </div>
        <div className="description">Subconturile vor primi o cerere de confirmare pe email.</div>
        <CircleSpinnerOverlay loading={loading} overlayColor="rgba(0, 0, 0, 0.8)" color='#1b1b1bff' />
      </div>
    </form>
  );
}
