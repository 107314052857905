import React from 'react'
import { toast } from 'react-toastify';
import { useAuth } from '../context/AuthContext';
import userService from '../services/UserService';
import MyButton from '../UI/button/MyButton';

export default function SubUserCard() {
    const {authUser} = useAuth();
    const cancelSubUser = async () => {
        try {
            const response = await userService.cancelSubUser();
            toast.success("Acest cont este independent acum.",{
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              setTimeout(() => {
                window.location.href = '/dashboard'; 
              }, 2000);
        } catch (error) {
            toast.error("Acest cont este independent acum.",{
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
        }
    }
  return (
    <div className="subscription-card">
    <div className="subscription-details">
        <div className="sub-first-row">
            {/* <div className="price-container">
                <span className="info-value">00</span>
                <br />
                <span className="info-value currency">EUR</span>
            </div> */}
            <div className="sub-name-author">
               <div>
               <p className="subscription-details-info subUserInfo">Acest cont este un subcont al: <strong>{authUser.parentUserName}</strong></p>
               </div>
               <p className="subscription-details-info owner-name">{authUser.name}</p>
            </div>
        </div>
        <p className="subscription-details-info date-info">
        <MyButton
            type='button'
            body="Elimină legătura"
            onClick = {cancelSubUser}
        />
        <span className="date"></span></p>

    </div>
</div>
  )
}