import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../assets/css/LoginPage.css';
import EmailConfirmationOverlay from '../components/EmailConfirmationOverlay';
import Heading from '../components/Heading';
import LoginForm from '../components/LoginForm';
import Message from '../components/Message';
import useLogin from '../hooks/useLogin';
export default function LoginPage() {
  const [showOverlay, setShowOverlay] = useState(false);
  const [email, setEmail] = useState('');
  const { login, loading,emailConfirmationPrompt,setEmailConfirmationPrompt } = useLogin();

  useEffect(() => {
    if (emailConfirmationPrompt) {
        setShowOverlay(true);
    }
}, [emailConfirmationPrompt]);


  return (
    <div className='auth-block'>
    <div className='main-section acc-section'>
      <div className='content-wrapper'>
            <div className='form-wrapper'>
            <Heading text="Autentificare" />
                    <div className='form-block-box'>
                        <LoginForm login={login} loading={loading} emailConfirmationPrompt={emailConfirmationPrompt} email={email} setEmail={setEmail} />
                        <div className="faint-text switch-forms">Nu ai un cont? <Link to="/register" className='gradient-text-2'>Înregistrează-te</Link></div>
                        <Message type="success" message="Mulțumim! Trimiterea ta a fost primită!" />
                        <Message type="failure" message="Hopa! Ceva a mers prost în timpul trimiterei formularului." />
                    </div>
                    
            </div>
      </div>
    </div>

    <EmailConfirmationOverlay isOpen={showOverlay} onClose={() => {setShowOverlay(false); setEmailConfirmationPrompt('')}} isLoginAttempt={true} email={email} />
    </div>
  )
}
