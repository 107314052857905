import React from 'react'
import { combinedCalcTypes } from '../../assets/data/option';
import MySelect from '../../UI/select/MySelect';

export default function CombinedSelector({combinedCalcType,setCombinedCalcType}) {
    const selectedOption = combinedCalcTypes.find(option => option.value === combinedCalcType) || null;
  return (
    <div className='delay-container'>
    <label htmlFor="delay" className='myInpLabel'>Imputația executării</label>
    <MySelect 
        options={combinedCalcTypes} 
        name="calcType"
        value={selectedOption}
        onChange={setCombinedCalcType}
        />
    </div>
  )
}
