import { createContext } from "react";
import { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import userService from "../services/UserService";
import { useAuth } from "./AuthContext";
const CalcsContext = createContext();

export const useCalcsContext = () => useContext(CalcsContext);

export const CalcsProvider = ({ children }) => {
  const [calcs, setCalcs] = useState(JSON.parse(sessionStorage.getItem('calcsData')) || []);
  const {authUser} = useAuth();
  const fetchCalcsData = async () => {
    try {
      const data = await userService.getUserCalcs();
      setCalcs(data);
      sessionStorage.setItem('calcsData', JSON.stringify(data));
    } catch (error) {
      
    }
  };

  useEffect(() => {
    const hasCalcsFetched = sessionStorage.getItem('calcsData');
    if (!(hasCalcsFetched) && authUser) {
      fetchCalcsData();
    }else{
      setCalcs(JSON.parse(sessionStorage.getItem('calcsData')));
    }
  }, []);

  const refetchCalcsData = () => {
    sessionStorage.removeItem('calcsData');
    fetchCalcsData();
  };

  return (
    <CalcsContext.Provider value={{ calcs, setCalcs, refetchCalcsData }}>
      {children}
    </CalcsContext.Provider>
  );
};