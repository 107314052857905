import React from 'react'
import MyButton from '../../UI/button/MyButton'
import InputWrapper from '../InputWrapper'

export default function TitleInputWrapper({handleSubmit,name,setName,}) {
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };
  return (
    <div>
        <h3>Titlu</h3>
        <div className='inline-wrapper'>
        <InputWrapper
        isRequired={true}
        value={name}
        onChange={(e) => setName(e.target.value)}
        onKeyPress={handleKeyPress}
        type="text"
        name='calcTitle'
        limit={50}
        />
        </div>
        <p>
            Numele in privinta cui a fost efectuat calculul
        </p>
    <MyButton
    body="Confirmă"
    onClick={handleSubmit}
    className="confirm-button"
    />

    </div>

  )
}
