import React from 'react'
import { useScreenWidth } from '../hooks/useScreenWidth';

export default function TransactionCard({transaction}) {
    const transactionDateTime = new Date(transaction.timestamp);
    const transactionDateString = transactionDateTime.toLocaleDateString();
    const transactionTimeString = transactionDateTime.toLocaleTimeString();
    const isMobileWidth = useScreenWidth();

    return (
        <div className="transaction-card">
            <div className="transaction-info">
                <p className="transaction-amount">{isMobileWidth ? <><br/>{transaction.amount} lei</> : `${transaction.amount} lei`}</p>
                <p className="transaction-timestamp">{transactionDateString} {transactionTimeString}</p>
                <p className={transaction.status === "pending" ? "transaction-status transaction-pending" : transaction.status === "failed" ? "transaction-status transaction-failed" : "transaction-status"}>{transaction.status === "successful" ? "succes" : transaction.status === "pending" ? "în așteptare": transaction.status === "failed" ? "eșuat" : null}</p>
            </div>
        </div>
    );
}
