import React from 'react'
import { useState } from 'react';
import { useLocation} from 'react-router-dom';
import { CircleSpinner, CircleSpinnerOverlay } from 'react-spinner-overlay';
import { toast } from 'react-toastify';
import Heading from '../components/Heading';
import PasswordWrapper from '../components/PasswordWrapper';
import useResendVerificationEmail from '../hooks/useResendVerificationEmail';
import useResetPassword from '../hooks/useResetPassowrd';
import MyButton from '../UI/button/MyButton';
export default function ResetPasswordPage() {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const {resetPassword, loading, resend} = useResetPassword();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    const email = searchParams.get('email');
    const { resendVerificationEmail, loading: resendLoading, cooldown,cooldownTimeLeft } = useResendVerificationEmail();

    const handleSubmit = (event) => {
        event.preventDefault();
        if(!password){
            setPasswordError("Introduceti parola!");
            return;
        }
        const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
        if (!passwordRegex.test(password)) {
          toast.error('Parola trebuie să conțină minim 8 caractere, inclusiv o literă majusculă și o cifră.', {
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
            return;
        }
        if (password !== confirmPassword) {
          setPasswordError('Parolele nu se potrivesc');
          return;
        }
        

        resetPassword(token,password);
      };

  return (
    <div className='acc-section'>
        <div className='content-wrapper'>
            <div className='form-wrapper'>
                <Heading text="Reseteaza parola" />
               {resend ?
               <div className='form-block-box link-wrapper tab-form'>
                 <p className='subHead'>Hopa! Link-ul tău de resetare pare a fi invalid sau a expirat. Te rugăm să soliciți unul nou.</p>
                 { cooldown ? 
                        <MyButton disabled body={`Trimite Email in ${cooldownTimeLeft}s`} /> :
                        resendLoading ?  <MyButton onClick={() => resendVerificationEmail(email,'password')}className="button-h45"  body={<div className="spinner-container"><CircleSpinner loading={resendLoading} color='#1b1b1bff' /></div>} />
                        : <MyButton onClick={() => resendVerificationEmail(email,'password')} body="Trimite Email" /> }
                </div>
            :
            <div className='form-block-box tab-form'>
            <p className='subHead'>Introdu parola nouă și salvează</p>
                <form className='form-container ' onSubmit={(e) => handleSubmit(e)}>
                <PasswordWrapper 
                    password={password} 
                    setPassword={setPassword} 
                    passwordError={passwordError} 
                    setPasswordError={setPasswordError} 
                    confirmPassword={confirmPassword}
                    setConfirmPassword={setConfirmPassword}
                    className="link-wrapper" />
                    <MyButton body='Resetează parola'/>
                </form>
            </div>
            }
            </div>
        </div>
        <CircleSpinnerOverlay loading={loading} overlayColor="rgba(0, 0, 0, 0.8)" color='#1b1b1bff' />
    </div>
  )
}
