import React from 'react'
import { FaTimes } from 'react-icons/fa';
import '../assets/css/Overlay.css'
export default function Overlay({isOpen, handleOverlay, body}) {

    if (!isOpen) return null;
  return (
    <div className="overlay">
            <div className="overlay-content">
              {body}
              <button className="close-icon" onClick={handleOverlay}>
                    <FaTimes />
                </button>
            </div>
        </div>
  )
}
