import React from 'react'
import MyButton from '../UI/button/MyButton';

export default function GeneralButtons({clearData,handleSubmit}) {
  return (
    <div className='general-buttons-wrapper'>
    <MyButton body="Resetare date" onClick={(e) => {e.preventDefault(); clearData()}} className="clear-button" type="button" />
  <MyButton body="Calculare" onClick={(e) => handleSubmit(e)} className="calc-button" type="button" />
  </div>
  )
}
