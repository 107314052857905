import React from 'react'
import MyButton from '../../UI/button/MyButton';
import InputWrapper from '../InputWrapper';

export default function LinkCustomPasswordForm({newPassword, handlePasswordSubmit,setNewPassword}) {
  return (
    <div className='section-form selected-tab-form-settings'>
    <form className='section-form-container' onSubmit={(e) => {handlePasswordSubmit(e);}}>
    <div className='section-top-flex'>
      <div className='section-title-info'>
        <h1 className='section-title'>Parola</h1>
        <p className='section-description'>Foloseste forma de mai jos pentru a adauga o parola.</p>
      </div>
      <div className='section-form-button'>
      <MyButton body="Salveaza Parola"/>
      </div>
    </div>
    <div className="spacer-m-2"></div>
    <div className='section-text'>
    <div className='section-row full'>
    <InputWrapper
      name="newPassword" 
      type="password" 
      isRequired={true} 
      body="Parola noua"
      value={newPassword}
      onChange={(e) => setNewPassword(e.target.value)}
    />
    </div>
    <div className="description">Parola trebuie să conțină minim 8 caractere, inclusiv o literă majusculă și o cifră.</div>
    </div>
    </form>
  </div>
  )
}
