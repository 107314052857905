export const valute = [
    {label: "MDL", value: 1},
    {label: "USD", value: 2},
    {label: "EUR", value: 3},
    {label: "RUB", value: 4},
    {label: "UAH", value: 5}
];
export const penalty = [
    {label: "% din suma pe zi", value:false},
    {label: "suma fixă pe zi", value:true},
];
export const interestRates = [
    { label: "0%", value: 0.0 },
    { label: "5%", value: 5 },
    { label: "9%", value: 9 },
    { label: "orice numar", value: "custom" }
];
export const timesPeriod = [
    { label: '180 zile', value: 1 },
    { label: '3 ani', value: 2 },
    { label: "perioada dorita", value: "custom" }
];
export const combinedCalcTypes = [
    { label: 'Penalitate', value: 1 },
    { label: 'Dobanda', value: 2 },
    { label: "Combinat", value: 3 }
];