import React from 'react'

export default function Heading({text}) {
    return (
        <h1 className='form-heading'> 
            {text}
            <span></span>
        </h1>
    );
}
