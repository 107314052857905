import React from 'react'
import { QRCode } from 'react-qrcode-logo'
import logo from '../assets/logo/Logo-Pro-Lex-IT.png'
export default function PrintHeader({currentUrl}) {
  return (
    <div className="visible-print row">
    <div className="logo-content col-xs-10">
        <img src={logo} alt="ProLex Asistență Juridică" width="230"/>
        <div className="row text-right">
           
            {/* <b>ProLex</b> - consultanţă şi asistenţă juridică calificată<br/> */}
            office@prolex.md | 069 767 760<br/>
          <span id="href-result">{currentUrl}</span>
        </div>
        <div className="href-content">
        </div>
    </div>
   <div className="col-xs-2"><div id="qrcode"></div></div>
    <div className="col-xs-2">
    <QRCode
      size={80}
      value={currentUrl}
      qrStyle="dots"
      ecLevel='L'
    />

      </div>
    </div>
  )
}
