import { useEffect } from "react";
import { useState } from "react";
import authService from "../services/AuthService";


const useRequestPasswordReset = () => {
    const [loading, setLoading] =useState(null);
    const [error, setError] = useState(null);
    const [cooldown, setCooldown] = useState(false);
    const [cooldownTimeLeft, setCooldownTimeLeft] = useState(0);

    useEffect(() => {
        if (cooldown && cooldownTimeLeft > 0) {
            const timeout = setTimeout(() => {
                setCooldownTimeLeft(time => time - 1);
            }, 1000);
            return () => clearTimeout(timeout);
        } else if (cooldown && cooldownTimeLeft <= 0) {
            setCooldown(false);
        }
    }, [cooldown, cooldownTimeLeft]);

    const requestPasswordReset = async (email) =>{
        setLoading(true);
        setError(null);
        try{
            await authService.requestPasswordReset({email});
            setCooldown(true);
            setCooldownTimeLeft(60);
        }catch(error){
            setError(error.response.data);
        }finally{
            setLoading(false);
        }
    };
    return {requestPasswordReset, loading,error,cooldown,cooldownTimeLeft};
}
export default useRequestPasswordReset;