import React, { useEffect, useRef, useState} from 'react'
import { Bar} from 'react-chartjs-2';
import {CategoryScale} from 'chart.js'; 
import Chart from 'chart.js/auto';
import { useLayoutEffect } from 'react';
import { useAuth } from '../context/AuthContext';
export default function CalcsStatisticaCharts({ numberOfCalcsToday, numberOfCalcsThisWeek, numberOfCalcsThisMonth, numberOfCalcsAllTime }) {
    const chartRef = useRef(null);
    const {authUser} = useAuth();
    useLayoutEffect(() => {
        const subscriptionCard = document.querySelector('.general-section-card .subscription-card');
        const updateChartHeight = () => {
            if (subscriptionCard instanceof HTMLElement) {
                const subscriptionCardHeight = subscriptionCard.clientHeight;
                if (chartRef.current && subscriptionCardHeight > 150) {
                    chartRef.current.style.height = `${subscriptionCardHeight}px`;
                }
            }
        };
    
        // Initial height update
        updateChartHeight();
    
        window.addEventListener('resize', updateChartHeight);
    
        return () => {
            window.removeEventListener('resize', updateChartHeight);
        };
    }, [authUser]);
    
    Chart.register(CategoryScale);
    const data = {
        labels: ['Astăzi', 'Săptămânal', 'Lunar', 'Total'],
        datasets: [
          {
            label: 'Număr de Calcule',
            data: [numberOfCalcsToday, numberOfCalcsThisWeek, numberOfCalcsThisMonth,numberOfCalcsAllTime],
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)', // Red
                'rgba(54, 162, 235, 0.2)', // Blue
                'rgba(255, 206, 86, 0.2)', // Yellow
                'rgba(75, 192, 192, 0.2)', // Turquoise (Total)
              ],
              borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)', // Turquoise (Total)
              ],
            borderWidth: 1
          }
        ]
      };
      // Options for the chart
      const options = {
        maintainAspectRatio: false,
        responsive: true,
        scales: {
          y:{
            ticks: {
              stepSize:1
            }
          }
        }
      };
    
      return (
        <div className="chart-container" ref={chartRef}>
      <Bar data={data} options={options} />
      </div>
      );
}
