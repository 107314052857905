import React from 'react'
import CalcsStatisticaCharts from './CalcsStatisticaCharts';

export default function CalcsStatistica({calcs,subscriptionCardRef}) {
    const isToday = (date) => {
        const today = new Date();
        return date.getDate() === today.getDate() &&
               date.getMonth() === today.getMonth() &&
               date.getFullYear() === today.getFullYear();
      };
    
      // Function to check if a date is within the current week
      const isThisWeek = (date) => {
        const today = new Date();
        const firstDayOfWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay());
        const lastDayOfWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() + (6 - today.getDay()));
    
        return date >= firstDayOfWeek && date <= lastDayOfWeek;
      };
    
      // Function to check if a date is within the current month
      const isThisMonth = (date) => {
        const today = new Date();
        return date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear();
      };
    
      if(calcs){
        // Filter calculations made today, this week, and this month
      const calcsMadeToday = calcs.filter(calc => isToday(new Date(calc.createdDate)));
      const calcsMadeThisWeek = calcs.filter(calc => isThisWeek(new Date(calc.createdDate)));
      const calcsMadeThisMonth = calcs.filter(calc => isThisMonth(new Date(calc.createdDate)));
    
      // Count the number of calculations made today, this week, and this month
      const numberOfCalcsToday = calcsMadeToday.length;
      const numberOfCalcsThisWeek = calcsMadeThisWeek.length;
      const numberOfCalcsThisMonth = calcsMadeThisMonth.length;
      const numberOfCalcsAllTime = calcs.length;

      return (
        <div className='calcs-container'>
            <CalcsStatisticaCharts
            numberOfCalcsToday={numberOfCalcsToday}
            numberOfCalcsThisWeek={numberOfCalcsThisWeek}
            numberOfCalcsThisMonth={numberOfCalcsThisMonth}
            numberOfCalcsAllTime = {numberOfCalcsAllTime}
            subscriptionCardRef = {subscriptionCardRef}
          />
        </div>
      )
      }
    
  return (
    <CalcsStatisticaCharts
            numberOfCalcsToday={0}
            numberOfCalcsThisWeek={0}
            numberOfCalcsThisMonth={0}
            numberOfCalcsAllTime = {0}
            subscriptionCardRef = {subscriptionCardRef}
          />
  )
}
