import React, { useEffect, useState } from 'react';
import MyTable from '../../UI/table/MyTable';
import BalanceForm from './BalanceForm';
import Papa from 'papaparse';
import Overlay from '../Overlay';
import MyButton from '../../UI/button/MyButton';
import Uploader from '../Uploader';
import { format, isValid, parse } from 'date-fns';
import { useScreenWidth } from '../../hooks/useScreenWidth';

function formatDate(dateString) {
    if (!dateString) {
        return ''; 
    }
    const parsedDate = new Date(dateString);
    if (!isNaN(parsedDate.getDate())) {
        const day = parsedDate.getDate().toString().padStart(2, '0');
        const month = (parsedDate.getMonth() + 1).toString().padStart(2, '0');
        const year = parsedDate.getFullYear();
        return `${day}.${month}.${year}`;
    }

    return '';
}



function BalanceTable({entries, setEntries,
    dueAmount, setDueAmount,
    dueDate, setDueDate,
    paymentAmount, setPaymentAmount,
    paymentDate, setPaymentDate,
    tableRef}) {
    const handleNumericInputChange = (value, setter) => {
        const regex = /^[0-9]*([.,][0-9]*)?$/;
    
        // Check if input is valid
        if (value === '' || value[0] === '0' || (value[0] !== '0' && regex.test(value))) {
            setter(value);
    
        }
    };

    const [editingDueAmount, setEditingDueAmount] = useState('');
    const [editingPaymentAmount, setEditingPaymentAmount] = useState('');
    const [editingDate, setEditingDate] = useState(null);
    const isMobileWidth = useScreenWidth();
    const [editingIndex, setEditingIndex] = useState(null);

    const generateRow = (entry, index) => {

        if (index === editingIndex) {
            return [
                { 
                    type: 'datepicker', 
                    value: editingDate, 
                    onChange: (newDate) => setEditingDate(newDate)
                },
                { 
                    type: 'input', 
                    value: editingDueAmount,
                    disabled: !!editingPaymentAmount, 
                    onChange: (value) => handleNumericInputChange(value, setEditingDueAmount)
                },
                { 
                    type: 'input', 
                    value: editingPaymentAmount,
                    disabled: !!editingDueAmount,
                    onChange: (value) => handleNumericInputChange(value, setEditingPaymentAmount)
                },
                { type: 'actions', actions: [{ label: 'Save', action: (e) => handleSaveEdit(index,e) }, { label: 'Delete', action: (e) => handleDelete(index,e) }] }
            ];
        }
        return [
            { type: 'text', value: formatDate(entry.date) },
            { type: 'text', value: entry.due || '' },
            { type: 'text', value: entry.paid || '' },
            { type: 'actions', actions: [
                { 
                    label: 'Edit', 
                    action: (e) => {
                        e.preventDefault(); 
                        setEditingIndex(index);
                        setEditingDate(entry.date);
                        setEditingDueAmount(entry.due || '');
                        setEditingPaymentAmount(entry.paid || '');
                    } 
                }, 
  { label: 'Delete', action: (e) => handleDelete(index,e) }
]}
        ];
    }

    const handleAddDue = (date, amount) => {
        if (date && amount) {
            setEntries([...entries, {date: date, due: amount, paid: null}]);
            setDueDate(null);
            setDueAmount('');
        }
    }
    
    const handleAddPayment = (date, amount) => {
        if (date && amount) {
            setEntries([...entries, {date: date, due: null, paid: amount}]);
            setPaymentDate(null);
            setPaymentAmount('');
        }
    }

    // Delete an entry based on its index
    const handleDelete = (index,e) => {
        e.preventDefault()
        const newEntries = [...entries];
        newEntries.splice(index, 1);
        setEntries(newEntries);
    }

    // Edit an entry: prepopulate the input fields with its data
    const handleSaveEdit = (index, e) => {
        e.preventDefault();
        const newEntries = [...entries];
        newEntries[index] = {
            date: editingDate, // Save the edited date here
            due: editingDueAmount,
            paid: editingPaymentAmount,
        };
        setEntries(newEntries);
        setEditingIndex(null);
        setEditingDueAmount('');
        setEditingPaymentAmount('');
        setEditingDate(null); // Reset the editing date
      }
      const parseDate = (dateString) => {
        let parsedDate = null;
        let finalDate = null;
        parsedDate = parse(dateString, 'dd.MM.yyyy', new Date());
            if (!isNaN(parsedDate.getDate())){
                const day = parsedDate.getDate().toString().padStart(2, '0');
                const month = (parsedDate.getMonth() + 1).toString().padStart(2, '0');
                const year = parsedDate.getFullYear();
                const formattedYear = (year < 100 ? year + 2000 : year).toString();
                finalDate = new Date(`${month}.${day}.${formattedYear}`);
            }else{
                return finalDate;
            }
          
            if (isValid(parsedDate)) {  
                return finalDate;
            }
    
        return null; 
    };

    const handleFileUpload = (file) => {
        Papa.parse(file, {
          complete: (result) => {
            const newEntries = result.data.map((row) => {
              if (row.length === 3) {
                const date = parseDate(row[0].replace(/[\/ ]/g, '.'));
      
                // Handle empty strings for due and paid
                const due = row[1]
                  ? parseFloat(row[1].trim().replace(/\s/g, '').replace(',', '.')) || null
                  : null;
                const paid = row[2]
                  ? parseFloat(row[2].trim().replace(/\s/g, '').replace(',', '.')) || null
                  : due ? null : null; // Maintain logic for missing paid when due exists
      
                // Only convert negative due to positive paid, set due to null
                const positivePaid = due < 0 ? Math.abs(due) : paid >= 0 ? paid : null;
                const positiveDue = paid < 0 ? Math.abs(paid) : due >= 0 ? due : null; // Set due to null for negative values
      
                return { date, due: positiveDue, paid: positivePaid };
              } else {
                return null; // Handle rows with unexpected number of elements
              }
            }).filter(entry => entry !== null); // Remove null entries
      
            setEntries((prevEntries) => [...prevEntries, ...newEntries]);
          },
          header: false,
        });
      };
    
    
    
    
    
    
    
      const [showOverlay, setShowOverlay] = useState(false);

      const handleOpenOverlay = () => {
        setShowOverlay(!showOverlay);
    };

    const exportToCSV = () => {
        const csvData = entries.map(entry => [
          formatDate(entry.date),
          entry.due || '',
          entry.paid || ''
        ]);
      
        const csvContent = csvData.map(row => row.join(';')).join('\n');
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const currentDate = new Date();

        // Format the date
        const formattedDate = currentDate.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
        }).replace(/\//g, '.'); // Replace slashes with dots

        // Format the time
        const formattedTime = currentDate.toLocaleTimeString('en-GB', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false // Use 24-hour format
        });

        // Concatenate date and time with a space in between
        const formattedDateTime = `${formattedDate} ${formattedTime}`;

        // Replace non-alphanumeric characters with underscores for the filename
        const timestampFilename = formattedDateTime.replace(/[^\w]/g, '_');
        if (navigator.msSaveBlob) {
          // IE 10+
          navigator.msSaveBlob(blob, `Calc-${timestampFilename}.csv`);
        } else {
          // Other browsers
          link.href = URL.createObjectURL(blob);
          link.download = `Calc-${timestampFilename}.csv`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      };
      
      const totalDue = entries.reduce((total, entry) => +total + +(entry.due || 0), 0);
      const totalPaid = entries.reduce((total, entry) => +total + +(entry.paid || 0), 0);
      const totalDiff = totalDue - totalPaid;
  
      const totals = ["Total", totalDue.toFixed(2), totalPaid.toFixed(2), totalDiff.toFixed(2)]; 
    return (
        <div>
            <BalanceForm 
                onAddDue={handleAddDue} 
                onAddPayment={handleAddPayment}
                dueAmount = {dueAmount} setDueAmount={setDueAmount}
                dueDate = {dueDate} setDueDate={setDueDate}
                paymentAmount = {paymentAmount} setPaymentAmount={setPaymentAmount}
                paymentDate = {paymentDate} setPaymentDate={setPaymentDate}
                tableRef = {tableRef}
            />
           <div className='table-container'>
           <div className='two-buttons'>
           <MyButton 
            onClick={handleOpenOverlay} 
            body={isMobileWidth ? "Incarcă" : "Incarcă date in tabel"}
            className="import-button"
            type="button"
            />
            <MyButton 
            onClick={exportToCSV}
            body={isMobileWidth ? "Descarcă" : "Descarcă date din tabel"}
            className="export-button"
            type='button'
            />
           </div>
            <div className='table-responsive'>    
           
            <MyTable 
                headers={['Data', 'Suma scadentă', 'Suma achitată', '']} 
                data={entries.map((entry, index) => generateRow(entry, index))} 
                totals= {totals}
                saveAction = {handleSaveEdit}
            />
            </div>
           </div>
            <Overlay
            isOpen={showOverlay}
            handleOverlay={handleOpenOverlay}
            body=
            {
            <Uploader
            handleOverlay={handleOpenOverlay}
            onFileUpload={handleFileUpload}/>
        }
            />
        </div>
    );
}

export default BalanceTable;
