import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCalcsContext } from '../../context/CalcsContext';
import MyButton from '../../UI/button/MyButton';
import CalcsTable from '../CalcsTable';

export default function Calcule() {
  const { calcs, refetchCalcsData,setCalcs } = useCalcsContext();
  const [savedCalcs, setSavedCalcs]= useState([]);
  const [showSaved, setShowSaved] = useState(true)
  const navigate = useNavigate();

  

  useEffect(() => {
    if(calcs){
      setSavedCalcs([...calcs.filter(calc => calc.saved)]);
    }
  }, [calcs]);
  

return (
  <div className='section-form'>
    <div className='section-form-container'>
      <div className='section-top-flex'>
        <div className='section-title-info'>
          <h1 className='section-title'>Calcule</h1>
          <p className='section-description'>Lista calculelor.</p>
        </div>
      </div>
      <div className='spacer-m-2'></div>
      <div className='calcs-selector'>
          <span className={showSaved ?  'tab-selector-settings selected-tab-settings' : 'tab-selector-settings'} onClick={() => setShowSaved(true)} >Calcule salvate</span>
          <span className={!showSaved ?  'tab-selector-settings selected-tab-settings' : 'tab-selector-settings'} onClick={() => setShowSaved(false)} >Istoric calcule</span>
          </div>
    </div>
    <div className='calcs-container listcalc'>
        {showSaved ? 
        savedCalcs.length > 0 ? 
          <CalcsTable
          calcs={savedCalcs}
          setCalcs = {setSavedCalcs}
          allowEdit ={true}
          refetchCalcsData ={refetchCalcsData}
          calcsPerPage={10}
          />
        :
        <>
          <h4 style={{ textAlign: 'center' }}>
            Nu există calcule salvate.
          </h4>
        </>
         : calcs.length > 0 
         ?
                    <CalcsTable
                    calcs={calcs}
                    setCalcs={setCalcs}
                    allowEdit={false}
                    refetchCalcsData ={refetchCalcsData}
                    calcsPerPage={10}
                    />
         :
          <>
          <h4 style={{ textAlign: 'center' }}>
            Nu există calcule in istoric.
          </h4>
          </>
          }

      </div>

  </div>
);
}
