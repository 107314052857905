import React, {useMemo} from "react";

export default function DaysResults({firstDate, lastDate, firstDayInclusive, lastDayInclusive, daysInterval, holidays}){
    
    const totalDays = useMemo( () => {
        if(
            (firstDate === null) ||
            (lastDate === null) ||
            (lastDate < firstDate)
            ) return 0;
        else{
        const _MS_PER_DAY = 1000 * 60 * 60 * 24;
        const utc1 = Date.UTC(firstDate.getFullYear(), firstDate.getMonth(), firstDate.getDate());
        const utc2 = Date.UTC(lastDate.getFullYear(), lastDate.getMonth(), lastDate.getDate());
        let difference = Math.floor((utc2 - utc1) / _MS_PER_DAY) - 1;

        if(firstDayInclusive === true) difference+=1;
        if(lastDayInclusive === true) difference+=1;

        if (difference === -1) return 0;
        return difference;
        }
    },  [firstDate, lastDate, firstDayInclusive, lastDayInclusive]);
     

    const workingDays = useMemo( () => {
        if(
            (firstDate === null) ||
            (lastDate === null) ||
            (lastDate < firstDate)
            ) return 0;

        
        const startDate = new Date(firstDate);
        const endDate = new Date(lastDate);

        if(!firstDayInclusive) startDate.setDate(startDate.getDate() + 1);
        if(!lastDayInclusive) endDate.setDate(endDate.getDate() - 1);

        var millisecondsPerDay = 86400 * 1000;
        startDate.setHours(0,0,0,1);
        endDate.setHours(23,59,59,999);
        var diff = endDate - startDate;  
        var days = Math.ceil(diff / millisecondsPerDay);

        var weeks = Math.floor(days / 7);
        days = days - (weeks * 2);

        var startDay = startDate.getDay();
        var endDay = endDate.getDay();

        if (startDay - endDay > 1)         
            days = days - 2;      

        if (startDay === 0 && endDay !== 6)
            days = days - 1;

        if (endDay === 6 && startDay !== 0)
            days = days - 1;
        
        //if input string contains intervals, it is divided
        if(holidays.includes('-')){
            let additionalHolidays = holidays.split(/(\d{2}\.\d{2}\.\d{4}\s-\s\d{2}\.\d{2}\.\d{4})/);

            additionalHolidays.forEach(element => {
                //check for each element if it is interval
                if(element.includes('-')){
                    //if it is interval, it is splitted into 2 strings
                    const buff = element.split(/\s*-\s*/); 

                    //here we create dates based on previous strings
                    const startDateBuff = buff[0].split('.');
                    const startDate = new Date(`${startDateBuff[2]}-${startDateBuff[1]}-${startDateBuff[0]}`);
                    const endDateBuff = buff[1].split('.');
                    const endDate = new Date(`${endDateBuff[2]}-${endDateBuff[1]}-${endDateBuff[0]}`);

                    //and calculate length of the interval
                    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
                    const utc1 = Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
                    const utc2 = Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());

                    if((startDate >= firstDate && startDate<=lastDate) && (endDate >= firstDate && endDate <= lastDate)){
                        let difference = Math.floor((utc2 - utc1) / _MS_PER_DAY) - 1;
                        days-=difference;
                    }
                }
                else{
                    const buff = element.split('.');
                    const newAddHol = new Date(`${buff[2]}-${buff[1]}-${buff[0]}`);
                    if(newAddHol >= startDate && newAddHol <=endDate) --days;
                }
        });
        //if input string does not contain intervals, it is separated by spaces
        } else{
            let additionalHolidays = holidays.split(/\s/);
            additionalHolidays.forEach(element => {
            const buff = element.split('.');
            const newAddHol = new Date(`${buff[2]}-${buff[1]}-${buff[0]}`);
            if(newAddHol >= startDate && newAddHol <=endDate) --days;
        });
        }
        
        return days;
    
    },  [firstDate, lastDate, firstDayInclusive, lastDayInclusive, holidays]);

    const minusInterval = new Date(firstDate);

    useMemo( () => {
        minusInterval.setDate(minusInterval.getDate() - daysInterval);
    }, [daysInterval]);

    const plusInterval = new Date(firstDate);

    useMemo( () => {
        plusInterval.setDate(plusInterval.getDate() + +daysInterval);
    }, [daysInterval]);

    

    return( 
        <div>
            <p>Zile totale: <b>{totalDays}</b></p>
            <p>Zile lucrătoare: <b>{workingDays}</b></p>
            <p>Zile nelucrătoare: <b>{totalDays - workingDays}</b></p>
            <br />
            <p>Data interval - <b>{`${minusInterval.getDate().toString().padStart(2, '0')}.${(minusInterval.getMonth()+1).toString().padStart(2, '0')}.${minusInterval.getFullYear()}`}</b></p>
            <p>Data interval + <b>{`${plusInterval.getDate().toString().padStart(2, '0')}.${(plusInterval.getMonth()+1).toString().padStart(2, '0')}.${plusInterval.getFullYear()}`}</b></p>
        </div>
    );
}