import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import authService from '../services/AuthService';

const useResendVerificationEmail = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [cooldown, setCooldown] = useState(false);
    const [cooldownTimeLeft, setCooldownTimeLeft] = useState(0);

    useEffect(() => {
        if (cooldown && cooldownTimeLeft > 0) {
            const timeout = setTimeout(() => {
                setCooldownTimeLeft(time => time - 1);
            }, 1000);
            return () => clearTimeout(timeout);
        } else if (cooldown && cooldownTimeLeft <= 0) {
            setCooldown(false);
        }
    }, [cooldown, cooldownTimeLeft]);

    const resendVerificationEmail = async (email,tokenType) => {
        setLoading(true);
        setError(null);
        try {
            if(tokenType === 'password') {
                await authService.resendPasswordToken({ email });
            }
            if(tokenType === 'email'){
                await authService.resendVerificationEmail({ email });
            }
            toast.success("Email trimis cu succes.",{
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            setCooldown(true);
            setCooldownTimeLeft(60);
        } catch (err) {
            const message = err.response.data && err.response.data.email ? err.response.data.email : err.response.data;
            toast.error(message,{
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
        } finally {
            setLoading(false);
        }
    };

    return { resendVerificationEmail, loading, error, cooldown, cooldownTimeLeft };
};

export default useResendVerificationEmail;