import React, { useEffect, useState } from 'react'
import InputWrapper from './InputWrapper';
import MyButton from '../UI/button/MyButton';
import SocialButtons from './SocialButtons';
import { CircleSpinnerOverlay } from 'react-spinner-overlay';
import ToggleButton from './ToggleButton';
import {useScreenWidth} from '../hooks/useScreenWidth';

export default function RegisterForm({loading,register}) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [telephoneNumber, setTelephoneNumber] = useState('');
    const [personType, setPersonType] = useState(false);
    const [IDNO, setIDNO] = useState('');
    const isMobileWidth = useScreenWidth();
    useEffect(() => {
      const params = new URLSearchParams(window.location.search);
      const emailParam = params.get('email');
      if (emailParam) {
          setEmail(emailParam);
      }
  }, []);
  return (
    <>
    <div className='myInpWrapper full-width'>
    {/* <label className = "myInpLabel" htmlFor='personType'>Tip Persoană</label> */}
  <ToggleButton
            labelLeft='Fizică'
            labelRight='Juridică'
            isChecked={personType}
            id="personType"
            onClickRight={() => {setPersonType(true);}}
            onClickLeft={() => {setPersonType(false);}}
            />
  </div>
    <form className={personType ? 'form-container grid selected-tab-form' : 'form-container grid selected-tab-form'} onSubmit={(e) => {
      e.preventDefault();
      register(firstName, lastName, email, password,companyName,personType,telephoneNumber,IDNO);
  }}>
        {personType ? <>
          <InputWrapper
          name="companyName" 
          type="text" 
          isRequired={true} 
          placeholder="Denumirea companiei" 
          body="Denumirea companiei"
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
          className={isMobileWidth && "full-width"}
          /><InputWrapper
                name="IDNO"
                type="text"
                isRequired={true}
                placeholder="Introduceți IDNO"
                body="IDNO"
                value={IDNO}
                onChange={(e) => setIDNO(e.target.value)}
                regex = {/^(\s*|\d+)$/}
                // controlLimit = {13}
                limit = {13}
                className={isMobileWidth ?  !personType ? "full-width"  : null  : null}
            />
        </>:
        <>
          <InputWrapper
          name="Lastname" 
          type="text" 
          isRequired={true} 
          placeholder="Ursu" 
          body="Nume"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          />
          <InputWrapper
          name="Firstname" 
          type="text" 
          isRequired={true} 
          placeholder="Alexandru" 
          body="Prenume"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          />
        </>}
          
             <InputWrapper
                name="phoneNumber"
                type="text"
                isRequired={true}
                placeholder="Introduceți numărul de telefon"
                body="Număr de telefon"
                value={telephoneNumber}
                onChange={(e) => setTelephoneNumber(e.target.value)}
                isNumeric={true}
                isTelephoneNumber = {true}
                className={isMobileWidth ?  !personType ? "full-width"  : null  : null}
            />
                    <InputWrapper
                      name="Email-2" 
                      type="email" 
                      isRequired={true} 
                      placeholder="exemplu@gmail.com" 
                      body="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className={isMobileWidth && "full-width"}
                    />

             
      <InputWrapper
        name="Password-2" 
        type="password" 
        isRequired={true} 
        placeholder="" 
        body="Parolă"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        className='full-width'
      />
                            <MyButton body="Înregistrare" className='full-width login-button'/>
                            <CircleSpinnerOverlay loading={loading} overlayColor="rgba(0, 0, 0, 0.8)" color='#1b1b1bff' />
                            <div className='container-7 full-width' >
                            <SocialButtons />
                            </div>
                        </form>

    </>
  )
}
