import React, { useState } from 'react'
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useAuth } from '../../context/AuthContext';
import useRequestChangeDetails from '../../hooks/useRequestChangeDetails';
import MyButton from '../../UI/button/MyButton';
import InputWrapper from '../InputWrapper';
import Overlay from '../Overlay';
import VerifyCode from '../VerifyCode';

export default function ChangePasswordForm({currentPassword,setCurrentPassword,newPassword,setNewPassword,handlePasswordSubmit,showOverlay,setShowOverlay,resend}) {
  const { authUser} = useAuth();
  function handleOverlay(){
    setShowOverlay(!showOverlay);
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    if(!currentPassword || !newPassword){
      toast.error('Completati toate campurile va rog.', {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    setShowOverlay(true);
    requestChangeDetails();
};
  const {requestChangeDetails, cooldown,cooldownTimeLeft,error} = useRequestChangeDetails();
  useEffect(()=>{if(error){
    setShowOverlay(false);
  }},[error]);
  return (
    <div className='section-form selected-tab-form-settings'>
      <form className='section-form-container' onSubmit={handleSubmit}>
      <div className='section-top-flex'>
        <div className='section-title-info'>
          <p className='section-description'>Foloseste forma de mai jos pentru a schimba parola.</p>
        </div>
        <div className='section-form-button'>
        <MyButton body="Salvează"/>
        </div>
      </div>
      <div className="spacer-m-2"></div>
      <div className='section-text'>
      <div className='section-row full'>
      <InputWrapper
        name="currentPassword" 
        type="password" 
        isRequired={true} 
        body="Parola curenta"
        value={currentPassword}
        onChange={(e) => setCurrentPassword(e.target.value)}
      />
      <InputWrapper
        name="newPassword" 
        type="password" 
        isRequired={true} 
        body="Parola noua"
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
      />
      </div>
      <div className="description">Parola trebuie să conțină minim 8 caractere, inclusiv o literă majusculă și o cifră.</div>
      </div>
      </form>
      <Overlay
        isOpen={showOverlay}
        handleOverlay={handleOverlay}
        body={
        <VerifyCode
        handleSubmit={handlePasswordSubmit}
        handleRequestSubmit={handleSubmit}
        resend={resend}
        cooldown={cooldown}
        cooldownTimeLeft={cooldownTimeLeft}
        />}
        />
    </div>
  )
}
