import React from 'react';
import { Link } from 'react-router-dom';
import maibLogo from '../assets/icons/maib.png';
import masterCardIcon from '../assets/icons/mastercard.png'
import visaCardIcon from '../assets/icons/visa.png'
import amexCardIcon from '../assets/icons/amex.png'
import '../assets/css/Footer.css';

export default function Footer() {
  return (
    <div className='footer hide-on-print'>
    <div className='footer-flex'>
      <div className='footer-container'>
        <div className='copyright'>
          <Link to="https://prolex.it" className='footer-text' target="_blank" rel="noopener noreferrer">© 2024 Pro-Lex IT</Link>
        </div>
        <div className='footer-text contacts'>
        <a href="tel:+37369767760" className='footer-text'>+37369 767 760</a>
        </div>
        <div className='terms-and-conditions'>
          <Link to="/termeni-conditii" className='footer-text'>Termeni și condiții</Link>
        </div>
        <div className='terms-and-conditions foreign-logo'>
        <img src={maibLogo} alt="" className='maib-logo'/>
          <img src={masterCardIcon} alt="" />
          <img src={visaCardIcon} alt="" />
          <img src={amexCardIcon} alt="" />
        </div>
      </div>
      
    </div>
  </div>
  );
}
