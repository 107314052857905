import React from 'react';
import { interestRates } from '../../assets/data/option';
import MySelect from '../../UI/select/MySelect';
import NumericInput from '../NumericInput';

export default function DobandaContainer({ dobandaValue, setDobandaValue,selectedRate, setSelectedRate, interestRateRef}) {
    const selectedOption = interestRates.find((option) => option.value === selectedRate) || null;

    return (
      <div>
        <label htmlFor="interestRate" className={'myInpLabel'}>
          Rata dobânzii +
        </label>
        <div className="interestRate-wrapper">
          <MySelect
            options={interestRates}
            name="interestRateSelect"
            value={selectedOption}
            onChange={setSelectedRate}
            isSearchable={false}
            isCreatable
          />
          {selectedRate === 'custom' && (
            <NumericInput
              name="interestRate"
              isRequired={true}
              placeholder="Rata dobânzii +"
              value={dobandaValue}
              onChange={(e) => setDobandaValue(e.target.value)}
              frwdRef = {interestRateRef} 
            />
          )}
        </div>
      </div>
    );
  }