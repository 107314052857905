import React from 'react';
import '../assets/css/ToggleButton.css';

const ToggleButton = ({labelLeft, labelRight, isChecked, onClickRight,onClickLeft,id,  ...props }) => {
  return (
    <div className='toggle-button'>
      <div
      className={`left-side ${isChecked === false ? 'checked' : ''}`}
      onClick={onClickLeft}
      id= {id}
      {...props}
      >
        {labelLeft}
      </div>
      <div
      className={`right-side ${isChecked === true? 'checked' : ''}`}
      body={labelRight}
      onClick={onClickRight}
      id = {id + "2"}
      {...props}
      >
        {labelRight}
      </div>
    </div>
  );
};

export default ToggleButton;
