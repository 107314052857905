import React from 'react'
import { useRef } from 'react';
import SubscriptionDetailsInfo from '../SubscriptionDetailsInfo';
import TransactionsHistory from '../TransactionsHistory'

export default function Wallet() {
  return (
    <div className='section-form'>
        <div className='section-top-flex'>
        <div className='section-title-info'>
          <h1 className='section-title'>Portofel</h1>
          <p className='section-description'>Pachet activ, Istoria tranzacțiilor</p>
        </div>
        </div>
        <div className="spacer-m-2"></div>
        <div className='section-row'>
          <div className='wallet-section-card info-card'>
          <SubscriptionDetailsInfo
          />
          </div>
        <div className='wallet-section-card info-card'>
        <TransactionsHistory
        />
        </div>
        </div>

    </div>
  )
}
