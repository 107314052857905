import React from 'react'
import masterCardIcon from '../assets/icons/mastercard.png'
import visaCardIcon from '../assets/icons/visa.png'
import amexCardIcon from '../assets/icons/amex.png'
import { useState } from 'react';
import { useEffect } from 'react';
import subscriptionService from '../services/SubscriptionService';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
export default function PaymentMethodCard({cardNumber}) {
  const [clientIp, setClientIp] = useState('');
  const [cardType, setCardType] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
      const fetchClientIp = async () => {
      try {
          const response = await axios.get('https://api.ipify.org?format=json');
          setClientIp(response.data.ip);
      } catch (error) {
        
      }
      };

      fetchClientIp();
  }, []);
  useEffect(() => {
    function detectCardType() {
      // Extract the first 6 digits from the masked card number
      const firstSixDigits = cardNumber.slice(0, 6);
      // Regular expressions for detecting card types based on the first 6 digits
      const visaRegex = /^4/;
      const mastercardRegex = /^5[1-5]/;
      const amexRegex = /^3[47]/;
  
      // Test the first 6 digits against the regular expressions
      if (visaRegex.test(firstSixDigits)) {
        return 'visa';
      } else if (mastercardRegex.test(firstSixDigits)) {
        return 'mastercard';
      } else if (amexRegex.test(firstSixDigits)) {
        return 'amex';
      } else {
        return 'Unknown';
      }
    }
    
    setCardType(detectCardType());
  }, [cardNumber]);
  


  async function onSubmit() {
        try {
          const succesUrl = location.pathname;;
                const response =  await subscriptionService.changeCard(clientIp,succesUrl);
                  navigate(response);          
        } catch (error) {
            toast.error('Eroare , încercați mai târziu.', {
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
}
    return (
      <div className="payment-method-card">
        <div className='card-number-wrapper'>
        <div className="card-type-icon">
        {cardType === 'visa' && <img src={visaCardIcon} alt="Visa Card Icon" />}
          {cardType === 'mastercard' && <img src={masterCardIcon} alt="Mastercard Icon" />}
          {cardType === 'amex' && <img src={amexCardIcon} alt="Amex Icon" />}
          </div>
        <div className="masked-number">{cardNumber}</div>
        </div>
        <button type="button" className="change-button" onClick={onSubmit}>
        Schimbă
        </button>
      </div>
    );
}