import {  useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import authService from "../services/AuthService";

const useRequestChangeDetails = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [cooldown, setCooldown] = useState(false);
    const [cooldownTimeLeft, setCooldownTimeLeft] = useState(0);

    useEffect(() => {
        if (cooldown && cooldownTimeLeft > 0) {
            const interval = setInterval(() => {
                setCooldownTimeLeft(time => time - 1);
            }, 1000);
            return () => clearInterval(interval);
        } else if (cooldown && cooldownTimeLeft <= 0) {
            setCooldown(false);
        }
    }, [cooldown, cooldownTimeLeft]);

    const requestChangeDetails = async () => {
        setLoading(true);
        setError(null);
        try {
            const data = await authService.requestChangeDetails();
            setCooldown(true);
            setCooldownTimeLeft(60);
            return data;
        } catch (err) {
            toast.error('Eroare in timpul salvarii datelor.', {
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setError(err); // Set error state
        } finally {
            setLoading(false);
        }
    };

    return { requestChangeDetails, loading, error, cooldown, cooldownTimeLeft };
};

export default useRequestChangeDetails;