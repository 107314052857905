import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import '../assets/css/CustomDatePicker.css';
import ro from "date-fns/locale/ro";
registerLocale("ro", ro);
export default function CustomDatePicker({name, placeholder, value, onChange, ...props}) {
    const maxDate = new Date(2100, 11, 31);
    return (
        <DatePicker 
            selected={value} 
            onChange={date => onChange(date)} 
            placeholderText={placeholder}
            name={name}
            id={name}
            dateFormat="dd.MM.yyyy"
            locale="ro"
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            maxDate={maxDate}
            {...props}
        />
    );
}
