import React, { createContext, useContext, useState } from 'react';

const CheckoutOverlayContext = createContext();

export const useCheckoutOverlay = () => useContext(CheckoutOverlayContext);

export const CheckoutOverlayProvider = ({ children }) => {
  const [showCheckoutOverlay, setShowCheckoutOverlay] = useState(false);
  const handleCheckoutOverlay = () => {
    setShowCheckoutOverlay(!showCheckoutOverlay);
};
  return (
    <CheckoutOverlayContext.Provider value={{ showCheckoutOverlay, setShowCheckoutOverlay, handleCheckoutOverlay }}>
      {children}
    </CheckoutOverlayContext.Provider>
  );
};
