import React from 'react';
import 'katex/dist/katex.min.css';
import { BlockMath } from 'react-katex';

const InterestRateFormula = ({rateValue}) => {
    return (
        <div className='formula'>
          <BlockMath>{String.raw`\frac{D \cdot (X + ${(rateValue * 100)}\%)}{365} \cdot Y`}</BlockMath>
        </div>
      );
    };

export default InterestRateFormula;
