import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import useRequestChangeDetails from '../../hooks/useRequestChangeDetails';
import MyButton from '../../UI/button/MyButton';
import InputWrapper from '../InputWrapper';
import Overlay from '../Overlay';
import VerifyCode from '../VerifyCode';

export default function EmailForm({email,setEmail,handleEmailSubmit,showOverlay,setShowOverlay,resend}) {
  const { authUser} = useAuth();
  function handleOverlay(){
    setShowOverlay(!showOverlay);
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    if (email === authUser.email) {
        return;
    }
    setShowOverlay(true);
    requestChangeDetails();
};
  const {requestChangeDetails, cooldown,cooldownTimeLeft,error} = useRequestChangeDetails();
  useEffect(()=>{if(error){
    setShowOverlay(false);
  }},[error]);
  return (
    <div className='section-form selected-tab-form-settings'>
    <form className='section-form-container' onSubmit={handleSubmit}>
    <div className='section-top-flex'>
      <div className='section-title-info'>
       
        <p className='section-description'>Foloseste forma de mai jos pentru a schimba posta ta electronica.</p>
      </div>
      <div className='section-form-button'>
      <MyButton body="Salvează"/>
      </div>
    </div>
    <div className="spacer-m-2"></div>
    <div className='section-text'>
    <div className='section-row'>
    <InputWrapper
      name="accountEmail" 
      type="email" 
      isRequired={true} 
      body="Email"
      value={email}
      onChange={(e) => setEmail(e.target.value)}
    />
    </div>
    <div className="description">Vei avea nevoie de el pentru a te autentifica.</div>
    </div>
    </form>
    <Overlay
        isOpen={showOverlay}
        handleOverlay={handleOverlay}
        body={
        <VerifyCode
        handleSubmit={handleEmailSubmit}
        handleRequestSubmit={handleSubmit}
        resend={resend}
        cooldown={cooldown}
        cooldownTimeLeft={cooldownTimeLeft}
        />}
        />
  </div>
  )
}
