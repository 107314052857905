import React, { useState, useEffect } from 'react';
import '../assets/css/Oauth2Page.css';
import MyButton from '../UI/button/MyButton';
import  useLinkOauth2  from '../hooks/useLinkOauth2';
import { CircleSpinnerOverlay } from 'react-spinner-overlay';
import { useNavigate } from 'react-router-dom';
import PasswordWrapper from '../components/PasswordWrapper';
import Heading from '../components/Heading';
export default function DefaulAccountLinkPage() {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const {linkOauth2, loading} = useLinkOauth2();
  
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const emailParam = searchParams.get('email');
    if(!emailParam){
      navigate('/penalitate');
    }
    setEmail(emailParam);
  }, []);


  const handleSubmit = (event) => {
    event.preventDefault();
    if(!password){
        setPasswordError("Introduceti parola!");
        return;
    }
    if (password !== confirmPassword) {
      setPasswordError('Parolele nu se potrivesc');
      return;
    }
    linkOauth2(email,password);
  };

  return (
    <div className="oauth2-page">
    <CircleSpinnerOverlay loading={loading} overlayColor="rgba(0, 0, 0, 0.8)" color='#1b1b1bff' />
     <div className='acc-section'>
      <div className='content-wrapper'>
          <div className='form-wrapper'>
          <Heading text="Conectare Cont"/>
      <p className='subHead'>Adresa de e-mail cu care încercați să vă conectați este deja înregistrată. Vă rugăm să confirmați parola.</p>
      <div className='form-block-box tab-form'>
      <form onSubmit={(e) => handleSubmit(e)} className='form-container'>
      <PasswordWrapper 
      password={password} 
      setPassword={setPassword} 
      passwordError={passwordError} 
      setPasswordError={setPasswordError} 
      confirmPassword={confirmPassword}
      setConfirmPassword={setConfirmPassword} 
      className="link-wrapper"/>
        {passwordError && <p className="error-message">{passwordError}</p>}

        <MyButton body='Conectare Cont'/>
      </form>
      </div>
          </div>
      </div>
     </div>
    </div>
  );
}
