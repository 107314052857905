import React from 'react'

export default function SidebarButton({className,icon,title,description, ...props}) {
  return (
    <div className={`profile-sidebar-button ${className}`} {...props}>
        <div className='profile-side-link-icon'>
            {icon}
        </div>
        <div>
            {title}
            {description}
        </div>
    </div>
  )
}
