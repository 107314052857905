import React from 'react';
import MyInput from '../UI/input/MyInput';

export default function NumericInput({ frwdRef, value, onChange, allowDecimals = true, isTelephoneNumber, ...props }) {
    const handleInputChange = (e) => {
        let inputValue = e.target.value;
        
        // Verificăm dacă este introdus un număr de telefon și dacă valoarea începe cu "+"
        if (isTelephoneNumber && !inputValue.includes('+', 1)) {
            // Nu permitem introducerea altor caractere decât cifrele și "+"
            inputValue = inputValue.replace(/[^\d+]/g, '');
        }else {
            // Validăm numai cifrele și punctele zecimale (dacă este permis)
            const regex = allowDecimals ? /^[0-9]*([.,][0-9]*)?$/ : /^[0-9]*$/;
            inputValue = inputValue.replace(',', '.');

            if (!regex.test(inputValue)) {
                return; // Nu actualizăm starea dacă valoarea nu este validă
            }
        }

        onChange({ ...e, target: { ...e.target, value: inputValue } });
    }

    return (
        <MyInput 
            frwdRef={frwdRef}
            type="text" 
            value={value} 
            onChange={handleInputChange} 
            inputMode="decimal"
            {...props} 
        />
    );
}
