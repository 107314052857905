import React from 'react'
import MyButton from '../UI/button/MyButton';
export default function SocialButton({ Icon, onClick}) {
    return (
        <MyButton 
        className='button-social-small'
        body={ <div className='icon-2'>
        <Icon />
    </div>}
    onClick={onClick}
/>
    );
}
