import React from 'react'

export default function Message({type, message}) {
    const className = type === "success" ? "w-form-done" : "w-form-fail";
    return (
        <div className={className} tabIndex="-1" role="region" aria-label={`${type} message`}>
            <div>{message}</div>
        </div>
    );
}
