import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAuth } from '../context/AuthContext';
import authService from '../services/AuthService';

function useRegister() {
    const [loading, setLoading] = useState(false);
    const [emailConfirmationPrompt, setEmailConfirmationPrompt] = useState('');  // <-- for the prompt message
    const navigate = useNavigate();
    const { setAuthUser, setIsLoggedIn } = useAuth(); 
    const query = new URLSearchParams(useLocation().search);
    const tokenValue = query.get('token');
    const register = async (firstName, lastName, email, password,companyName,personType,telephoneNumber,IDNO) => {
       if(!validation(password,personType,telephoneNumber,IDNO)){
            return;
       }
        setLoading(true);
        try {
            const responseMessage = await authService.register(personType ? companyName : firstName + ' ' + lastName, email, password,personType,telephoneNumber,personType ? IDNO : null,tokenValue);
            setAuthUser(null)
            setIsLoggedIn(false);
            setEmailConfirmationPrompt(responseMessage);  // <-- set the prompt
            //navigate("/login");
            //sugus
        } catch (error) {
            handleErrors(error);
        } finally {
            setLoading(false);
        }
    };
    function validation(password,personType,telephoneNumber,IDNO){
        const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\w\S]{8,}$/;
        const phoneNumberPattern = /^(?:\+373|0)?[0-9]{8}$/;
        const IDNOPattern = /[0-9]{6,13}/;
        if (!passwordPattern.test(password)){
            toast.error('Parola trebuie să conțină minim 8 caractere, inclusiv o literă majusculă și o cifră.', {
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              return false;
        }
        if (!phoneNumberPattern.test(telephoneNumber)){
            toast.error('Numărul de telefon trebuie să fie în formatul +373xxxxxxxx, 0xxxxxxxx sau xxxxxxxx.', {
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              return false;
        }
        if(personType){
            if (!IDNOPattern.test(IDNO)){
                toast.error('IDNO trebuie să conțină exact 13 cifre.', {
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
                  return false;
            }
        }
        return true;
    }

    const handleErrors = (error) => {
        if (error.response) {
            if (error.response.status === 400) {
                if(error.response.data.password){
                    Object.keys(error.response.data).forEach((field) => {
                        toast.error(error.response.data[field], {
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    });
                }else{
                    toast.error(error.response.data, {
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                      });
                }
                
            } else if (error.response.status === 401) {
                toast.error('Inregistrarea a esuat', {
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
            } else {
                toast.error(error.response.data, {
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
            }
        } else {
            toast.error('Ceva nu a mers bine. Vă rugăm să încercați din nou mai târziu.', {
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
        }
    };

    return { register, loading,emailConfirmationPrompt,setEmailConfirmationPrompt };  // <-- return the prompt
}

export default useRegister;
