import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import LoginPage from '../pages/LoginPage'
import SignupPage from '../pages/SignupPage'
import ProtectedRoute from './ProtectedRoute'
import PublicRoute from './PublicRoute'
import { calculatorRoutes } from './index'
import DashboardPage from '../pages/DashboardPage'
import SubscribeSuccesPage from '../pages/SubscribeSuccesPage'
import UnavailablePage from '../pages/UnavailablePage'
import SubUserConfirmationPage from '../pages/SubUserConfirmationPage'
const isTemporarilyUnavailable = false;
export default function AppRouter() {
  return (
<Routes>
      {isTemporarilyUnavailable ? ( // Check the flag for temporary unavailability
        <Route path="*" element={<UnavailablePage />} /> // Redirect all routes to UnavailablePage
      ) : (
        <>
          <Route
            path="/login"
            element={
              <PublicRoute>
                <LoginPage />
              </PublicRoute>
            }
          />
          <Route
            path="/register"
            element={
              <PublicRoute>
                <SignupPage />
              </PublicRoute>
            }
          />
          <Route
            path="/dashboard"   

            element={
              <ProtectedRoute>
                <DashboardPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/subscribe-succes"
            element={
              <ProtectedRoute>
                <SubscribeSuccesPage />
              </ProtectedRoute>
            }
          />
          <Route
            path = "/subaccount-confirmation"
            element={
              <ProtectedRoute>
                <SubUserConfirmationPage/>
              </ProtectedRoute>
            }
            />
{calculatorRoutes.map((route) => (
  <Route path={route.path} element={<route.component />} key={route.path} />
))}
          <Route path="*" element={<Navigate to="/penalitate" replace />} />
        </>
      )}
    </Routes>
  )
}
