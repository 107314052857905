import React from 'react'
import MyButton from '../../UI/button/MyButton';
import CustomDatePicker from '../CustomDatePicker'
import NumericInput from '../NumericInput';
export default function BalanceForm({ onAddDue, onAddPayment,
    dueAmount, setDueAmount,
    dueDate, setDueDate,
    paymentAmount, setPaymentAmount,
    paymentDate, setPaymentDate,
    tableRef}) {

        const handleKeyPressAddDue = (e) => {
            if (e.key === 'Enter') {
                onAddDue(dueDate, dueAmount);
            }
          };
          const handleKeyPressAddPayment = (e) => {
            if (e.key === 'Enter') {
                onAddPayment(paymentDate, paymentAmount);
            }
          };
        

    return (
        <div id="addBalanceButtons">
            <label className='myInpLabel'>Tabel</label>
           <div 
           ref ={tableRef}
           className = "table-inputs">
           <div className="input-group">
                <CustomDatePicker 
                    value={dueDate} 
                    onChange={setDueDate} 
                    placeholder="Data scadentă" 
                    name="datepicker2"
                    onKeyPress={handleKeyPressAddDue}
                />
                 <NumericInput
                    name="suma-scadenta"
                    value={dueAmount}
                    onChange={e => setDueAmount(e.target.value)}
                    placeholder="Suma"
                    onKeyPress={handleKeyPressAddDue}
                 />
            <MyButton body="Adaugă" onClick={() => {
                onAddDue(dueDate, dueAmount);
                }} 
                className="add-button" 
                type="button"
            />

            </div>

            <div className="input-group">
                <CustomDatePicker 
                    value={paymentDate} 
                    onChange={setPaymentDate} 
                    placeholder="Data achitare" 
                    name="datepicker3" 
                    onKeyPress={handleKeyPressAddPayment}
                />
                <NumericInput
                    name="suma-achitata"
                    value={paymentAmount}
                    onChange={e => setPaymentAmount(e.target.value)}
                    placeholder="Suma"
                    onKeyPress={handleKeyPressAddPayment}
                />
            <MyButton 
                body="Adaugă" 
                onClick={() => {
                onAddPayment(paymentDate, paymentAmount);
                }}
                className="add-button" 
                type="button"
            />
        </div>
           </div>
        </div>
    );
}
