import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAuth } from '../context/AuthContext';
import { useCalcsContext } from '../context/CalcsContext';
import authService from '../services/AuthService';

function useLogin() {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [emailConfirmationPrompt, setEmailConfirmationPrompt] = useState('');  
    const { setAuthUser, setIsLoggedIn} = useAuth(); 
    const { refetchCalcsData } = useCalcsContext();
    const prevPath = localStorage.getItem('prevPath');
    const login = async (email, password) => {

        setLoading(true);
        try {
            const data = await authService.login(email, password);
            setAuthUser(data)
            setIsLoggedIn(true);
            refetchCalcsData();
            if(data.isNewlyCreated && data.linked){
                navigate('/success');
            }else{
                if (prevPath) {
                    navigate(prevPath);
                    window.location.reload();
                    localStorage.removeItem('prevPath');
                } else {
                    navigate('/dashboard');
                }
            }
            
            return data;
        } catch (error) {
            handleErrors(error);
        } finally {
            setLoading(false);
        }
    };

    const handleErrors = (error) => {
        if (error.response) {
            if (error.response.status === 400) {
                toast.error(error.response.data, {
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
            } else if (error.response.status === 401) {
                
                if(error.response.data === "E-mailul nu este verificat. Vă rugăm să verificați e-mailul înainte de a vă autentifica.") {
                    setEmailConfirmationPrompt(error.response.data);
                }else{
                    toast.error(error.response.data, {
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                      });
                }
            } else if(error.response.status === 404) {
                toast.error(error.response.data, {
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
            }
        } else {
            toast.error('Ceva nu a mers bine. Vă rugăm să încercați din nou mai târziu.', {
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
        }
    };

    return { login, loading, emailConfirmationPrompt,setEmailConfirmationPrompt};
}

export default useLogin;