import React, { useEffect } from 'react'
import { useState } from 'react';
import CheckoutForm from './CheckoutForm'
import '../assets/css/WalletForm.css'
import { useScreenWidth } from '../hooks/useScreenWidth';
import { useAuth } from '../context/AuthContext';
import SubscriptionDetails from './SubscriptionDetails';
import InvoiceForm from './InvoiceForm';
import TokenForm from './TokenForm';
import MyButton from '../UI/button/MyButton';
import abonament from '../assets/icons/abonament100.png';
import token from '../assets/icons/token100.png';
import PlanSelectForm from './PlanSelectForm';
import PaymentMethodForm from './PaymentMethodForm';
export default function WalletForm() {
    const [updatePlan, setUpdatePlan] = useState(false);
    const [planId, setPlanId] = useState(null);
    const [isTokenBuy, setIsTokenBuy] = useState(false);
    const [secondStep, setSecondStep] = useState(false);
    const [thirdStep, setThirdStep] = useState(false);
    const [maxActiveSessions, setMaxActiveSessions] = useState(3);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const { authUser } = useAuth();
    const [subscriptionPaymentMethod,setSubscriptionPaymentMethod] = useState(authUser.subscription ? authUser.subscription.paymentMethod : "transfer");
    useEffect(()=>{
        setSubscriptionPaymentMethod(authUser.subscription ? authUser.subscription.paymentMethod : "transfer");
        setSecondStep(authUser.subscription ? true : false);
    },[authUser])
    const isMobileWidth = useScreenWidth();
    const goToCheckoutForm = () => {
        setUpdatePlan(true);
    };
    const goToSubscriptionDetails = () => {
        setUpdatePlan(false);
    };
    const goToFirstStep = ()=>{
        setIsTokenBuy(false);
        setSecondStep(false);
    }

  return (
    <div>
        {!secondStep && <div className='bundles-wrapper'>
        <MyButton 
        body = {<><img src={token} loading="lazy" alt="" className="space"/><h3>Cumpăra calcule</h3><span>ALEGE</span></>}
        type="button"
        className={planId === "sem80" ? "bundle-card selected" : "bundle-card sem"}
        onClick ={() => {setIsTokenBuy(true);setSecondStep(true);}}
        />
        <MyButton 
        body = {<><img src={abonament} loading="lazy" alt="" className="space"/><h3>Cumpăra abonament</h3><span>ALEGE</span></>}
        type="button"
        className={planId === "anu150" ? "bundle-card selected" : "bundle-card an"}
        onClick ={() => {setSecondStep(true); setIsTokenBuy(false);} }
        />
    </div>}
  { !isTokenBuy ? secondStep && !thirdStep ?
  <div>
        {/* {authUser.subscription ? updatePlan && authUser.subscription.paymentMethod === "maib" ? <div className='select-pay-method'>
                    <span className={subscriptionPaymentMethod === 'maib' ?  'tab-selector-settings selected-tab-settings' : 'tab-selector-settings'} onClick={() => {setSubscriptionPaymentMethod('maib');}} >Card</span>
                    <span className={subscriptionPaymentMethod === 'transfer' ?  'tab-selector-settings selected-tab-settings' : 'tab-selector-settings'} onClick={() => {setSubscriptionPaymentMethod('transfer');}} >Transfer</span>
            </div> : null :  <div className='select-pay-method'>
                    <span className={subscriptionPaymentMethod === 'maib' ?  'tab-selector-settings selected-tab-settings' : 'tab-selector-settings'} onClick={() => {setSubscriptionPaymentMethod('maib');}} >Card</span>
                    <span className={subscriptionPaymentMethod === 'transfer' ?  'tab-selector-settings selected-tab-settings' : 'tab-selector-settings'} onClick={() => {setSubscriptionPaymentMethod('transfer');}} >Transfer</span>
            </div>
           } */}
            {isMobileWidth ? (authUser.subscription && authUser.subscription.status)  !== null  && !updatePlan ? 
                                    <SubscriptionDetails 
                                    goToCheckoutForm={goToCheckoutForm}
                                    planId = {planId}
                                    setPlanId = {setPlanId} /> :
                                    <PlanSelectForm
                                    goToSubscriptionDetails = {goToSubscriptionDetails}
                                        planId = {planId}   
                                        setPlanId = {setPlanId}
                                        updatePlan = {updatePlan}
                                        currentPlanId = {authUser.subscription ? authUser.subscription.plan.id : null}
                                        subscriptionStatus = {authUser.subscription ? authUser.subscription.status : null}
                                        goBack = {goToFirstStep}
                                        setThirdStep = {setThirdStep}
                                        maxActiveSessions = {maxActiveSessions}
                                        setMaxActiveSessions = {setMaxActiveSessions}
                                        termsAccepted = {termsAccepted}
                                        setTermsAccepted = {setTermsAccepted}
                                    />

                                    // subscriptionPaymentMethod === "maib"  ? 
                                    // <CheckoutForm
                                    //     goToSubscriptionDetails = {goToSubscriptionDetails}
                                    //     planId = {planId}   
                                    //     setPlanId = {setPlanId}
                                    //     updatePlan = {updatePlan}
                                    //     currentPlanId = {authUser.subscription ? authUser.subscription.plan.id : null}
                                    //     subscriptionStatus = {authUser.subscription ? authUser.subscription.status : null}
                                    //     goBack = {goToFirstStep}
                                    // /> :
                                    // subscriptionPaymentMethod === "transfer" && 
                                    // <InvoiceForm
                                    //     goToSubscriptionDetails = {goToSubscriptionDetails}
                                    //     updatePlan = {updatePlan}
                                    //     planId = {planId}
                                    //     setPlanId = {setPlanId}
                                    //     goBack = {goToFirstStep}
                                    // />
                                    : (
                authUser.subscription && !updatePlan
                ?   <SubscriptionDetails 
                        goToCheckoutForm={goToCheckoutForm} 
                        planId = {planId}
                        setPlanId = {setPlanId}
                    />
                : 
                <PlanSelectForm
                    goToSubscriptionDetails = {goToSubscriptionDetails}
                    planId = {planId}   
                    setPlanId = {setPlanId}
                    updatePlan = {updatePlan}
                    currentPlanId = {authUser.subscription ? authUser.subscription.plan.id : null}
                    subscriptionStatus = {authUser.subscription ? authUser.subscription.status : null}
                    goBack = {goToFirstStep}
                    setThirdStep = {setThirdStep}
                    maxActiveSessions = {maxActiveSessions}
                    setMaxActiveSessions = {setMaxActiveSessions}
                    termsAccepted = {termsAccepted}
                    setTermsAccepted = {setTermsAccepted}
                />
                
                   
                // subscriptionPaymentMethod === "maib"  ? 
                // <CheckoutForm
                //     goToSubscriptionDetails = {goToSubscriptionDetails}
                //     planId = {planId}
                //     setPlanId = {setPlanId}
                //     updatePlan = {updatePlan}
                //     currentPlanId = {authUser.subscription ? authUser.subscription.plan.id : null}
                //     subscriptionStatus = {authUser.subscription ? authUser.subscription.status : null}
                //     goBack = {goToFirstStep}
                // />
                // : subscriptionPaymentMethod === "transfer" && 
                // <InvoiceForm
                //     goToSubscriptionDetails = {goToSubscriptionDetails}
                //     updatePlan = {updatePlan}
                //     planId = {planId}
                //     setPlanId = {setPlanId}
                //     goBack = {goToFirstStep}
                // />
                

                )}
        </div>
        : 
        secondStep && thirdStep &&
        <>
        <PaymentMethodForm
        goBack = {()=>setThirdStep(false)}
        updatePlan = {updatePlan}
        maxActiveSessions = {maxActiveSessions}
        planId = {planId}
        termsAccepted = {termsAccepted}
        setTermsAccepted = {setTermsAccepted}
        />
        </>
        :
        secondStep && 
        <><div className='select-pay-method'>
        <span className='tab-selector-settings selected-tab-settings no-pointer'>Card</span>
</div>
        <TokenForm
        goBack = {goToFirstStep}
        /></>}
        </div>
  )
}
