import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuth } from "../context/AuthContext";
import authService from "../services/AuthService";

function useLinkOauth2(){
    const [loading, setLoading] = useState(false);
    const {setAuthUser, setIsLoggedIn} = useAuth();
    const navigate = useNavigate();
    const prevPath = localStorage.getItem('prevPath');
    const linkOauth2 = async (email, password) => {
        setLoading(true);
        try{
            const data = await authService.linkOauth2(email,password);
            setAuthUser(data)
            setIsLoggedIn(true);
            if (prevPath) {
                navigate(prevPath);
                window.location.reload();
                localStorage.removeItem('prevPath');
            } else {
                navigate('/dashboard');
            }
            toast.success("Contul a fost conectat cu succes", {
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            return data;
        }catch(error){
            handleErrors(error);
        }finally{
            setLoading(false);
        }
    };

    const handleErrors = (error) => {
        if (error.response) {
            if (error.response.status === 400) {
                toast.error(error.response.data, {
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
            } else if (error.response.status === 401) {
                toast.error(error.response.data, {
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
            } else {
                toast.error("Ceva nu a mers, incercati mai tarziu", {
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
            }
        } else {
            toast.error("Ceva nu a mers, incercati mai tarziu", {
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
        }
    };

    return {linkOauth2, loading};
}
export default useLinkOauth2;