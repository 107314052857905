import React, { createContext, useContext, useState, useEffect } from 'react';

// Create a context
const CalculatorDataContext = createContext();

// Create a provider component
export const CalculatorDataProvider = ({ children }) => {
  const savedData = JSON.parse(sessionStorage.getItem('calcData')) || {};

  const convertedEntries = savedData.entries ? 
  savedData.entries.map(entry => ({
      ...entry,
      date: new Date(entry.date)
  })) : [];

    const [valuta, setValuta] = useState(savedData.valuta || 1);
    const [name, setName] = useState(savedData.name || "");
    const [penalitateValue, setPenalitateValue] = useState(savedData.penalitateValue || "");
    const [dobandaValue, setDobandaValue] = useState(savedData.dobandaValue !== null ? savedData.dobandaValue : '');
    const [selectedRate, setSelectedRate] = useState(savedData.selectedRate || 9);
    const [penalitateType, setPenalitateType] = useState(savedData.penalitateType ?? false);
    const [delay, setDelay] = useState(savedData.delay || "");
    const [activeInput, setActiveInput] = useState(savedData.activeInput || null);
    const [numberValue, setNumberValue] = useState(savedData.numberValue || '');
    const [isQuarantine, setIsQuarantine] = useState(savedData.isQuarantine || false);
    const [isDetailed, setIsDetailed] = useState(savedData.isDetailed || false);
    const [startDate, setStartDate] = useState(savedData.startDate ? new Date(savedData.startDate) : new Date());
    const [entries, setEntries] = useState(convertedEntries || []);
    const [dueDate, setDueDate] = useState(savedData.dueDate ? new Date(savedData.dueDate) : null);
    const [dueAmount, setDueAmount] = useState(savedData.dueAmount || '');
    const [paymentDate, setPaymentDate] = useState(savedData.paymentDate ? new Date(savedData.paymentDate) : null);
    const [fromNextDay, setFromNextDay] = useState(savedData.fromNextDay || false);
    const [paymentAmount, setPaymentAmount] = useState(savedData.paymentAmount || '');
    const [combinedCalcType,setCombinedCalcType] = useState(savedData.combinedCalcType || 3);

    useEffect(() => {
      setName("");
    }, [penalitateValue,penalitateType,dobandaValue,selectedRate,delay,isQuarantine,isDetailed,entries,startDate,activeInput,numberValue,fromNextDay,combinedCalcType]);

    useEffect(() => {
      const dataToSave = {
          name,
          dueDate,
          dueAmount,
          paymentAmount,
          fromNextDay,
          paymentDate,
          valuta, 
          penalitateValue, 
          dobandaValue,
          selectedRate,
          penalitateType, 
          delay, 
          activeInput, 
          numberValue, 
          isQuarantine,
          isDetailed,
          combinedCalcType, 
          startDate: startDate && startDate.toString(),
          entries
      };
      sessionStorage.setItem('calcData', JSON.stringify(dataToSave));
    }, [name,selectedRate,valuta, penalitateValue, penalitateType, delay, activeInput, numberValue, isQuarantine, startDate, entries, dueAmount, dueDate, paymentAmount, paymentDate,isDetailed,dobandaValue,fromNextDay,combinedCalcType]);

    const clearData = () => {
      sessionStorage.removeItem('calcData');
      setName("");
      setDueDate(null);
      setDobandaValue('');
      setSelectedRate(9);
      setDueAmount("");
      setPaymentAmount("");
      setPaymentDate(null);
      setValuta(1);
      setIsDetailed(false);
      setPenalitateValue("");
      setPenalitateType(false);
      setDelay("");
      setActiveInput(null);
      setNumberValue('');
      setIsQuarantine(false);
      setStartDate(new Date());
      setEntries([]);
      setFromNextDay(false);
      setCombinedCalcType(3);
  };

  // Create the context value
  const contextValue = {
    name,
    setName,
    dueAmount,
    setDueAmount,
    dueDate,
    setDueDate,
    paymentAmount,
    setPaymentAmount,
    paymentDate,
    setPaymentDate,
    valuta,
    setValuta,
    dobandaValue,
    setDobandaValue,
    selectedRate, 
    setSelectedRate,
    penalitateValue,
    setPenalitateValue,
    penalitateType,
    setPenalitateType,
    delay,
    setDelay,
    activeInput,
    setActiveInput,
    numberValue,
    setNumberValue,
    isQuarantine,
    setIsQuarantine,
    isDetailed,
    setIsDetailed,
    fromNextDay,
    setFromNextDay,
    combinedCalcType,
    setCombinedCalcType,
    startDate,
    setStartDate,
    entries,
    setEntries,
    clearData,
  };

  // Provide the context value to the components
  return <CalculatorDataContext.Provider value={contextValue}>{children}</CalculatorDataContext.Provider>;
};


// Create a custom hook to use the context
export const useCalculatorData = () => {
  const context = useContext(CalculatorDataContext);
  if (!context) {
    throw new Error('useCalculatorData must be used within a CalculatorDataProvider');
  }
  return context;
};
