import React from 'react'
import { useEffect, useState } from 'react';
import "../assets/css/checkoutForm.css"
import instance from "../interceptors/AxiosInterceptor";
import MyButton from '../UI/button/MyButton';
import { CircleSpinner, CircleSpinnerOverlay } from 'react-spinner-overlay';
import { toast } from 'react-toastify';
import subscriptionService from '../services/SubscriptionService';
import { useAuth } from '../context/AuthContext';
import MyInput from '../UI/input/MyInput';
import userService from '../services/UserService';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
export default function CheckoutForm({ goToSubscriptionDetails, planId, setPlanId, currentPlanId, updatePlan,subscriptionStatus, goBack }) {
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
   
    const [maxActiveSessions, setMaxActiveSessions] = useState(3);
    const monthlyPrice = 300;
    const semestrialPrice =1350;
    const annualPrice = 2520;
    const [finalMonthlyPrice,setFinalMonthlyPrice] = useState(300);
    const [finalSemestrialPrice,setFinalSemestrialPrice] = useState(1350);
    const [finalAnnualPrice,setFinalAnnualPrice] = useState(2520);

    const location = useLocation();
    const {authUser} = useAuth();
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [clientIp, setClientIp] = useState('');
    useEffect(() => {
        const fetchClientIp = async () => {
        try {
            const response = await axios.get('https://api.ipify.org?format=json');
            setClientIp(response.data.ip);
        } catch (error) {
         
        }
        };

        fetchClientIp();
    }, []);
    useEffect(()=>{
        if (authUser){
        setTermsAccepted(authUser.termsAccepted);
        }
    },[authUser])
    

    const handleError = (newError) => {
        setError(newError.message || String(newError));
    };
    function validation(){
        if (!planId) {
            toast.error('Selectați un plan înainte de plată.', {
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return false;
        }
        if(planId === currentPlanId && subscriptionStatus === "active"){
            toast.error('Abonamentul selectat deja este activ.', {
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return false;
        }
        if(!termsAccepted){
            toast.error('Acceptați Termenii și condițiile.', {
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return;
        }
        return true;
    }
    useEffect(()=>{
        let i = 1;
        let sum = 0;
        let sum6 = 0;
        let sum12 = 0;
        while(i <= maxActiveSessions){
            if(i < 6 &&  i > 3){
                sum+=50;
                sum6+=50*3;
                sum12+=50*6;
            }else if(i < 11 &&  i > 5){
                sum+=45;
                sum6+=45*3;
                sum12+=45*6;
            }else if(i > 10){
                sum+=40;
                sum6+=40*3;
                sum12+=40*6;
            }
            i++;
        }
        setFinalMonthlyPrice(monthlyPrice + sum);
        setFinalSemestrialPrice(semestrialPrice + sum6);
        setFinalAnnualPrice(annualPrice + sum12);
    },[maxActiveSessions])
    async function onSubmit() {
        if(!validation()){
            return;
        }
            try {
                setLoading(true);
                    const response = !updatePlan ? await instance.post('/api/subscription/subscribe', {
                        clientIp: clientIp,
                        planId: planId,
                        maxActiveSessions
                    }) : await subscriptionService.updateSubscription(planId,maxActiveSessions);
                    if(!updatePlan){
                        localStorage.setItem('prevPath', location.pathname + location.search);
                        window.location.href = response.data;
                    }else{
                        toast.success('Abonament schimbat cu succes!', {
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                          });
                    }
                    if(!termsAccepted){await userService.acceptTerms();}
                    setTermsAccepted(true);
                  setTimeout(() => {
                    window.location.reload();
                }, 1000);
            } catch (error) {
                toast.error('Eroare la procesarea tranzacției, încercați mai târziu.', {
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }finally{
                setLoading(false);
            }
    }
    // return (
    //     <div>
    //         <div className='checkout-form-container'>
    //             Ne pare rău, momentan întâmpinăm probleme tehnice cu plata cu cardul din partea băncii. Vă rugăm să utilizați transferul bancar pentru a vă abona.
    //         </div>
    //     </div>
    // );

    return (
        <div>
       <div className='checkout-form-container'>
       <div>
        <div className='payment-label'><h2>Abonamente</h2></div>
    <div className='bundles-wrapper'>
        <MyButton 
        body = {<><h3>Semestrial</h3><hr /><h2> {finalSemestrialPrice} lei </h2><p>Acces: 6 Luni </p><p>Economisești <b>25%</b></p><h4>{finalSemestrialPrice/6} lei/lună</h4><span>ALEGE</span></>}
        type="button"
        className={planId === "sem80" ? "bundle-card selected" : "bundle-card sem"}
        onClick ={() => planId === "sem80" ? setPlanId(null) : setPlanId("sem80") }
        />
        <MyButton 
        body = {<><h3>Anual</h3><hr /><h2> {finalAnnualPrice} lei </h2><p>Acces: 1 an</p><p>Economisești <b>30%</b></p><h4>{finalAnnualPrice/12} lei/lună</h4><span>ALEGE</span></>}
        type="button"
        className={planId === "anu150" ? "bundle-card selected" : "bundle-card an"}
        onClick ={() => planId === "anu150" ? setPlanId(null) : setPlanId("anu150") }
        />
        <MyButton 
        body = {<><h3>Lunar</h3><hr /><h2> {finalMonthlyPrice} lei </h2><p>Acces: 1 Lună </p><p>Economisești <b>0%</b></p><h4>{finalMonthlyPrice} lei/lună</h4><span>ALEGE</span></>}
        type="button"
        className={planId === "lun20" ? "bundle-card selected" : "bundle-card lun"}
        onClick ={() => planId === "lun20" ? setPlanId(null) : setPlanId("lun20") }
        />
    </div>
    <div className="max-active-sessions-container">
           <br />
            <input 
                type="range" 
                id="maxActiveSessions" 
                min={3} 
                max={20} 
                value={maxActiveSessions} 
                onChange={(e) => setMaxActiveSessions(parseInt(e.target.value))}
                className="max-active-sessions-slider"
            />
            <label htmlFor="maxActiveSessions"><h3>Utilizatori activi: {maxActiveSessions}</h3></label><br />
        </div>
            </div>
        {
        authUser && !authUser.termsAccepted && <div className='terms-confirmation'>
        <MyInput
                name="terms-check"
                type="checkbox"
                checked={termsAccepted}
                onChange={(e) => setTermsAccepted(e.target.checked)}
                label="Accept"
            /><a className="aterms" href='/termeni-conditii' target='_blank'>Termeni și condiții</a>
            </div>
    }
    </div>
           <div>
           <MyButton 
            onClick={onSubmit}
            className = "button-with-loader"
            body={loading ? <div className="spinner-container"><CircleSpinner loading={loading} color='#1b1b1bff' />
            </div> : updatePlan
             ? "Schimbă": "Achită"}
            />
             {updatePlan && <MyButton 
                    body = "Înapoi"
                    type = "button"
                    className="cancel-button"
                    onClick = {goToSubscriptionDetails}
                />}
                {goBack && !updatePlan && <MyButton 
                    body = "Înapoi"
                    type = "button"
                    className="cancel-button"
                    onClick = {goBack}
                />}
           </div>
           </div>
    );
}