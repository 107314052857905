// PaymentMethodContext.js
import React, { createContext, useContext, useState } from 'react';
import subscriptionService from '../services/SubscriptionService';

const PaymentMethodContext = createContext();

export const usePaymentMethodContext = () => {
  return useContext(PaymentMethodContext);
};

export const PaymentMethodProvider = ({ children }) => {
  const [savedPaymentMethods, setSavedPaymentMethods] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [showNewCardForm, setShowNewCardForm] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchPaymentMethods = async () => {
    try {
      // Fetch payment methods data
      setLoading(true);
      const data = await subscriptionService.getPaymentMethods();
      setSavedPaymentMethods(data);
    } catch (error) {
      // Handle error
      setShowNewCardForm(true);
    } finally {
      setLoading(false);
    }
  };

  const updatePaymentMethods = (newPaymentMethods) => {
    setSavedPaymentMethods(newPaymentMethods);
  };

  return (
    <PaymentMethodContext.Provider
      value={{
        loading,
        savedPaymentMethods,
        selectedPaymentMethod,
        showNewCardForm,
        fetchPaymentMethods,
        updatePaymentMethods,
        setSelectedPaymentMethod,
        setSavedPaymentMethods,
        setShowNewCardForm,
        setLoading
      }}
    >
      {children}
    </PaymentMethodContext.Provider>
  );
};
