import React from 'react';
import CustomDatePicker from '../../components/CustomDatePicker';
import MyInput from '../input/MyInput';
import classes from './MyTable.module.css';
import { FaSave, FaEdit, FaTrash } from 'react-icons/fa'

function MyTable({ headers, data,disabled, totals,saveAction }) {
  return (
    <table className={`${classes.table} table`}>
        <thead>
            <tr>
                {headers.map((header, idx) => (
                    <th key={idx}>{header}</th>
                ))}
            </tr>
        </thead>
        <tbody>
            {data.map((row, rowIndex) => (
    <tr key={rowIndex}>
        {row.map((cell, cellIndex) => (
            <td key={cellIndex}>
                {cell.type === 'text' && cell.value}
                {cell.type === 'input' && <MyInput value={cell.value} onChange={(e) => cell.onChange(e.target.value)} disabled={cell.disabled} onKeyDown={(e) => {e.key === 'Enter' && saveAction(rowIndex,e)}}/>}
                {cell.type === 'datepicker' && <CustomDatePicker value={cell.value}  onChange={date => cell.onChange(date)} onKeyDown={(e) => {e.key === 'Enter' && saveAction(rowIndex,e)}}/>}
                {cell.type === 'actions' && (
                    <div className="table-buttons">
                        {cell.actions.map((action, actionIndex) => (
                           <button key={actionIndex} onClick={action.action} className="action-button" type="button">
                            {action.label === 'Edit' && <FaEdit className="edit-icon" />}
                            {action.label === 'Delete' && <FaTrash className="delete-icon" />}
                            {action.label === 'Save' && <FaSave className="save-icon" />}
                       </button>
                        ))}
                    </div>
                )}
            </td>
        ))}
    </tr>
))}
        <tr className={classes.lastRow}>
                {totals.map((total, totalIndex) => (
                    <td key={totalIndex}>{total}</td>
                ))}
            </tr>
        </tbody>
    </table>
  );
}

export default MyTable;
