import React from 'react'

export default function Pagination({calcs,setCurrentPage,calcsPerPage,currentPage,visiblePages}) {
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const totalPages = Math.ceil(calcs.length / calcsPerPage);
  
    const generatePageNumbers = () => {
      const pageNumbers = [];
      const totalPages = Math.ceil(calcs.length / calcsPerPage);
    
      for (let number = 1; number <= totalPages; number++) {
        const isCurrent = currentPage === number;
        const isNearSelected = Math.abs(currentPage - number) <= visiblePages;
    
        if (isCurrent || isNearSelected || number === 1 || number === totalPages) {
          pageNumbers.push(
            <div
              key={number}
              onClick={() => paginate(number)}
              className={`pagination-number ${isCurrent ? 'pagination-active' : ''}`}
            >
              {number}
            </div>
          );
        } else if (
          (number === currentPage - visiblePages - 1 && currentPage - visiblePages > 2) ||
          (number === currentPage + visiblePages + 1 && currentPage + visiblePages < totalPages - 1)
        ) {
          pageNumbers.push(
            <div key={number} className="pagination-number ellipsis" onClick={() => paginate(number)}>
              ...
            </div>
          );
        }
      }
    
      return pageNumbers;
    };
  return (
    <div className='pagination-container'>
          {currentPage > 1 && (
            <div className='pagination-number arrow' onClick={() => paginate(currentPage - 1)}>
              <svg width='18' height='18'>
                <use xlinkHref='#left' />
              </svg>
              <span className='arrow-text'></span>
            </div>
          )}
          {generatePageNumbers()}
          {currentPage < totalPages && (
            <div className='pagination-number arrow' onClick={() => paginate(currentPage + 1)}>
              <svg width='18' height='18'>
                <use xlinkHref='#right' />
              </svg>
            </div>
          )}
        </div>
  )
}
