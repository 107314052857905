import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import authService from "../services/AuthService";

function useResetPassword(){
    const [loading, setLoading] = useState(false);
    const [resend, setResend] = useState(false);
    const navigate = useNavigate();
    const resetPassword = async (token, password) =>{
        setLoading(true);
        try{
            const data = await authService.passwordReset(token,password);
            navigate('/login');
            toast.success("Parola a fost resetata cu succes", {
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

            return data;
        }catch(error){
            if(error.response.data.password){
                toast.error(error.response.data.password, {
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }else if(error.response.status === 400){
                toast.error(error.response.data, {
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setResend(true);
            }else{
                toast.error(error.response.data, {
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
            
        }finally{
            setLoading(false);
        }
    };
   
    
    return {resetPassword, loading,resend};
}
export default useResetPassword;