import React, { useState } from 'react'
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom'
import '../assets/css/Navbar.css'
import { useAuth } from '../context/AuthContext'
import Logo from './Logo'
import userIcon from '../assets/icons/icons8-male-user-50.png'
import walletIcon from '../assets/icons/icons8-wallet-32.png'
import { useCheckoutOverlay } from '../context/CheckoutContext'
import {Popover} from '../UI/popover/Popover.tsx'
import Overlay from './Overlay'
import WalletForm from './WalletForm'
import useLogout from '../hooks/useLogout'
import MyTextarea from '../UI/textarea/MyTextarea'
import MyButton from '../UI/button/MyButton'
import instance from '../interceptors/AxiosInterceptor'
import { toast } from 'react-toastify'
import { CircleSpinner } from 'react-spinner-overlay'
export default function Navbar() {
        const [menuOpen, setMenuOpen] = useState(false)
        const { isLoggedIn, authUser} = useAuth();
        const logout = useLogout();
        const location = useLocation();
        const { showCheckoutOverlay, handleCheckoutOverlay } = useCheckoutOverlay();
        const [showFeedbackOverlay, setShowFeedbackOverlay] = useState(false);
        const [isPopoverOpen, setIsPopoverOpen] = useState(false);
        const [popoverOpen, setPopoverOpen] = useState(false);
        const navigate = useNavigate();
        const [feedbackText, setFeedbackText] = useState('');
        const [loading, setLoading] = useState(false);
        const userDetails = authUser ?  authUser.email + "\n" + authUser.name + "\n" + authUser.telephoneNumber : "nullUser";
        const handleFeedback = async (e) =>{
          e.preventDefault();
          try {
            setLoading(true);
            await instance.post('https://api.prolex.md/api/calculator/sendFeedback',{
              message: feedbackText,
              calcDetails: userDetails
          },{
              withCredentials: true});
              toast.success('Feedback-ul a fost trimis cu succes.', {
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setShowFeedbackOverlay(false);
          } catch (error) {
            toast.success('Eroare.Încercați mai târziu.', {
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
          });
          }finally{
            setLoading(false);
          }
        }
  return (
    <nav className='hide-on-print'>
        <Link to={"/"} className="nav-title">
            <Logo/>
        </Link>
        {/*<div className='menu' onClick={() => setMenuOpen(!menuOpen)}>*/}
        
        <Popover
                  isOpen={popoverOpen}
                  positions={['bottom']}
                  padding={2}
                  onClickOutside={() => setPopoverOpen(false)} // Close popover when clicked outside
                  content={({ position, childRect, popoverRect }) => (
                    <div
                    style={{
                      backgroundColor: '#f1f4f9',
                      width: '100vw', // Adjust width as needed
                      borderRadius: '0px 0px 5px 5px',
                      border: '1px solid lightgrey',
                      fontFamily: 'Montserrat, sans-serif',
                      fontSize: '14px',
                      lineHeight: '1.6'
                    }}
                    onMouseLeave={() => setPopoverOpen(false)} // Close popover on mouse leave from popover content
                  >
                    <div
                      className="body"
                      style={{
                        padding: '2px',
                        alignItems: 'center'
                      }}
                    >
                      <ul style={{ listStyleType: 'none', padding: 0 }}>
                      {authUser ?  <>
                        <li>
                          <NavLink to="/dashboard" className='nav-option'onClick={() => setIsPopoverOpen(false)}>
                            Cabinet personal
                          </NavLink>
                        </li>
                        <li>
                          <div className="nav-option" onClick={() => {setIsPopoverOpen(false);setShowFeedbackOverlay(!showFeedbackOverlay)}}>
                            Lasă un Feedback
                          </div>
                        </li>
                        <li>
                          <div className="nav-option exit" onClick={() => {setIsPopoverOpen(false);logout();}}>
                            Ieși din cont
                          </div>
                        </li>
                      </>:
                      <>
                        <li><NavLink className="nav-option" to={"/login"} state={{ prev: location.pathname }} onClick={() => {setIsPopoverOpen(false);setMenuOpen(false)}}>Autentificare</NavLink></li>
                        <li><NavLink className="nav-option" to={"/register"} onClick={() => {setIsPopoverOpen(false);setMenuOpen(false)}}>Înregistrare</NavLink></li>
                      </>}
                      </ul>
                    </div>
                  </div>
                )}
              >
                <div
                onClick={()=>{setIsPopoverOpen(false);navigate("/dashboard");}}
                  onMouseEnter={() => setPopoverOpen(true)}
                >
                 <div className='menu'>
        <img src={userIcon} alt="" />
        </div>
                </div>
              </Popover>
        <ul className={menuOpen ? "open" : ""}>
          <div className='menu-left'>
            <li><NavLink to={"/penalitate"} className="navbar-link" onClick={() => setMenuOpen(false)}>Penalitate</NavLink></li>
            <li><NavLink to={"/dobanda"} className="navbar-link" onClick={() => setMenuOpen(false)}>Dobânda de întârziere</NavLink></li>
            <li><NavLink to={"/inflatie"} className="navbar-link" onClick={() => setMenuOpen(false)}>Calcul inflație</NavLink></li>
            <li><NavLink to={"/imputatia"} className="navbar-link" onClick={() => setMenuOpen(false)}>Imputația</NavLink></li>
            <li><NavLink to={"/zile"} className="navbar-link" onClick={() => setMenuOpen(false)}>Calcul zile</NavLink></li>
            <li><a href="https://taxa.prolex.md" className="navbar-link" target="_blank">Taxa</a></li>
          </div>
            <div className='menu-right'>
              {isLoggedIn && authUser.parentUserId == null ? 
            <li className='hide-on-mobile nav-wallet' onMouseEnter={() => setIsPopoverOpen(false)}>
            <div className='wallet' onClick={handleCheckoutOverlay}>
            <img src={walletIcon} alt="" />
            {authUser.subscription == null ? authUser.balance : null}
            </div>
            </li>  
            : null}
              {isLoggedIn 
            ? 
            <>
                <Popover
                  isOpen={isPopoverOpen}
                  positions={['bottom']}
                  padding={2}
                  onClickOutside={() => setIsPopoverOpen(false)} // Close popover when clicked outside
                  content={({ position, childRect, popoverRect }) => (
                    <div
                    style={{
                      backgroundColor: '#f1f4f9',
                      width: '200px', // Adjust width as needed
                      borderRadius: '0px 0px 5px 5px',
                      border: '1px solid lightgrey',
                      fontFamily: 'Montserrat, sans-serif',
                      fontSize: '14px',
                      lineHeight: '1.6'
                    }}
                    onMouseLeave={() => setIsPopoverOpen(false)} // Close popover on mouse leave from popover content
                  >
                    <div
                      className="body"
                      style={{
                        padding: '2px',
                        alignItems: 'center'
                      }}
                    >
                      <ul style={{ listStyleType: 'none', padding: 0 }}>
                      {authUser ?  <>
                        <li>
                          <NavLink to="/dashboard" className='nav-option'onClick={() => setIsPopoverOpen(false)}>
                            Cabinet personal
                          </NavLink>
                        </li>
                        <li>
                          <div className="nav-option" onClick={() => {setIsPopoverOpen(false);setShowFeedbackOverlay(!showFeedbackOverlay)}}>
                            Lasă un Feedback
                          </div>
                        </li>
                        <li>
                          <div className="nav-option exit" onClick={() => {setIsPopoverOpen(false);logout();}}>
                            Ieși din cont
                          </div>
                        </li>
                      </>:
                      <>
                        <li><NavLink to={"/login"} state={{ prev: location.pathname }} onClick={() => setMenuOpen(false)}>Autentificare</NavLink></li>
                        <li><NavLink to={"/register"} onClick={() => setMenuOpen(false)}>Înregistrare</NavLink></li>
                      </>}
                      </ul>
                    </div>
                  </div>
                )}
              >
                <div
                onClick={()=>{setIsPopoverOpen(false);navigate("/dashboard");}}
                  onMouseEnter={() => setIsPopoverOpen(true)}
                >
                  <li className='nav-option'>{authUser && authUser.name}</li>
                </div>
              </Popover>
            </>
            : <>
              <li><NavLink to={"/login"} state={{ prev: location.pathname }} onClick={() => setMenuOpen(false)}>Autentificare</NavLink></li>
              <li><NavLink to={"/register"} onClick={() => setMenuOpen(false)}>Înregistrare</NavLink></li>
              </>
              }
            </div>
        </ul>
        <Overlay
        isOpen={showCheckoutOverlay}
        handleOverlay={handleCheckoutOverlay}
        body={<WalletForm/>}
        />
        <Overlay
        isOpen={showFeedbackOverlay}
        handleOverlay={()=>{setShowFeedbackOverlay(!showFeedbackOverlay);}}
        body={
          <form onSubmit={handleFeedback}>
            <span className='myInpLabel'>Feedback</span>
            <MyTextarea
              name='feedback'
              label='Feedback'
              value={feedbackText}
              onChange={(e) => setFeedbackText(e.target.value)}
            />
          <MyButton
          body={loading ? <div className="spinner-container"><CircleSpinner loading={loading} color='#1b1b1bff' />
          </div>:"Expediază"}
          className = "button-with-loader"
          />
          </form>
        }
        />
    </nav>
  )
}
