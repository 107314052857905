import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { CircleSpinner } from 'react-spinner-overlay';
import { toast } from 'react-toastify';
import { useAuth } from '../context/AuthContext';
import instance from '../interceptors/AxiosInterceptor';
import userService from '../services/UserService';
import MyButton from '../UI/button/MyButton';
import MyInput from '../UI/input/MyInput';

export default function TokenForm({goBack}) {
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const {authUser} = useAuth();
    const [showedPrice,setShowedPrice] = useState(null);
    const [tokens,setTokens] = useState(1);
    const location = useLocation();
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [clientIp, setClientIp] = useState('');
    useEffect(() => {
        const fetchClientIp = async () => {
        try {
            const response = await axios.get('https://api.ipify.org?format=json');
            setClientIp(response.data.ip);
        } catch (error) {
         
        }
        };

        fetchClientIp();
    }, []);
    useEffect(()=>{
        if (authUser){
        setTermsAccepted(authUser.termsAccepted);
        }
    },[authUser]);
    useEffect(()=>{
        setShowedPrice(tokens * 22);
    },[tokens]);
    

    const handleError = (newError) => {
        setError(newError.message || String(newError));
    };
    function validation(){
        if(tokens === null){
            toast.error('Nu poate fi selectat mai putin de 1 calcul.', {
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return false;
        }
        if(!termsAccepted){
            toast.error('Acceptați Termenii și condițiile.', {
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return;
        }
        return true;
    }
    async function onSubmit() {
        if(!validation()){
            return;
        }
            try {
                setLoading(true);
                    const response = await instance.post('/api/subscription/buy-tokens', {
                        clientIp: clientIp,
                        amount: tokens
                    });
                        localStorage.setItem('prevPath', location.pathname + location.search);
                        window.location.href = response.data;
                    if(!termsAccepted){await userService.acceptTerms();}
                    setTermsAccepted(true);
            } catch (error) {
                toast.error('Eroare la procesarea tranzacției, încercați mai târziu.', {
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }finally{
                setLoading(false);
            }
    }
    // return (
    //     <div>
    //         <div className='checkout-form-container'>
    //             Ne pare rău, momentan întâmpinăm probleme tehnice cu plata cu cardul din partea băncii. Vă rugăm să utilizați transferul bancar pentru a vă abona.
    //         </div>
    //     </div>
    // );

    return (
        <div>
       <div className='checkout-form-container'>
       <div>
        <div className='payment-label'><h2>Selectați numărul de calcule</h2></div>
    <div className="max-active-sessions-container">
           <br/>
            <input 
                type="range" 
                id="maxActiveSessions" 
                min={1} 
                max={50} 
                value={tokens} 
                onChange={(e) => setTokens(parseInt(e.target.value))}
                className="max-active-sessions-slider"
            />
        </div>
    
        <div className='calcs-selector'>
            <div className='steps'> <label htmlFor="maxActiveSessions"><h3>Calcule: {tokens}</h3></label></div>
            <div className='steps'> <label><h3>Suma: {showedPrice} lei</h3></label></div>
        </div>
        {
        authUser && !authUser.termsAccepted && <div className='terms-confirmation'>
        <MyInput
                name="terms-check"
                type="checkbox"
                checked={termsAccepted}
                onChange={(e) => setTermsAccepted(e.target.checked)}
                label="Accept"
            /><a className="aterms" href='/termeni-conditii' target='_blank'>Termeni și condiții</a>
            </div>
    }
            </div>
        
    </div>
           <div>
           <MyButton 
            onClick={onSubmit}
            className = "button-with-loader"
            body={loading ? <div className="spinner-container"><CircleSpinner loading={loading} color='#1b1b1bff' />
            </div> : "Achită"}
            />
           </div>
           {goBack && <MyButton 
                    body = "Înapoi"
                    type = "button"
                    className="cancel-button"
                    onClick = {goBack}
                />}
           </div>
    );
}