const useInflationCalculation = ({startDate, delay, isQuarantine, isDetailed,fromNextDay, entries}) => {

    const formatDate = (date) => {
        return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
    }

    const addToDateValues = (acc, formattedDate, value, type) => {
        if (!acc[type][formattedDate]) {
            acc[type][formattedDate] = parseFloat(value);
        } else {
            acc[type][formattedDate] += parseFloat(value);
        }
    }

    const prepareEntriesForRequestBody = (entries) => {
        return entries.reduce((acc, entry) => {
            const formattedDate = formatDate(entry.date);
    
            if (entry.due !== null && entry.due !== '') {
                addToDateValues(acc, formattedDate, entry.due, 'scadentValues');
            }
    
            if (entry.paid !== null && entry.paid !== '') {
                addToDateValues(acc, formattedDate, entry.paid, 'payValues');
            }
    
            return acc;
        }, { scadentValues: {}, payValues: {} });
    }


    const calculateResults = () => {
        const formattedEntries = prepareEntriesForRequestBody(entries);
    
        return {
            penaltyDate: formatDate(startDate),
            daysTillStart: parseInt(delay, 10),
            isCarantine: isQuarantine === null ? false : isQuarantine,
            isBrief: !isDetailed,
            fromNextDay: fromNextDay,
            ...formattedEntries
        };
    }

    return {
        calculateResults,
    };
}
export default useInflationCalculation;