import React from 'react'
import classes from './MyInput.module.css'
export default function MyInput({name, type, isRequired, placeholder,value, onChange, label,disabled,onClick,frwdRef,checked,className,regex,limit,controlLimit, ...props}) {
 let handleInputChange;
  if(regex || limit){
   handleInputChange = (e) => {
      const inputValue = e.target.value;
  
      if ((regex && !regex.test(inputValue) )) {
          return;
      }
      if(limit && (inputValue.length > limit)){
        return;
      }
  
  
      onChange(e);
  };
  if (controlLimit && (value.length !== controlLimit) && value.length != 0) {
    className = "error-input";
  }
  }
  if (type === 'checkbox') {
    return (
      <>
        <input
          id={name}
          className={classes.input}
          type={type}
          name={name}
          required={isRequired}
          placeholder={placeholder}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          ref={frwdRef}
        />
        {label && <label htmlFor={name}>{label}</label>}
      </>
    );
  } else {
    return (
      <input
        className={`${classes.input} ${className}`}
        type={type}
        id={name}
        name={name}
        required={isRequired}
        placeholder={placeholder}
        value={value}
        onChange={handleInputChange ? handleInputChange : onChange}
        disabled={disabled}
        onClick={onClick}
        ref={frwdRef}
        {...props}
      />
    );
  }
}
