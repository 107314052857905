import React from 'react'
import { useAuth } from '../context/AuthContext';
import { useCheckoutOverlay } from '../context/CheckoutContext';
import MyButton from '../UI/button/MyButton';

export default function SubscriptionCard({ price, startDate, endDate, planId, nextBillingDate }) {
    const subscriptionDuration = planId === "lun20" ? '1 lună' : planId === "sem80" ? '6 luni' : planId === "anu150" ? '1 an' : null;
    const {authUser} = useAuth();
    const { handleCheckoutOverlay,showCheckoutOverlay } = useCheckoutOverlay();
    return (
<div className="subscription-card"
    
>
    <div className="subscription-details">
    <div className="sub-name-author">
               <div>
               <p className="subscription-details-info">ABONAMENT</p>
                {<h1 className="subscription-details-info">{subscriptionDuration}</h1> }
                {/* {trialDuration ? <> + {trialDuration} zile</>: null} */}
               </div>
                
            </div>
            <div className="sub-first-row">
            {/* <div className="price-container">
                <span className={price && price.toString().length === 3 ?"info-value-3" : "info-value"}>{price}</span>
                <span className={price && price.toString().length === 3 ?"info-value-3 currency-3" :"info-value currency"}> lei</span>
            </div> */}
            
        </div><br />
        <h4 className="subscription-details-info owner-name">{authUser.name}</h4>
        <div className="subscription-details-info date-info">
        {authUser.subscription.status !== "pending" &&<> Perioada facturată: <span className="date">{startDate} - {endDate}</span></>}
        <p className='subscription-details-info renew-info'>
        {authUser.subscription.status === "canceled" ? (
            <span>Expiră la sfârșitul perioadei de facturare curente.</span>
        ) : authUser.subscription.status === "pending" ? 
            (<span>În așteptarea confirmării de plată</span>)
          : authUser.subscription.status === "active+pending" ? 
        (<span>În așteptarea confirmării de plată</span>)
            :(
           nextBillingDate &&  <>
            <span>Data următoarei facturări: <span>{nextBillingDate}</span></span>
        </>
        )}
    </p>
        </div>
                {!showCheckoutOverlay && <>
        <MyButton
        type='button'
        body="Administrează abonamentul"
        onClick = {() => {handleCheckoutOverlay()}}
        />
        </>}
    </div>
</div>



    );
}