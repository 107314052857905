import React from 'react'
import { useAuth } from '../context/AuthContext';
import { useCheckoutOverlay } from '../context/CheckoutContext';
import MyButton from '../UI/button/MyButton';

export default function FreeSubscriptionCard() {
    const {authUser} = useAuth();
    const { handleCheckoutOverlay } = useCheckoutOverlay();
  return (
    <div className="subscription-card">
    <div className="subscription-details">
        <div className="sub-first-row">
            {/* <div className="price-container">
                <span className="info-value">00</span>
                <br />
                <span className="info-value currency">EUR</span>
            </div> */}
            <div className="sub-name-author">
               <div>
               <p className="subscription-details-info">ABONAMENT: <strong>{authUser.balance} calcule</strong></p>
               </div>
               <p className="subscription-details-info owner-name">{authUser.name}</p>
            </div>
        </div>
        <p className="subscription-details-info date-info">
        <MyButton
            type='button'
            body="Administrează abonamentul"
            onClick = {() => {handleCheckoutOverlay()}}
        />
        <span className="date"></span></p>

    </div>
</div>
  )
}
