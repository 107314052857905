import React, { useEffect } from 'react'
import { useAuth } from '../../context/AuthContext';
import { useCalcsContext } from '../../context/CalcsContext';
import CalcsStatistica from '../CalcsStatistica';
import CalcsTable from '../CalcsTable'
import SubscriptionDetailsInfo from '../SubscriptionDetailsInfo'

export default function General() {
  const { calcs, setCalcs,refetchCalcsData } = useCalcsContext();


  return (
    <div className='section-form'>
      <div className='section-form-container'>
        <div className='section-top-flex'>
          <div className='section-title-info'>
            <h1 className='section-title'>Detalii</h1>
            <p className='section-description'>Informații generale despre profil.</p>
          </div>
        </div>
        <div className="spacer-m-2"></div>
        <div className='section-row'>
          <div className='general-section-card info-card'>
            <SubscriptionDetailsInfo
            />
          </div>
          <div className='general-section-card info-card'>
            <CalcsStatistica
            calcs={calcs}
            />
          </div>
        </div>
      </div>
      <div className='calcs-container listcalc'>
        {calcs && calcs.length > 0 ? <CalcsTable
        calcs={calcs}
        setCalcs={setCalcs}
        allowEdit={false}
        refetchCalcsData ={refetchCalcsData}
        calcsPerPage={6}
        />
        :
        <>
          <h4 style={{ textAlign: 'center' }}>
            Nu există calcule recente.
          </h4>
        </>}
        </div>
  </div>
  )
}
