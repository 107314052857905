import React from 'react'
import useLogout from '../hooks/useLogout'
import Settings from './ProfileSections/Settings';
import { useState } from 'react';
import Sidebar from './Sidebar';
import Calcule from './ProfileSections/Calcule';
import Wallet from './ProfileSections/Wallet';
import General from './ProfileSections/General';
import SubAccountsManagement from './ProfileSections/SubAccountsManagement';

export default function DashboardContainer() {
    const logout = useLogout();
    const [activeSection, setActiveSection] = useState(
      sessionStorage.getItem('dashboardActiveSection') || 'general'
    );
  
    const handleSwitchSection = (section) => {
      sessionStorage.setItem('dashboardActiveSection', section);
      setActiveSection(section);
    };

    return (
      <div className='container'>
        <div className='video-single-box'>
          <div className='profile-setting-flex'>
            <div className='profile-settings-left'>
              {activeSection === 'general' && <General />}
              {activeSection === 'settings' && <Settings />}
              {activeSection === 'calcule' && <Calcule />}
              {activeSection === 'wallet' && <Wallet/>}
              {activeSection === 'subaccounts-management' && <SubAccountsManagement/>}
            </div>
            <div className='profile-settings-right'>
              <Sidebar
              logout={logout}
              activeSection={activeSection}
              handleSwitchSection={handleSwitchSection}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }