import React, { useEffect } from 'react'
import { useState } from 'react'
import { CircleSpinner } from 'react-spinner-overlay';
import { toast } from 'react-toastify';
import Heading from '../components/Heading'
import useRequestPasswordReset from '../hooks/useRequestPasswordReset';
import MyButton from '../UI/button/MyButton';
import MyInput from '../UI/input/MyInput';

export default function PasswordForgotPage() {
    const [email, setEmail] = useState('');
    const {requestPasswordReset, loading, error, cooldown, cooldownTimeLeft} = useRequestPasswordReset();
    useEffect(()=>{
        toast.error(error, {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
      });
      },[error]);
  return (
    <div className='acc-section'>
        <div className='content-wrapper'>
            <div className='form-wrapper'>
                <Heading text="Reseteaza parola" />
                <div className='form-block-box tab-form'>
                {cooldown ? <p className='subHead'>Verifică E-mailul. Linkul de resetare a parolei a fost expediat cu succes.</p>
                :<p className='subHead'>Inserează adresa ta de e-mail</p>}
                    <form className='form-container' onSubmit={(e) => {e.preventDefault();if(email) requestPasswordReset(email)}}>
                    <MyInput 
                    type="email" 
                    placeholder="Introdu adresa ta de email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                    {/* {error ? <div className="error-message">{error}</div> :null} */}
                    { cooldown ? 
                        <MyButton disabled body={`Trimite Email in ${cooldownTimeLeft}s`} /> :
                        loading ?  <MyButton  className="button-h45" body={<div className="spinner-container"><CircleSpinner loading={loading} color='#1b1b1bff' /></div>} />
                        : <MyButton  body="Resetează parola" /> }
                    </form>
                </div>
            </div>
        </div>
    </div>
  )
}
