import ResultsTable from "../components/ResultsTable";
import { useResults } from "../context/ResultsContext";
import '../assets/css/ResultsPage.css'
import { useMemo } from "react";
import { useEffect } from "react";
import { CircleSpinnerOverlay } from "react-spinner-overlay";
import Overlay from "../components/Overlay";
import { useState } from "react";
import PrintHeader from "../components/PrintHeader";
import TitleInputWrapper from "../components/CalculatorWrappers/TitleInputWrapper";
import calculatorService from "../services/CalculatorService";
import { toast } from "react-toastify";
import { useAuth } from "../context/AuthContext";
import saveIcon from "../assets/icons/save.png";
import exportPdf from "../assets/icons/exportpdf.png";
import printIcon from "../assets/icons/print.png";
import { useCalcsContext } from "../context/CalcsContext";
import MyButton from "../UI/button/MyButton";
export default function ResultPage() {
    const { resultsData, loading, paid, calcName, setCalcName,calcId, calcAdresare, setCalcAdresare, creationDate } = useResults();
    const [showTitleInputOverlay, setShowTitleInputOverlay] = useState(false);
    const [newCalcName, setNewCalcName] = useState("");
    const {isLoggedIn, authUser} = useAuth();
    const {refetchCalcsData } = useCalcsContext();

  const handleTitleInputOverlay = () => {
    setNewCalcName(calcName);
    setShowTitleInputOverlay(!showTitleInputOverlay);
};

    const currentUrl = window.location.href;
    useEffect(() => {


      // Create and append watermark when component mounts
      const watermark = document.createElement('div');
      watermark.style.position = 'fixed';
      watermark.style.bottom = '10px';
      watermark.style.right = '10px';
      watermark.style.color = 'rgba(0, 0, 0, 0.3)';
      watermark.style.fontFamily = 'Arial, sans-serif';
      watermark.style.fontSize = '10px';
      watermark.appendChild(document.createTextNode('Copyright © Pro-Lex IT'));
      watermark.id = 'printWatermark';
      document.body.appendChild(watermark);
  
      // Remove watermark when component unmounts
      return () => {
        const existingWatermark = document.getElementById('printWatermark');
        if (existingWatermark) {
          document.body.removeChild(existingWatermark);
        }
      };

    }, []);

    const htmlContent = useMemo(() => {
      if (resultsData) {
        return (
          <div>
            <ResultsTable data={resultsData} />
          </div>
        );
      }
      return null;
    }, [resultsData]);

    const handleOverlaySubmit = async () => {
      if (newCalcName === "" || newCalcName === null) {
        toast.warning('Indicați cui este adresat acest calcul.', {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return;
      }
      if(!authUser){
        toast.warning('Autentifică-te pentru a redacta acest calcul.', {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        handleTitleInputOverlay();
        return;
      }
      // if (!authUser.subscription.status) {
      //   toast.warning('Pentru a salva acest calcul, alege un abonament.', {
      //     autoClose: 3000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //     theme: "light",
      //   });
      //   handleTitleInputOverlay();
      //   return;
      // }
      // if (authUser.subscription.status === "pending"){
      //   toast.warning('Plata pentru abonament nu a fost confirmată.', {
      //     autoClose: 3000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //     theme: "light",
      //   });
      //   return;
      // }
        try{
          const response = await calculatorService.renameCalculation(newCalcName, calcId);
          await calculatorService.saveCalculation(calcId);
          toast.success('Calcul salvat cu succes.', {
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setCalcName(newCalcName);
          refetchCalcsData();
          handleTitleInputOverlay();
          return;
        }catch(error){
        }
        return; 
    };
    useEffect(() => {
      const handleBeforePrint = () => {
          // Update the author name when entering print mode
          if (authUser) {
            const authorElement = document.createElement('div');
            authorElement.className = "author visible-print";
            authorElement.innerHTML = `Calcul realizat de: ${authUser.name}`;
            
            const printFooter = document.querySelector('.print-footer');
            const firstChild = printFooter.firstChild;
    
            // Insert the author element as the first child of the print footer
            printFooter.insertBefore(authorElement, firstChild);
          }
      };
  
      const handleAfterPrint = () => {
          // Remove the author name element when exiting print mode
          const authorElement = document.querySelector('.author.visible-print');
          if (authorElement) {
              authorElement.remove();
          }
      };
      // const handleCopy = (event) => {
      //   event.preventDefault();
      // };
    
      // document.addEventListener('copy', handleCopy);

      window.addEventListener("beforeprint", handleBeforePrint);
      window.addEventListener("afterprint", handleAfterPrint);
  
      return () => {
          window.removeEventListener("beforeprint", handleBeforePrint);
          window.removeEventListener("afterprint", handleAfterPrint);
          // document.removeEventListener('copy', handleCopy);
      };
  }, [authUser]);
    return (
      <div className="results-page">
      <div className="to-print">
        <PrintHeader 
        currentUrl={currentUrl}/>
              <div className="results-container" id="download-content">
              <div className="result-buttons-wrapper">
             {authUser && <div className="print-button-row">
             <div className="result-icons hide-on-print"
             onClick={handleTitleInputOverlay}
             >
                <img src={saveIcon} loading="lazy" alt="" />
              </div>
              <div className="result-icons hide-on-print"
               onClick={() => {
                window.print();
            }}
              >
                <img src={printIcon} loading="lazy" alt="" />
              </div>
              <div className="result-icons hide-on-print"
               onClick={() => {
                window.print();
            }}
              >
                <img src={exportPdf} loading="lazy" alt="" />
              </div>
              
                </div>}
             </div>
              {resultsData && <ResultsTable data={resultsData} paid = {paid} 
              calcName = {calcName}  setCalcName = {setCalcName} 
              calcId = {calcId} handleTitleInputOverlay={handleTitleInputOverlay} 
              calcAdresare = {calcAdresare}
              />}
              <div className="print-footer">
              {authUser && 
                <div className="author hide-on-print" contentEditable={false}>
                    Calcul realizat de: {authUser.name}
                </div>
            }
              <div className="signature-line visible-print"></div>
              <div className="calc-date visible-print">{creationDate}</div>
              </div>
             
              </div>
      </div>
      <CircleSpinnerOverlay loading={loading} overlayColor="rgba(0, 0, 0, 0.8)" color='#1b1b1bff' />
              <Overlay 
      isOpen={showTitleInputOverlay}
      handleOverlay={handleTitleInputOverlay}
      body={
        <TitleInputWrapper 
        handleSubmit={handleOverlaySubmit}
        name = {newCalcName}
        setName={setNewCalcName}
        />
      }
      />
      </div>
    );
  }