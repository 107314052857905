import React from 'react'
import { useState } from 'react';
import MyButton from '../UI/button/MyButton';
import InputWrapper from './InputWrapper';

export default function VerifyCode({ handleSubmit, handleRequestSubmit, resend,cooldown,cooldownTimeLeft }) {
    const [code, setCode] = useState(''); // State to manage the verification code
  
    const onSubmit = (e) => {
      e.preventDefault();
      handleSubmit(code); // Call the parent's handleSubmit function with the verification code
    };
    return (
        <form onSubmit={onSubmit}>
        <h2>Confirmare</h2>
        <p>Vă rugăm să verificați emailul. Codul de verificare a fost trimis cu succes.</p>
        <InputWrapper
          name="verificationCode" 
          type="text" 
          isRequired={true} 
          placeholder="Introduceți codul de verificare" 
          body="Cod de Verificare"
          value={code}
          onChange={(e) => setCode(e.target.value)}
        />
        <MyButton
        body="Confirmati"
        />
        {
        resend ?
            cooldown ? 
        <MyButton disabled body={`Trimite cod in ${cooldownTimeLeft}s`} /> : 
        <MyButton
        body="Trimite cod nou"
        type="button"
        onClick={(e) => {handleRequestSubmit(e);}}
        />: null}
      </form>
    );
  }
