import React from 'react'
import { useAuth } from '../context/AuthContext'
import TransactionCard from './TransactionCard';
import '../assets/css/TransactionHistory.css';
import { useRef } from 'react';
import { useLayoutEffect } from 'react';
export default function TransactionsHistory() {
    const { authUser, isLoggedIn } = useAuth();
    const chartRef = useRef(null);
    useLayoutEffect(() => {
        const subscriptionCard = document.querySelector('.wallet-section-card .subscription-card');
        const updateChartHeight = () => {
            if (subscriptionCard instanceof HTMLElement) {
                const subscriptionCardHeight = subscriptionCard.clientHeight;
                if (chartRef.current && subscriptionCardHeight > 150) {
                    chartRef.current.style.height = `${subscriptionCardHeight}px`;
                }
            }
        };
    
        // Initial height update
        updateChartHeight();
    
        window.addEventListener('resize', updateChartHeight);
    
        return () => {
            window.removeEventListener('resize', updateChartHeight);
        };
    }, [authUser]);
    return (
       <div className='transaction-history-card' ref={chartRef}>
        {(authUser && authUser.transactions && Object.keys(authUser.transactions).length > 0) &&
        <div className="transaction-history-headers">
                    <p className="header-column suma">Suma</p>
                    <p className="header-column data">Data</p>
                    <p className="header-column statut">Statut</p>
                </div>}
         <div className="transaction-history-container">
            {authUser && authUser.transactions && Object.keys(authUser.transactions).length > 0 ? (
                Object.keys(authUser.transactions).map(transactionId => (
                    <TransactionCard
                        key={transactionId}
                        transaction={authUser.transactions[transactionId]}
                    />
                ))
            ) : (
                <div className="transaction-card">
                <h4 style={{ textAlign: 'center' }}>Nu există tranzacții.</h4>
                </div>
            )}
        </div>
       </div>
    );
}
