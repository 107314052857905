import React from 'react'
import { CircleSpinner } from 'react-spinner-overlay';
import { useAuth } from '../context/AuthContext';

import FreeSubscriptionCard from './FreeSubscriptionCard';
import SubscriptionCard from './SubscriptionCard';
import SubUserCard from './SubUserCard';

export default function SubscriptionDetailsInfo({subscriptionCardRef}) {
    const {authUser,loading} = useAuth();
    let startDate;
    let endDate;
    let nextBillingDate;
    let price;
    let planId;

    if(authUser.subscription){
        endDate = new Date(authUser.subscription.lastBillingDay).toLocaleDateString('ro-RO', { year: 'numeric', month: 'numeric', day: 'numeric' });
        startDate = new Date(authUser.subscription.firstBillingDay).toLocaleDateString('ro-RO', { year: 'numeric', month: 'numeric', day: 'numeric' });
        nextBillingDate =  new Date(authUser.subscription.nextBillingDate).toLocaleDateString('ro-RO', { year: 'numeric', month: 'numeric', day: 'numeric' });
        price = authUser.subscription.plan ? authUser.subscription.plan.price : null;
        planId = authUser.subscription.plan ? authUser.subscription.plan.id : null;
    }
  return (
    !loading ?
    authUser.subscription ?
        <><div className='subscription-details-container'>
        <SubscriptionCard
        price = {price}
        startDate = {startDate}
        nextBillingDate = {nextBillingDate}
        endDate = {endDate}
        planId={planId}
        subscriptionCardRef = {subscriptionCardRef ?? { current: null }}
        />
        </div></>
    : authUser.parentUserId ? <div className='subscription-details-container'><SubUserCard/></div>
    : <div className='subscription-details-container'><FreeSubscriptionCard/></div>
    : <CircleSpinner/>
  )
}
